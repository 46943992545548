import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import ShiftStartTime from "../../../../../sharedComponents/ShiftStartTime";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { InstructorList, getInstructorList, get_room_location, workshop_scheduling } from "../../../../../Studio-Services/Studio_Services";
import { SharedMultiSelectorChekBox } from "../../../../../sharedComponents/SharedMultiSelectorChekBox";

import moment from "moment";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { useNavigate } from "react-router-dom";
import { InputTypeTax } from "../../../../../sharedComponents/InputTypeTax";
import { SharedMultiSelect } from "../../../../../sharedComponents/SharedMultiSelect";
import { WorkshopMultiSelect } from "./WorkshopMultiSelect";
import { errorAlert, successAlert } from "../../../../../Static_Services/Alertmsg";

export const Scheduleworkshop = () => {
  const [loder,setLoder]= useState(false);

  const [sessions, setSessions] = useState([{ id: 1 }]); // Initial session state with one session

  const [tax1, setTax1] = useState();
  const [tax2, setTax2] = useState();
  // const [insOpt, setInsOpt] = useState();
  const [locationss, setLocationss] = useState();
  const [preskillsdata, setPreskillsdata] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState({
    workshopName: "",
    workshopStatus: "",
    workshopClientVisibility: "",
    workshopPrice: "",
    workshopTax1: "",
    workshopTax2: "",
    workshopDescription: "",
    workShopCapacity: "",

  })

  const navigate = useNavigate();

  useEffect(()=>{
if(selectedOptions.length > 0){
  let error = [...innerError];
  if(error){
    error[0].skills ='';
  }
  setInnerError(error);
}
  },[selectedOptions])


  const handleAddSession = () => {
    const newSession = { id: Date.now() }; // Generate a unique ID for the new session
    setSessions([...sessions, newSession]);
  };

  const handleDeleteSession = (sessionId) => {
    const updatedSessions = sessions.filter((session) => session.id !== sessionId);
    setSessions(updatedSessions);
  };


  const getRoomLocation = async () => {
    const fData = {
      "business_id": localStorage.getItem("business_id")
    }
    const resp = await get_room_location(fData);
    if (resp) {
      const loc = [];
      (resp.data.data).forEach(e => {
        loc.push({
          value: e.location_id, label: e.location_name
        })
      })
      setLocationss(loc);
    }
  }

  const InstList = async () => {
    const fData = {
      "pageid": 1,
      'instructor': 1
    }
    const resp = await InstructorList(fData);
    if (resp) {
      const preData = [];
      (resp.data.data).forEach(e => {
        if (e.status === "Approve" && e.business_status === "Active") {
          preData.push({
            id: e.user_id, label: `${e.name} ${e.lastname}`, image: e.profile_img ? e.profile_img : logo
          })
        }
      });
      setPreskillsdata(preData);
    }
  }
  useEffect(() => {
    getRoomLocation();
    InstList();
  }, [])

  const vigibaleOption = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" }
  ]

  const StOpt = [
    { value: "Active", label: "Active" },
    { value: "In-Active", label: "In-Active" },

  ]


  const HoursOPT = [
    { value: "01", label: <>01</> },
    { value: "02", label: <>02</> },
    { value: "03", label: <>03</> },
    { value: "04", label: <>04</> },
    { value: "05", label: <>05</> },
    { value: "06", label: <>06</> },
    { value: "07", label: <>07</> },
    { value: "08", label: <>08</> },
    { value: "09", label: <>09</> },
    { value: "10", label: <>10</> },
    { value: "11", label: <>11</> },
    { value: "12", label: <>12</> }
  ]

  const MinutsOPT = [
    { value: "00", label: <>00</> },
    { value: "05", label: <>05</> },
    { value: "10", label: <>10</> },
    { value: "15", label: <>15</> },
    { value: "20", label: <>20</> },
    { value: "25", label: <>25</> },
    { value: "30", label: <>30</> },
    { value: "35", label: <>35</> },
    { value: "40", label: <>40</> },
    { value: "45", label: <>45</> },
    { value: "50", label: <>50</> },
    { value: "55", label: <>55</> },
  ]

  const Ampm = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ]

  const [innerError, setInnerError] = useState([{ startdate: '', location: '', sHours: '', sMinuts: '', sAmpm: '', eHours: '', eMinuts: '', eAmpm: '', skills: '' }]);



  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());

    const workshopName = formValue.workshopName;
    const workshopStatus = formValue.workshopStatus;
    const workshopClientVisibility = formValue.workshopClientVisibility;
    const workshopPrice = formValue.workshopPrice;
    const workshopTax1 = formValue.workshopTax1;
    const workshopTax2 = formValue.workshopTax2;
    const workshopDescription = formValue.workshopDescription;
    const workShopCapacity = formValue.workShopCapacity;
    if (!workshopName) { setError(pre => ({ ...pre, workshopName: " * Required", })); isValid = 1; }
    if (!workshopStatus) { setError(pre => ({ ...pre, workshopStatus: "* Required", })); isValid = 2; }
    if (!workshopClientVisibility) { setError(pre => ({ ...pre, workshopClientVisibility: "* Required", })); isValid = 3; }
    if (!workshopPrice) { setError(pre => ({ ...pre, workshopPrice: "* Required", })); isValid = 4; }
    if (!workshopTax1) { setError(pre => ({ ...pre, workshopPrice: "Required", })); isValid = 5; }
    if (!workshopTax2) { setError(pre => ({ ...pre, workshopPrice: "Required", })); isValid = 5; }
    if (!workshopDescription) { setError(pre => ({ ...pre, workshopDescription: "Required", })); isValid = 6; }
    if (!workShopCapacity) { setError(pre => ({ ...pre, workShopCapacity: "Required", })); isValid = 7; }

    const startdate = formData.getAll('startdate[]');
    const location = formData.getAll('location[]');
    const sHours = formData.getAll('sHours[]');
    const sMinuts = formData.getAll('sMinuts[]');
    const sAmpm = formData.getAll('sAmpm[]');
    const eHours = formData.getAll('eHours[]');
    const eMinuts = formData.getAll('eMinuts[]');
    const eAmpm = formData.getAll('eAmpm[]');


    if (!startdate) { isValid = 10; }
    // if (!instructor) { isValid = 11; }
    if (!location) { isValid = 12; }
    if (!sHours) { isValid = 13; }
    if (!sMinuts) { isValid = 14; }
    if (!sAmpm) { isValid = 15; }
    if (!eHours) { isValid = 16; }
    if (!eMinuts) { isValid = 17; }
    if (!eAmpm) { isValid = 19; }

    const slote = [];

    for (let i = 0; i < sessions.length; i++) {
      let data = {};
      if (!sHours[i]) { data.sHours = 'Required'; isValid = 222; }
      if (!sMinuts[i]) { data.sMinuts = 'Required'; isValid = 222; }
      if (!sAmpm[i]) { data.sAmpm = 'Required'; isValid = 222; }
      if (!eHours[i]) { data.eHours = 'Required'; isValid = 222; }
      if (!eMinuts[i]) { data.eMinuts = 'Required'; isValid = 222; }
      if (!eAmpm[i]) { data.eAmpm = 'Required'; isValid = 222; }
      if (!location[i]) { data.location = 'Please enter address location'; isValid = 222; }
      if (!startdate[i]) { data.startdate = 'Required'; isValid = 222; }
      if (!selectedOptions[i]) { data.skills = 'Please select instructor'; isValid = 222; }
      if (Object.keys(data).length > 0) {
        innerError[i] = { ...data };
      }

      if (isValid === 1) {
        const ftime = `${sHours[i]} : ${sMinuts[i]} : 00 ${sAmpm[i]}`;
        const ttime = `${eHours[i]} : ${eMinuts[i]} : 00 ${eAmpm[i]}`;
        slote.push({
          service: moment(startdate[i]).unix(),
          address: "",
          capacity: 0,
          instructor: selectedOptions[i] && selectedOptions[i].items && selectedOptions[i].items.map((id) => (id.id)) || [],
          location: location[i],
          start: Math.floor(moment(startdate[i] + " " + ftime, "YYYY-MM-DD hh:mm:ss A").valueOf() / 1000),
          end: Math.floor(moment(startdate[i] + " " + ttime, "YYYY-MM-DD hh:mm:ss A").valueOf() / 1000),
        })
      }
    }

    if (isValid === 1) {
      setLoder(true);
      const fData = {
        workshopName: formValue.workshopName,
        workshopStatus: formValue.workshopStatus,
        workshopClientVisibility: formValue.workshopClientVisibility,
        workshopPrice: formValue.workshopPrice,
        workshopTax1: formValue.workshopTax1,
        workshopTax2: formValue.workshopTax2,
        workshopDescription: formValue.workshopDescription,
        workShopCapacity: formValue.workShopCapacity,
        slot: slote,
        tax1_rate: formValue.tax1_rate ? formValue.tax1_rate : 0,
        tax2_rate: formValue.tax2_rate ? formValue.tax2_rate : 0,
      }
      const resp = await workshop_scheduling(fData);
      if (resp) {
        setLoder(false);
        // Reset the form after processing
        e.target.reset();
        setSelectedOptions([]);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
                Success
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
            ${resp.data.message} </small></p>
            `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/add-items");
          }
        });
      }else{ setLoder(false);}
    }
  }

  const errorChange = (e) => {
    const { name, value } = e.target;
    setError(prevState => ({
      ...prevState,
      [name]: "",
    }));
  }

  const ErrorchangeHandler = (e, i) => {
    let { name, value } = e.target;
    let finale = name.replace(/\[\]/g, '');
    let error = [...innerError];
    if(error){
      error[0][finale] ='';
    }
    setInnerError(error);
  }
  return (
    <>
       {loder &&
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
          </Spinner>
        </div> }
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="innerContent">
                  <Container>
                    <Form onSubmit={submitHandler}>
                      <div className="NewClass">
                        <h5>
                          <IoIosArrowBack className="m-3" />
                          SCHEDULE WORKSHOP
                        </h5>
                        <Row className="mt-3">
                          <Col>
                            <InputField
                              name="workshopName"
                              type={"text"}
                              lable={"Name"}
                              onChange={errorChange}
                              error={error.workshopName}
                            />
                          </Col>
                          <Col>
                            <SharedSelect
                              name={"workshopClientVisibility"}
                              options={vigibaleOption}
                              defaultValue="Select Class"
                              lable={"Visible to client"}
                              onChange={errorChange}
                              error={error.workshopClientVisibility}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <InputField
                              name={"workShopCapacity"}
                              type={"number"}
                              lable={"Capacity"}
                              minLength={"0"}
                              onChange={errorChange}
                              error={error.workShopCapacity}
                            />
                          </Col>
                          <Col></Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <TextArea
                              name="workshopDescription"
                              lable={"Description"}
                              onChange={errorChange}
                              error={error.workshopDescription}
                            />
                          </Col>
                        </Row>
                        {sessions.map((session, index) => (
                          <div className="ScheduleInfo my-5" key={session.id}>
                            <h5>Schedule Information</h5>
                            <Row className="my-3">
                              <Col xm={12}>
                                <InputField
                                  lable={"Session 1 [Date]"}
                                  type={"date"}
                                  name={"startdate[]"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].startdate}
                                />
                              </Col>
                              <Col>
                                <WorkshopMultiSelect
                                  labelText="Instructor List"
                                  name="skills[]"
                                  setSelectedOptions={setSelectedOptions}
                                  id={index}
                                  selectedOptions={selectedOptions}
                                  options={preskillsdata}
                                />
                                <small className="text-danger">{innerError && innerError[index] && innerError[index].skills}</small>
                              </Col>

                              {/* <Col>
                                <SharedSelect
                                  name={"instructor[]"}
                                  options={insOpt}
                                  defaultValue="Select Class"
                                  lable={"Instructor List"}
                                />
                              </Col> */}
                              <Col>
                                <SharedSelect
                                  name={"location[]"}
                                  options={locationss}
                                  lable={"Location"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].location}
                                />
                              </Col>
                            </Row>

                            <Row className="my-4">
                              <Col>
                                <SharedSelect
                                  name={"sHours[]"}
                                  options={HoursOPT}
                                  lable={"Start Hours"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].sHours}
                                />
                              </Col>
                              <Col>
                                <SharedSelect
                                  name={"sMinuts[]"}
                                  options={MinutsOPT}
                                  lable={"Start Minutes"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].sMinuts}
                                />
                              </Col>
                              <Col>
                                <SharedSelect
                                  name={"sAmpm[]"}
                                  options={Ampm}
                                  lable={"Start AM/PM"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].sAmpm}
                                />
                              </Col>
                              <Col>
                                <SharedSelect
                                  name={"eHours[]"}
                                  options={HoursOPT}
                                  lable={"End Hours"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].eHours}
                                />
                              </Col>
                              <Col>
                                <SharedSelect
                                  name={"eMinuts[]"}
                                  options={MinutsOPT}
                                  lable={"End Minutes"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].eMinuts}
                                />
                              </Col>
                              <Col>
                                <SharedSelect
                                  name={"eAmpm[]"}
                                  options={Ampm}
                                  lable={"End AM/PM"}
                                  onChange={(e) => ErrorchangeHandler(e, index)}
                                  error={innerError && innerError[index] && innerError[index].eAmpm}
                                />
                              </Col>

                            </Row>
                            {sessions.length > 1 && ( // Render delete button only when there is more than one session
                              <SharedButton
                                title={"Remove"}
                                onClick={() => handleDeleteSession(session.id)}
                                type={"button"}
                                className={"btn btn-secondary"}
                              />
                            )}
                          </div>
                        ))}
                        {/* <SharedButton
                          title={"Add New Session"}
                          onClick={handleAddSession}
                          type={"button"}
                        /> */}
                        <Row className="mt-3">
                          <Col>
                            <InputTypeTax
                              name="workshopPrice"
                              type={"number"}
                              onChange={errorChange}
                              lable={"Price ( $ )"}
                              step="0.01"
                              error={error.workshopPrice}
                            />
                          </Col>
                          <Col>
                            <SharedSelect
                              name={"workshopStatus"}
                              options={StOpt}
                              lable={"Status"}
                              onChange={errorChange}
                              error={error.workshopStatus}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <SharedSelect
                              name={"workshopTax1"}
                              options={vigibaleOption}
                              onChange={(e) => setTax1(e.target.value)}
                              lable={"Tax 1 Applicable"}
                              error={error.workshopTax1}
                            />
                          </Col>
                          {tax1 === "yes" ?
                            <Col>
                              <InputTypeTax
                                name={"tax1_rate"}
                                type={"number"}
                                lable={"Tax 1 Rate %"}
                              />
                            </Col>
                            : ""}
                          <Col>
                            <SharedSelect
                              name={"workshopTax2"}
                              options={vigibaleOption}
                              onChange={(e) => setTax2(e.target.value)}
                              lable={"Tax 2 Applicable"}

                              error={error.workshopTax2}
                            />
                          </Col>
                          {tax2 === "yes" ?
                            <Col>
                              <InputTypeTax
                                name={"tax2_rate"}
                                type={"number"}
                                lable={"Tax 2 Rate %"}
                              />
                            </Col>
                            : ""}
                        </Row>
                        <Row>
                        
                     
                        </Row>
                        <Row className="m-4">
                          <Col>
                            <SharedButton
                              title={"Submit"}
                              type={"submit"}
                              className={"btn btn-primary w-100"}
                            />
                          </Col>
                          <Col>
                            <SharedButton
                              title={"Cancel"}
                              className={"cancelBtn btn btn-outline w-100"}
                              type={"butoon"}
                              href={"/add-items"}
                            />
                          </Col>
                          <Col></Col>
                        </Row>
                      </div>
                    </Form>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
