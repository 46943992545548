import React, { useEffect, useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { API_business_workshop_details, API_clover_buy_now_workshop_single, ExpiryCheck, Instructor_buy_now_workshop_cash, Instructor_clover_buy_now_workshop_single, ageCalculation, staff_business_workshop_details, staff_search_customer_for_workshop } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { DebitCreditCard } from '../../../sharedComponents/DebitCreditCard'
import { InputField } from '../../../sharedComponents/InputField'

export const ClientWorkshopDetail = () => {
    const [currentUrl, setCurrentUrl] = useState();
    const location = useLocation();
    const [stateData, setStateData] = useState();
    const [businessDetail, setBusinessDetail] = useState();
    const [loder, setLoder] = useState(false);
    const [classData, setClassData] = useState();
    const [instrustorData, setInstrustorData] = useState();
    const [workshopStatus, setWorkshopStatus] = useState();
    const [customerDetail, setCustomerDetail] = useState();
    const [view, setView] = useState(1);
    const [selectedClientInfo, setSelectedClientInfo] = useState();
    const [customerData, setCustomerData] = useState([])
    const [instructorpayment, setInstructorpayment] = useState(false);
    const [paymentval, setPaymentval] = useState(false);
    const [paymentMode, setPaymentMode] = useState();
    const [search_text, setSearch_text] = useState();
    const [clientDetail, setClientDetail] = useState([]);
    const [cardType, setCardType] = useState('');
    const [submitted, setSubmitted] = useState();
    const navigate = useNavigate();
    const [savecard, setSavecard] = useState(0);
    const [expirydate, setExpiryDate] = useState('');

    useEffect(() => {
        setCurrentUrl(location.pathname)
        if (location && location.state) {
            if (location.state.id) {
                console.log('====>', location.state);
            } else {
                console.log('=OTUR===>', location.state);
                setStateData(location.state);
            }


        }
        const bzdetails = JSON.parse(localStorage.getItem('bzDetails'));
        if (bzdetails) {
            console.log(bzdetails);
            setBusinessDetail(bzdetails);
        }
    }, [])

    const getWorkshopDetail = async () => {
        setLoder(true);
        if (currentUrl == "/workshop-detail") {
            let detail = { "business_id": businessDetail.business_id, "workshop_id": stateData.workshop_id, 'schedule_id': stateData.schedule_id };

            const resp = await staff_business_workshop_details(detail);
            if (resp) {
                const response = resp.data;
                response.data.start_date_utc_new = moment.unix(response.data.schedule_date).format('MMMM DD , YYYY');
                response.data.end_date_utc_new = moment.unix(response.data.end_date_utc).format('MMMM DD , YYYYY');
                // time conversion

                response.data.from_time_new = moment.unix(response.data.start).format('hh mm A');
                response.data.to_time_new = moment.unix(response.data.end).format('hh mm A');


                if (response.data.instructor_details.length != 0) {

                    response.data.instructor_details.filter(function (el) {
                        el.from_time_new = moment.unix(el.from_time_utc).format('hh mm A');
                        el.to_time_new = moment.unix(el.to_time_utc).format('hh mm A');
                    })
                }
                setClassData(response.data);
                setInstrustorData(response.data.instructor_details);
                setWorkshopStatus(response.data.workshop_status)
                setCustomerDetail(response.data.customer_details);
                setLoder(false);
            } else setLoder(false);

        } else {
            let detail = { "business_id": businessDetail.business_id, "workshop_id": stateData.workshop_id, 'schedule_id': stateData.schedule_id };

            const resp = await API_business_workshop_details(detail)
            if (resp) {
                const response = resp.data;
                // Date Conversion UTC
                response.data.start_date_utc_new = moment.unix(response.data.schedule_date).format('MMMM DD, YYYY');
                response.data.end_date_utc_new = moment.unix(response.data.end_date_utc).format('MMMM DD, YYYYY');
                // time conversion

                response.data.from_time_new = moment.unix(response.data.start).format('hh mm A');
                response.data.to_time_new = moment.unix(response.data.end).format('hh mm A');

                if (response.data.instructor_details.length != 0) {

                    response.data.instructor_details.filter(function (el) {
                        el.from_time_new = moment.unix(el.from_time_utc).format('hh mm A');
                        el.to_time_new = moment.unix(el.to_time_utc).format('hh mm A');
                    })
                }
                setClassData(response.data);
                setInstrustorData(response.data.instructor_details);
                setWorkshopStatus(response.data.workshop_status)
                setCustomerDetail(response.data.customer_details);
                setLoder(false);
            } else {
                setLoder(false);
            }
        }
    }


    useEffect(() => {
        if (businessDetail) {
            getWorkshopDetail();
        }
    }, [businessDetail])

    const ClientList = async (serc = '') => {
        let serchText = '';
        if (serc) {
            serchText = serc;
        } else {
            serchText = search_text;
        }
        setLoder(true);
        localStorage.setItem('w_Instructor_Search_val', serchText);
        var detail = { "pageid": "1", "business_id": businessDetail.business_id, 'workshop_id': stateData.workshop_id, "search_val": serchText }

        const resp = await staff_search_customer_for_workshop(detail);

        if (resp) {
            const responce = resp.data;
            responce.data.filter(function (el) {
                el.age = ageCalculation(el.date_of_birth);

            })
            setClientDetail(responce.data);
            setLoder(false);
        } else {
            setClientDetail([]);
            setLoder(false)
        }

    }

    const searchHandler = (e) => {
        const sanitizedValue = e.target.value.replace(/^\s+/g, '');
        setSearch_text(sanitizedValue);
        ClientList(sanitizedValue);
    }

    const booking = (id) => {
        setView(3);
        setSelectedClientInfo(id);
    }

    const handleRadioChange = (e) => {
        setCardType(e.target.value); // Update the cardType state with the selected value
    };

    const savePayment = async (card) => {
        setLoder(true);
        var detail = {
            "grand_total": classData.workshop_total_price,
            'workshop_id': classData.workshop_id,
            'workshop_schdule_id': classData.id,
            'tax': classData.workshop_tax_price,
            "token": card.card_token,
            "savecard": 0,
            "card_id": card.card_id,
            "business_id": businessDetail.business_id,
            "country_code": "",
        };

        const resp = await API_clover_buy_now_workshop_single(detail);

        if (resp) {
            successAlert(resp.data.message);
            setLoder(false);
            setSubmitted(false);
            if (currentUrl == '/workshop-detail') {
                setPaymentval(false);
                navigate(['/my-workshop']);
            }
        } else { setLoder(false); }
    }

    const getpaymentMode = (event) => {
        setPaymentMode(event.target.id)
    }

    const [referenceDetail, setReferenceDetail] = useState({ referenceid: '', payment_note: '' })
    const [referenceDetailError, setReferenceDetailError] = useState({ referenceid: '', payment_note: '' })

    const referenceHandler = (e) => {
        const { name, value } = e.target;
        setReferenceDetail((pre) => ({ ...pre, [name]: value }));
        setReferenceDetailError((pre) => ({ ...pre, [name]: '' }));
    }

    const PayAtStudio = async () => {
        setSubmitted(true);
        let isValid = 1;
        if (!referenceDetail.referenceid) { setReferenceDetailError((pre) => ({ ...pre, referenceid: "Transaction Number Required" })); isValid = 2 }
        // if (!referenceDetail.payment_note) { setReferenceDetailError((pre) => ({ ...pre, payment_note: "Notes Required" })); isValid = 3 }

        if (isValid === 1) {
            setLoder(true);

            var detail = {
                'business_id': businessDetail.business_id,
                'workshop_id': this.ID.id,
                'transaction_id': referenceDetail.value.referenceid,
                'payment_transaction_id': referenceDetail.value.payment_note || 'none',
                'amount': classData.workshop_total_price,
                'customer_id': selectedClientInfo.id,
                'tax': classData.workshop_tax_price
            };
            const resp = await Instructor_buy_now_workshop_cash(detail);

            if (resp) {
                const response = resp.data;
                successAlert(response.message);
                setView(1);
                setInstructorpayment(false);
                setSubmitted(false);
                getWorkshopDetail();
                setLoder(false);
            } else {
                setLoder(false)
            }

            setLoder(false)
        } else {
            setLoder(false);
        }
    }


    const payment = async (e) => {
        e.preventDefault();
        let isValid = 1;
        let resp = '';
        const fData = new FormData(e.target);
        const fValue = Object.fromEntries(fData.entries());

        const cardName = fValue.cardName ? fValue.cardName : "";
        const cardNumber = fValue.cardNumber ? fValue.cardNumber : "";
        const valid = expirydate ? expirydate : "";
        const cvv = fValue.cvv ? fValue.cvv : "";

        let expiry_month = "";
        let expiry_year = "";

        if (!cardName) { setError((pre) => ({ ...pre, cardName: "*Required" })); isValid = 2 }
        if (!cardNumber) { setError((pre) => ({ ...pre, cardNumber: "*Required" })); isValid = 4 }
        if (!valid) { setError((pre) => ({ ...pre, valid: "*Required" })); isValid = 5 }
        if (!cvv) { setError((pre) => ({ ...pre, cvv: "*Required" })); isValid = 6 }

        var expired = ExpiryCheck(valid);
        if (expired) {
            expiry_month = expired[0];
            expiry_year = expired[1];
        } else {
            return false;
        }
        setSubmitted(true);
        if (isValid === 1) {
            setLoder(true);
            if (this.currentUrl == '/workshop-detail') {
                const detail = {
                    "grand_total": classData.workshop_total_price,
                    "token": '',
                    'business_id': businessDetail.business_id,
                    'workshop_id': classData.workshop_id,
                    'workshop_schdule_id': classData.id,
                    'tax': classData.workshop_tax_price,
                    "savecard": savecard,
                    "number": cardNumber,
                    "expiry_month": expiry_month,
                    "customer_name": cardName,
                    "expiry_year": expiry_year,
                    "cvd": cvv,
                    "country_code": ""
                }
                //url = 'superadmin/webservices/api/buy_now_workshop';
                resp = await API_clover_buy_now_workshop_single(detail);

            }
            if (currentUrl == '/workshop-detail') {
                const detail = {
                    "grand_total": classData.workshop_total_price,
                    "token": '',
                    'business_id': businessDetail.business_id,
                    'workshop_id': classData.workshop_id,
                    'workshop_schdule_id': classData.id,
                    'customer_id': selectedClientInfo.id,
                    'tax': classData.workshop_tax_price,
                    "savecard": savecard,
                    "number": cardNumber,
                    "expiry_month": expiry_month,
                    "customer_name": cardName,
                    "expiry_year": expiry_year,
                    "cvd": cvv,
                    "country_code": ""
                }
                //url = 'superadmin/webservices/instructor/buy_now_workshop';
                // url = 'superadmin/webservices/instructor/clover_buy_now_workshop_single';
                resp = await Instructor_clover_buy_now_workshop_single(detail);
            }
            if (resp) {
                const response = resp.data;
                successAlert(response.message);
                setLoder(false);
                setSubmitted(false);
                if (currentUrl == '/workshop-detail') {
                    setView(1);
                    setInstructorpayment(false);
                    getWorkshopDetail();
                }
                if (this.currentUrl == '/workshop-detail') {
                    setPaymentval(false);
                    navigate(['/my-workshop']);
                }
            } else {
                setLoder(false);
            }
            setLoder(false);
        } else {
            setLoder(false);
            return false;
        }
    }

    const [error, setError] = useState({
        cardName: "",
        cardNumber: "",
        valid: "",
        cvv: "",
        mode: "",
        transactionid: "",
        notes: "",
    });

    const errorHandler = (e) => {
        const { name, value } = e.target;
        setError((pre) => ({
            ...pre,
            [name]: "",
        }));
    };

    const Savecard = (event) => {
        if (event.target.checked == true) {
            setSavecard(1);
        }
        else { setSavecard(0); }
    }

    const validHandler = (e) => {
        const inputValue = e.target.value;

        // Check if the user pressed backspace
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            // If the last character is a '/', remove it
            if (inputValue.endsWith('/')) {
                setExpiryDate(inputValue.slice(0, -1));
            } else {
                // Otherwise, update the state with the modified input
                setExpiryDate(inputValue);
            }
        } else {
            // Check for the condition to add '/'
            if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
                setExpiryDate(inputValue + '/');
            } else if (inputValue.length === 8) {
                return;
            } else {
                // Update the state with the user's input
                setExpiryDate(inputValue);
            }
        }
    };


    // Get User Details From LocalStore
    const [userDetail, setUserDetail] = useState();

    useEffect(() => {
        const storedData = localStorage.getItem("userDetail");
        const parsedData = JSON.parse(storedData);
        setUserDetail(parsedData);
    }, [])

    const redirectUrl = () => {

    }

    const checkOutHandler = (msg) => { errorAlert(msg); }


    const checkOutHandler2 = () => {
        setPaymentval(true);
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }


    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                <div class="studio-details client-home-inner">
                    <div class="container">
                        <DashboardToSectionDetails />
                        <div class="row  mb-3 d-flex">
                            <div class="col-6">
                                {(view == 2 && currentUrl == '/workshop-detail') &&
                                    <div class="formgroup searchbar">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={search_text}
                                            onChange={searchHandler}
                                        />
                                        <button onClick={() => ClientList()}><i class="fas fa-search"></i></button>
                                    </div>
                                }
                            </div>
                            <div class="col-6 text-right ">
                                {userDetail && userDetail.role_id == 4 ?
                                    view == 1 && currentUrl == '/workshop-detail' &&
                                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                                        onClick={() => setView(2)}>Add Client </button>

                                    :

                                    businessDetail && businessDetail.isCloverPurchase ?
                                        <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                                            onClick={() => checkOutHandler2()}
                                        > checkout </button>
                                        :
                                        businessDetail &&
                                        <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                                            onClick={() => checkOutHandler(businessDetail.studio_message)}
                                        > checkout </button>
                                }
                                <button type="button" class="btn btn-blue-grad px-2 text-uppercase ml-2" onClick={() => window.history.back()}>Back </button>
                            </div>
                        </div >

                    </div >
                    {(view == 1 || view == 3) &&
                        <div >
                            <div class="client-class-inner mb-4">
                                <div class="container">
                                    <div class="row">
                                        {view == 3 &&
                                            <>
                                                <div class="col-12 mb-4" >
                                                    <p class="black-color font-w-100 text-uppercase font-20"> booking Detail for Client </p>

                                                    <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                                        <div class="client-slot-box d-flex ">
                                                            <div class="client-img">
                                                                <img src={selectedClientInfo.profile_img} class="img-fluid" />
                                                            </div>
                                                            <div class="row client-detail pl-3">
                                                                <div class="col-12">
                                                                    <p class="black-color  mb-0 d-flex">
                                                                        <span class="nametitle1 mr-5 ml-5">Name :  {selectedClientInfo.name}
                                                                            {selectedClientInfo.lastname} </span>
                                                                        <span class="nametitle1  mr-5">Email :  {selectedClientInfo.email} </span>
                                                                        <span class="nametitle1  ml-5 mr-5">Phone No :  {selectedClientInfo.country_code}   {selectedClientInfo.mobile} </span>
                                                                        <span class="nametitle1 mr-5">Age :  {selectedClientInfo.age}  </span>
                                                                        <span class="nametitle1 ">Gender :  {selectedClientInfo.gender} </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div class="col-12 col-md-4 mb-4">
                                            <p class="black-color font-w-100 text-uppercase font-20"> workshop </p>
                                            <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 text-center fixed-hight">
                                                <div class="row">
                                                    <div class="col-2 mb-3 ">
                                                        <img src={businessDetail && businessDetail.logo} class="new-log-img" />
                                                    </div>
                                                    <div class="col-10 text-left mb-3">
                                                        <p class="black-color font-w-100 text-uppercase font-20 ml-2 "> {classData && classData.workshop_name} </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4 mb-4">
                                            <p class="black-color font-w-100 text-uppercase font-20"> other details </p>
                                            <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 fixed-hight">
                                                <div class="row ">
                                                    <div class="col-6 col-md-6">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Date </p>
                                                            <h5 class="title black-color mb-0"> {classData && classData.start_date_utc_new}  </h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 border-left">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Capacity </p>
                                                            <h5 class="title black-color mb-0">  {classData && classData.capacity_used}  /  {classData && classData.total_capacity}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-6 col-md-6">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Start time </p>
                                                            <h5 class="title black-color mb-0"> {classData && classData.from_time_new}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 border-left">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> End Time</p>
                                                            <h5 class="title black-color mb-0"> {classData && classData.to_time_new}  </h5>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row mt-3">
                                                    <div class="col-6 col-md-6">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0">Location </p>

                                                            {classData && classData.location &&
                                                                <h5 class="heading-titlenew mb-0 black-color">
                                                                    {!classData.location_url &&
                                                                        <Link to={'#'} > {classData.location} </Link>}
                                                                    {classData.location_url &&
                                                                        <Link to={classData.location_url} target="_blank"
                                                                            title={classData.location_url}> {classData.location} </Link>}
                                                                </h5>}
                                                            {classData && classData.address &&
                                                                <h5 class="heading-titlenew mb-0 black-color" >
                                                                    <Link to={classData.address} target="blank">Other</Link></h5>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-3">
                                                    <div class="col-12 col-md-12">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Web Link </p>
                                                            {classData && classData.web_link &&
                                                                <h5 class="title black-color mb-0">
                                                                    <Link to={classData && classData.web_link}
                                                                        target="_blank"><i class="fa fa-link" aria-hidden="true" title={classData && classData.web_link}></i>
                                                                    </Link> </h5>}
                                                            {classData && !classData.web_link && <h5 class="title black-color mb-0">N/A </h5>}
                                                        </div>
                                                    </div >
                                                </div >

                                                {/* ************************************************************************* */}

                                                <div class="row mt-3">
                                                    <div class="col-12 col-md-12">
                                                        <div class="text-left">
                                                            <p class="font-14 black-color mb-0"> Dates </p>
                                                            {classData && classData.more_workshop && classData.more_workshop.map((e) => (
                                                                e.start && (
                                                                    <h5 key={e.id} className="">
                                                                        {moment(new Date(e.start * 1000)).format('DD MMM YYYY hh:mm A')} - {moment(new Date(e.end * 1000)).format('hh:mm A')}
                                                                    </h5>
                                                                )

                                                            ))}

                                                        </div>
                                                    </div >
                                                </div >
                                            </div >
                                        </div >

                                        <div class="col-12 col-md-4 mb-4">
                                            <p class="black-color font-w-100 text-uppercase font-20"> description </p>
                                            <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 text-left fixed-hight description-box">
                                                <p class="font-14 black-color mb-0"> Description </p>
                                                <p class="brand-color3 font-12 mb-2"> {classData && classData.workshop_description}  </p>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >


                            <div class="mb-5">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="white-bg box-shadow1 border-radius6 p-4">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="section-heading pt-2 pb-4 text-left">
                                                            <p class="black-color font-w-100 text-uppercase font-20"> staff / instructors </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="section-heading pt-2 pb-3 text-right">

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    {instrustorData && instrustorData.map((itemss) => (
                                                        <div class="col-12 col-md-3 mb-4" >
                                                            <div class="activity-card box-shadow1 border-radius6 white-bg p-4">
                                                                <div class="col-12 mt-0 mt-md-4 mt-lg-0">
                                                                    <div class="activity-card-details text-left">

                                                                        <Link to="Javascript:void(0);"
                                                                            onClick={() => redirectUrl(itemss.instructor_id, '/instructor-detail', '')}
                                                                        >
                                                                            <div class="text-center">
                                                                                <div class="img-height box-shadow1 mx-auto">
                                                                                    <img src={itemss.instructor_profile_img} class="img-fluid" />
                                                                                </div>
                                                                                <p class="black-color font-w-100 font-16 mt-3 mb-2"> {itemss.instructor_name} {itemss.instructor_lastname}  </p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                            <div class="mb-5">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            {customerDetail && customerDetail.length != 0 &&
                                                <>
                                                    <div>
                                                        <div class="row py-4">
                                                            <div class="col-8">
                                                                <h5 class="title black-color font-w-500 text-uppercase font-16 mb-0"> User Details </h5>
                                                            </div>

                                                        </div>

                                                        <div class="row  mt-3">
                                                            {customerDetail.map((items) => (
                                                                <div class="col-md-4 mb-4">

                                                                    <div class="class-detail-slot-box box-shadow1 border-radius6 p-3 bg-white">
                                                                        <div class="row">
                                                                            <div class="col-3">
                                                                                <div class="profile-img box-shadow1">
                                                                                    <img src={items.profile_img} class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-9">
                                                                                <h5 class="brand-color3 heading-titlenew mb-1">{items.name}
                                                                                    {items.lastname} </h5>
                                                                                <ul class="d-none">
                                                                                    <li class="flex-fill">
                                                                                        <p class="black-color paratitle font-w-400 font-14"> {items.Age} ,  {items.gender} </p>
                                                                                    </li>
                                                                                </ul>
                                                                                <div>
                                                                                    <div class="d-flex align-items-center">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {customerDetail && customerDetail.length == 0 &&
                                                <div class="row" >
                                                    <div class="text-center">
                                                        <p class="font-awesome black-color font-18 d-inline mx-2 "> No User Available</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div >
                                </div >
                            </div >

                            <div class="container pb-5 d-flex">
                                <div class="col-6 col-lg-6 mt-5 mt-lg-0">
                                    <div class="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                                        <div class="px-3 py-3 text-left light-bg border-t-lr-6">
                                            <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                                        </div>

                                        <div class="px-3 pt-4 pb-2 border-bottom">
                                            <div class="row mb-2">
                                                <div class="col-6 text-left">
                                                    <p class="black-color font-w-100 font-18"> Workshop Fees </p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p class="black-color font-w-100 font-18">      {classData && classData.workshop_price}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6 text-left">
                                                    <p class="black-color font-w-100 font-18"> Tax 1 </p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p class="black-color font-w-100 font-18">     {classData && classData.tax1_rate}  </p>
                                                </div>
                                                <div class="col-6 text-left">
                                                    <p class="black-color font-w-100 font-18"> Tax 2 </p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p class="black-color font-w-100 font-18">      {classData && classData.tax2_rate}  </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="px-3 pt-4 pb-3 border-bottom">
                                            <div class="row mb-2">
                                                <div class="col-6 text-left">
                                                    <p class="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p class="title black-color font-w-100 font-18 mb-0">
                                                        {classData && classData.workshop_total_price ? classData.workshop_total_price : ''}  </p>
                                                </div>
                                            </div>
                                        </div>
                                        {currentUrl == '/workshop-detail' &&
                                            <div >
                                                {!paymentval && classData && classData.is_purchase == 0 &&
                                                    <div class="px-3 py-3 text-center" >
                                                        {businessDetail.isCloverPurchase &&
                                                            <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5 mt-2"
                                                                onClick={() => setPaymentval(true)}
                                                            > checkout </button>}
                                                        {!businessDetail.isCloverPurchase &&
                                                            <p class="black-color font-w-100 font-18 mb-0 mb-3"> {businessDetail.studio_message} </p>}
                                                    </div>
                                                }
                                            </div >
                                        }
                                        {currentUrl == '/workshop-detail' && view == 3 &&
                                            <div class="px-3 py-3 text-center" >
                                                <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5 mt-2"
                                                    onClick={() => setInstructorpayment(true)}
                                                > checkout </button >
                                            </div >
                                        }
                                    </div >
                                </div >
                                {instructorpayment &&
                                    <div class="col-6 col-lg-6 mt-5 mt-lg-0" >
                                        <div class="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                                            <div class="px-3 py-3 text-left light-bg border-t-lr-6">
                                                <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> credit card / debit card</h5>
                                            </div>
                                            {instructorpayment &&
                                                <div class="mt-4 ml-3" >
                                                    <p class="brand-color3 font-14 mb-2">Payment Mode</p>
                                                    <div class="d-flex flex-wrap">
                                                        <div class="radiotype-btn">
                                                            <input
                                                                type="radio"
                                                                className="customradio"
                                                                id="1"
                                                                value="cash"
                                                                name="paymentMethod"
                                                                checked={paymentMode == 'cash'} // Check conditionally based on the paymentMode state
                                                                onChange={getpaymentMode} // Attach the event handler for changes
                                                            />
                                                            <label>Cash/Check</label>
                                                        </div>
                                                        {businessDetail.isCloverPurchase &&
                                                            <div class="radiotype-btn ml-3" >
                                                                <input
                                                                    type="radio"
                                                                    className="customradio"
                                                                    id="3"
                                                                    value="card"
                                                                    name="paymentMethod"
                                                                    checked={paymentMode == 'card'} // Check conditionally based on the paymentMode state
                                                                    onChange={getpaymentMode} // Attach the event handler for changes
                                                                />
                                                                <label>Card</label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div >
                                            }
                                            {paymentMode == '3' &&
                                                <div class="card-details" >
                                                    <div class="client-payment-input border-radius6 box-shadow1 white-bg p-4">
                                                        <div class="row">
                                                            {customerData && customerData.map((cardsave) => (
                                                                <div class="col-12 mb-3" >
                                                                    {cardsave && cardsave.length != 0 &&
                                                                        <div class="client-card-img white-bg border-radius6 box-shadow1"  >
                                                                            <label class="select-card select-card-img select-card-cvv mb-2">
                                                                                {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                                                                    <img src="clientassets/img/visa_card.png" class="img-fluid payment_img" />}
                                                                                {cardsave.card_type == 'MC' &&
                                                                                    <img src="clientassets/img/master_card.png" class="img-fluid payment_img" />}
                                                                                {cardsave.card_type == 'AM' &&
                                                                                    <img src="clientassets/img/american_express.png" class="img-fluid payment_img" />}


                                                                                <p class="mb-0 font-12 dark-color mt-2">{cardsave.number}  </p>
                                                                                <h5 class="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                                                                <p class="mb-0 font-12 dark-color mt-2"> {cardsave.expiry_month}  /  {cardsave.expiry_year} </p>
                                                                                <input
                                                                                    type="radio"
                                                                                    name="radio"
                                                                                    value={cardsave.card_id} // Replace with the appropriate card ID value
                                                                                    checked={cardType === cardsave.card_id} // Check conditionally based on the cardType state
                                                                                    onChange={handleRadioChange} // Attach the event handler for changes
                                                                                />
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            {cardType == cardsave.card_id &&
                                                                                <form>
                                                                                    <div class="text-center d-flex">
                                                                                        <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-3 ml-5"
                                                                                            onClick={() => savePayment(cardsave)}
                                                                                        > Proceed to pay </button>
                                                                                    </div>
                                                                                </form>
                                                                            }
                                                                        </div >
                                                                    }
                                                                </div >
                                                            ))}
                                                            <div class="col-12 mb-3">
                                                                <div class="white-bg border-radius6 box-shadow1">
                                                                    <label class="select-card"> New Card
                                                                        <input
                                                                            type="radio"
                                                                            name="radio1"
                                                                            id="new"
                                                                            value="new"
                                                                            checked={cardType === "new"} // Check conditionally based on the cardType state
                                                                            onChange={handleRadioChange} // Attach the event handler for changes
                                                                        />
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div >
                                                    </div >
                                                    {cardType == 'new' &&
                                                        <DebitCreditCard />
                                                    }
                                                </div >
                                            }
                                            {paymentMode == '1' &&
                                                <div class="card-details" >
                                                    <form>
                                                        <div class="d-flex flex-wrap py-3">
                                                            <div class="col-md-12 form-group">
                                                                <label>Transaction Number</label>
                                                                <input type="text" class="form-control" formControlName="referenceid"
                                                                    name='referenceid'
                                                                    value={referenceDetail.referenceid}
                                                                    onChange={referenceHandler}
                                                                />
                                                                <small className='text-danger'>{referenceDetailError.referenceid}</small>

                                                            </div >
                                                            <div class="col-md-12 form-group">
                                                                <label>Notes</label>
                                                                <input type="text" class="form-control" formControlName="payment_note"
                                                                    name='payment_note'
                                                                    value={referenceDetail.payment_note}
                                                                    onChange={referenceHandler}
                                                                />
                                                                <small className='text-danger'>{referenceDetailError.payment_note}</small>
                                                            </div>
                                                        </div >
                                                        <div class="px-3 py-2 text-center">
                                                            <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase"
                                                                onClick={() => PayAtStudio()}
                                                            >
                                                                Proceed to pay </button>
                                                        </div >
                                                    </form >

                                                </div >
                                            }
                                        </div >
                                    </div >
                                }



                                {paymentval &&
                                    <div class="col-6 col-lg-6 mt- mt-lg-0">
                                        <div class="client-buy-process border-radius6 box-shadow1 white-bg">
                                            <div class="p-3 text-left light-bg border-t-lr-6">
                                                <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> credit card / debit card </h5>
                                            </div>

                                            <div class="px-3 pt-4 pb-3 border-bottom">
                                                <div class="row mb-2">
                                                    <div class="col-6 text-left">
                                                        <p class="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <p class="title black-color font-w-100 font-18 mb-0">
                                                            {classData && classData.workshop_total_price ? classData.workshop_total_price : ''}  </p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="client-payment-input border-radius6 box-shadow1 white-bg p-4">

                                                <div class="row">
                                                    {customerData && customerData.length > 0 && customerData.map((cardsave) => (
                                                        <div class="col-12 mb-3" >
                                                            {cardsave.length != 0 && currentUrl == '/workshop-detail' &&
                                                                <div class="client-card-img white-bg border-radius6 box-shadow1" >
                                                                    <label class="select-card select-card-img select-card-cvv mb-2">
                                                                        {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                                                            <img src="clientassets/img/visa_card.png" class="img-fluid payment_img" />}
                                                                        {cardsave.card_type == 'MC' &&
                                                                            <img src="clientassets/img/master_card.png" class="img-fluid payment_img" />}
                                                                        {cardsave.card_type == 'AM' &&
                                                                            <img src="clientassets/img/american_express.png" class="img-fluid payment_img" />}
                                                                        <p class="mb-0 font-12 dark-color mt-2"> {cardsave.number} </p>
                                                                        <h5 class="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                                                        <p class="mb-0 font-12 dark-color mt-2"> {cardsave.expiry_month}  /  {cardsave.expiry_year} </p>
                                                                        <input
                                                                            type="radio"
                                                                            name="radio"
                                                                            value={cardsave.card_id} // Replace with the appropriate card ID value
                                                                            checked={cardType === cardsave.card_id} // Check conditionally based on the cardType state
                                                                            onChange={handleRadioChange} // Attach the event handler for changes
                                                                        />
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    {cardType === cardsave.card_id &&
                                                                        <form >
                                                                            <div class="text-center d-flex">
                                                                                <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-3 ml-5"
                                                                                    onClick={() => savePayment(cardsave)}
                                                                                > Pay </button>

                                                                            </div>
                                                                        </form>
                                                                    }
                                                                </div>
                                                            }
                                                        </div >
                                                    ))}
                                                    <div class="col-12 mb-3">
                                                        <div class="white-bg border-radius6 box-shadow1">
                                                            <label class="select-card"> New Card
                                                                <input
                                                                    type="radio"
                                                                    name="radio"
                                                                    id="new"
                                                                    value="new"
                                                                    checked={cardType === "new"} // Check conditionally based on the cardType state
                                                                    onChange={handleRadioChange} // Attach the event handler for changes
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div >
                                            </div >
                                            {cardType == 'new' &&
                                                <form onSubmit={payment} autoComplete='off'>

                                                    <div class="row py-4 px-3">
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <InputField
                                                                    name={"cardName"}
                                                                    error={error.cardName}
                                                                    lable={"Name on Card"}
                                                                    onChange={errorHandler}
                                                                />
                                                            </div >
                                                        </div >

                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group client-buy-input-cardno">
                                                                <InputField
                                                                    name={"cardNumber"}
                                                                    type={"number"}
                                                                    error={error.cardNumber}
                                                                    lable={"Card Number"}
                                                                    onChange={errorHandler}
                                                                />
                                                            </div >
                                                        </div >

                                                        <div class="col-12 col-sm-6 mt-2 mt-md-3">
                                                            <div class="form-group">
                                                                <p class="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                                                <input
                                                                    type="text"
                                                                    name={'valid'}
                                                                    className="form-control"
                                                                    placeholder="MM/YYYY"
                                                                    value={expirydate}
                                                                    onChange={validHandler}
                                                                    pattern="^[0-9]{2}/[0-9]{4}"
                                                                    autoComplete="new-password"
                                                                    lable={"Valid Through"}
                                                                />
                                                                <small className="text-danger">{error.valid}</small>
                                                            </div >
                                                        </div >

                                                        <div class="col-12 col-sm-6 mt-2 mt-md-3">
                                                            <div class="form-group">
                                                                <InputField
                                                                    type={"password"}
                                                                    name={"cvv"}
                                                                    error={error.cvv}
                                                                    lable={"CVV"}
                                                                    onChange={errorHandler}
                                                                />
                                                            </div >
                                                        </div >
                                                        {currentUrl == '/workshop-detail' &&
                                                            <div class="col-12" >
                                                                <input type="checkbox" onChange={(e) => Savecard(e)} /> Save Card
                                                            </div >
                                                        }
                                                        <div class="w-100 py-5 text-center">
                                                            <button type="submit" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"

                                                            > Proceed to pay </button>
                                                        </div >

                                                    </div >
                                                </form >
                                            }
                                        </div >

                                    </div >
                                }

                            </div >
                        </div >
                    }
                    {view == 2 &&
                        <div>
                            <div class="container">
                                <div class="col-12 mb-4 mt-4">
                                    <div class="white-bg box-shadow1 border-radius6 p-3 mt-3 text-center fixed-hight">
                                        <div class="row">
                                            <div class="col-12">
                                                {clientDetail.length != 0 &&
                                                    <div class="row">
                                                        {clientDetail.map((items) => (
                                                            <div class="col-lg-4 py-3 ">
                                                                <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                                                    <div class="client-slot-box d-flex ">
                                                                        <div class="client-img">
                                                                            <img src={items.profile_img} class="img-fluid" />
                                                                        </div>
                                                                        <div class="row client-detail pl-3">
                                                                            <div class="col-12">
                                                                                <p class="black-color font-14 font-w-600 mb-0 d-flex">
                                                                                    <span class="nametitle d-block">{items.name + '  '}   {items.lastname} </span>
                                                                                </p>
                                                                                <span class="brand-color3 font-w-500 mt-2 d-block"> {items.email} </span>
                                                                                <span class="brand-color3 font-w-500 mt-2 d-block"> {items.country_code + '  '}
                                                                                    {items.mobile} </span>
                                                                                {items && items.is_purchase == '0' &&
                                                                                    <button onClick={() => booking(items)} class="btn btn-blue mt-2" >Purchase</button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                }
                                                {clientDetail.length == 0 &&
                                                    <div class="row" >
                                                        <div class="col-5"></div>
                                                        <div class="col-2">
                                                            <p class="font-awesome black-color font-18 d-inline mx-2"> No Client Available</p>
                                                        </div>
                                                        <div class="col-5"></div>
                                                    </div>
                                                }
                                            </div>

                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    }
                </div >
            </div >
            <Innerfooter />
        </>
    )
}
