import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
export default function Test() {

  const handleClick = () => {
    window.print(); // This will open the print dialog
  }

  return (
    <>
        <button type='button' onClick={handleClick}>Print</button>
    </>
  )
}
