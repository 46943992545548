import React, { useState } from "react";
import StaticFooter from "../../staticpagis/StaticFooter";
import { Col,  Row,  Spinner } from "react-bootstrap";
import { InputField } from "../../sharedComponents/InputField";
import { SharedButton } from "../../sharedComponents/Button";
import StaticNav from "./StaticNav";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { businessProfile, getBusinessMultiCatList } from "../../Studio-Services/Studio_Services";
import PlaceAutoCompleted from "../../client-staff/Components/CommanPages/PlaceAutoCompleted";
import Swal from "sweetalert2";
import logo from '../../Images/logo_white_web.png';
import MultiSelectDropdown from "./MultiSelectDropdown";

export default function   BusinessLocation() {
  const [loder, setLoder] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const location = useLocation();
  const [multipleCategories, setMultipleCategories] = useState([]);
  const [cordinates, setCordinates] = useState();
  const navigate = useNavigate();
  const [pmainFinal,setPmainFinal] = useState();
  const [pinnerFinal,setPinnerFinal] = useState();


  const chieldHandler = (upper,inner) =>{
    setPmainFinal(upper);
    setPinnerFinal(inner);
  }
  

  useEffect(() => {
    let getcurrentUser = JSON.parse(localStorage.getItem('st_userData'));
    setCurrentUser(getcurrentUser);
    if (getcurrentUser === null) {
      setCurrentUser(JSON.parse(localStorage.getItem('st_userRegisterData')));
    }
    businessCategoryMultiple();
  }, [location]);


  const businessCategoryMultiple = async () => {
    try {
      const fdata = { "type": "2" };
      const resp = await getBusinessMultiCatList(fdata);
      if (resp) {
        const data = resp.data.data;
        const updatedCategories = data.map(category => ({
          ...category,
          isChecked: false,
          subcategory: category.subcategory.map(subcat => ({
            ...subcat,
            isChecked: false,
          })),
        }));
        setMultipleCategories(updatedCategories);

      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }

  const [selectedFile, setSelectedFile] = useState(null);
  const [imgURL, setImgURL] = useState(null);
  
  const uploadImage = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setImgURL(event.target.result);
      };
    }
  };

  const [inputs, setInputs] = useState({ business_name: '', business_address: '', owner_details: '', phonenumber: '', primaryemail: '', website: '' })
  const [error, setError] = useState({ business_name: '', business_address: '', owner_details: '', phonenumber: '', primaryemail: '', website: '' })

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInputs((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: '' }));
  }

  const transformCategories = (mainCategories, subCategories) => {
    if(mainCategories.length >0){
      const final=[];
      for(let m=0;m<mainCategories.length ;m++){
        final.push({ "category": mainCategories[m].category_id ,"subcategory_info":[]});
       if(subCategories.length > 0){
        for(let i=0;i<subCategories.length ;i++){
          if(mainCategories[m].category_id===subCategories[i].id){
            final[m].subcategory_info.push({"subcategory":subCategories[i].category_id});
          }
        }
       }
      }
      return final;
    }
    };

  const setBusinessprofile = async (e) => {
    e.preventDefault();
    let catInformation = transformCategories(pmainFinal,pinnerFinal);

    let isValid = 1;
    const phoneRegex = /^\d{10}$/;
    // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (inputs && !inputs.primaryemail) {
      setError((pre) => ({ ...pre, primaryemail: 'Email must be a valid email address' }));
      isValid=2;
  }
    if (inputs && inputs.phonenumber && !phoneRegex.test(inputs.phonenumber)) { setError((pre) => ({ ...pre, phonenumber: 'in-correct phone number' }));  isValid=3;}
    if (inputs && !inputs.business_name) { setError((pre) => ({ ...pre, business_name: 'Business Name is required' })); isValid=4;}
    if (!cordinates) { setError((pre) => ({ ...pre, business_address: 'Business Address is required' })); isValid=5;}
    if (cordinates && !cordinates.getLocation_name) { setError((pre) => ({ ...pre, business_address: 'Business Address is required' })); isValid=5;}
    if (inputs && !inputs.owner_details) { setError((pre) => ({ ...pre, owner_details: 'Owner name is required' })); isValid=6;}
    if (inputs && !inputs.phonenumber) { setError((pre) => ({ ...pre, phonenumber: 'Phone number is required' }));isValid=7; }
    if (inputs && !inputs.primaryemail) { setError((pre) => ({ ...pre, primaryemail: 'Email is required' }));isValid=8; }
    if (inputs && !inputs.website) { setError((pre) => ({ ...pre, website: 'Website url is required' }));isValid=9; }

    if(isValid===1){
      setLoder(true);
      let currentUser =   (localStorage.getItem('st_userData')) ? JSON.parse(localStorage.getItem('st_userData')) :  JSON.parse(localStorage.getItem('st_userRegisterData'));
      var formData = new FormData();
      formData.append('user_id', currentUser.id);
      formData.append('business_name', inputs.business_name);
      formData.append('category_info', JSON.stringify(catInformation));
      formData.append('email', inputs.primaryemail);
      formData.append('mobile', inputs.phonenumber);
      formData.append('website', inputs.website);
      formData.append('business_logo', selectedFile);
      formData.append('business_address', cordinates.getLocation_name);
      formData.append('owner_details', inputs.owner_details);
      formData.append('lat', cordinates.getLat);
      formData.append('lang', cordinates.getLng);
      formData.append('country', cordinates.getCountry);
      formData.append('state', cordinates.getState);
      formData.append('city',cordinates.getCity);
      formData.append('location_name', cordinates.getLocation_name);
      formData.append('zipcode', cordinates.getZIP_Code);
      const resp = await businessProfile(formData);
      if(resp){
        setLoder(false);
        localStorage.setItem('st_businessPrfoileData', JSON.stringify(resp.data.data)); 
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='image' />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          SUCCESS
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        }).then((result) => {
          if (result.isConfirmed) {
              navigate('/business-locations');
            }
        });
      }else{
        setLoder(false);
      }
    }
  }

  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <StaticNav />
      <section className="Location-container">
        <div className="background-before">
          <div className="py-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="pricing-studio text-center wow animated slideInLeft mb-0">
                    <h5 className="dark-color font-w-500 pb-0 text-left">
                      Please Tell Us About Your Business So Your Customers Can Find You Easily
                    </h5>
                    <p className="text-left mb-3">Your full studio details will be listed here such as Head Quarters address,legal name, owner details, phone number, contact details and logo image and package details.</p>
                  </div>
                  <div className="login-boxs border-radius10 p-4">
                    <p className="text-left m-0 text-capital pb-3">
                      Please Tell us your business details
                    </p>

                    <Row>
                      <label className="profilelabel">Business Logo (jpeg, png)</label>
                      <Col md={2}>
                        <div className="form-group">
                          <div className="custom-uploadfile mb-3">
                            {!imgURL &&
                              <div className="uploadicon text-center">
                                <BsFillCloudUploadFill />
                                <h6 className="text-center">Upload Profile <br /> Image</h6>
                              </div>
                            }
                            {imgURL &&
                              <button className="profileImgages" type="button">
                                <img src={imgURL} className="img-fluid" style={{ height: "100px" }} alt="image" />
                                <span className="d-block font-14">Change image </span>
                              </button>
                            }
                            <input type="file" className="fileuploadinput" accept="image/x-png,image/gif,image/jpeg"
                              formControlName="profileimg" onChange={(e) => uploadImage(e)} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col md={12} className="mb-3">
                      <MultiSelectDropdown  multipleCategories={multipleCategories} chieldHandler={chieldHandler}/>
                      </Col>
                      <Col md={6} className="mb-3">
                        <InputField lable={"Business Name"} type={'text'} name={'business_name'} onChange={inputHandler} error={error.business_name} />
                      </Col>  
                      <Col md={6} className="mb-3">
                        <InputField lable={"Owner Name"} type={'text'} name={'owner_details'} error={error.owner_details} onChange={inputHandler} />
                      </Col>                    
                    
                    <Col md={12} className="mb-3">
                        <PlaceAutoCompleted
                          label={'Head Quarters Address'}
                          setCordinates={setCordinates}
                        />
                        <small className="text-danger">{error.business_address}</small>
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-4">
                      <Col >
                        <h4>CONTACT DETAILS</h4>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md={4}>
                        <InputField lable={"Telephone/ Landline"} type={'number'} name={'phonenumber'}  error={error.phonenumber} onChange={inputHandler} />
                      </Col>
                      <Col md={4}>
                        <InputField lable={"Primary Email ID"} type={'email'} name={'primaryemail'} error={error.primaryemail} onChange={inputHandler} />
                      </Col>
                      <Col md={4}>
                        <InputField lable={"Website"} type={'text'} name={'website'} error={error.website} onChange={inputHandler} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <SharedButton type={'button'} title={"Save & Continue"} variant={'primary'} onClick={setBusinessprofile} />
                      </Col>
                    </Row>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StaticFooter />
    </>
  );
}
