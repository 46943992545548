import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Row, Col, Spinner, Modal, Form } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import { SharedDropDown } from "../../../../../sharedComponents/SharedDropDown";
import { ClassScheduleModal } from "../../../../../sharedComponents/ClassScheduleModal";
import {
  InstructorList,
  cancel_single_class,
  class_scheduling_update,
  getClassDetails,
  get_room_location,
  passes_status_change,
  update_multiple_business_status,
} from "../../../../../Studio-Services/Studio_Services";
import moment from "moment";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png";
import { CardCarousel } from "../../../../../sharedComponents/CardCarousel";
import { RiEditFill } from "react-icons/ri";
import { MemberProductCart } from "../../../../../sharedComponents/MemberProductCart";
import { MemberShipCard } from "../../../../../sharedComponents/MemberShipCard";
import { ClassCardCarousel } from "./ClassCardCarousel";
import { errorAlert, successAlert } from "../../../../../Static_Services/Alertmsg";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";

export const ClassSchedulePreview = ({ setLoder, preinfodata }) => {
  const [preinfo, setPreinfo] = useState({ class_id: '', scheduled_date: '', schedule_id: '', business_id: '' });
  const [updata, setUpdata] = useState({
    "class_days_prior_signup": "", "class_waitlist_overflow": "", "class_location": "", "class_id": "", "class_waitlist_count": ""
  })
  const [overflow, setOverflow] = useState("no");

  useEffect(() => {
    if (preinfodata) {
      setPreinfo(() => ({
        "class_id": preinfodata.class_id,
        "scheduled_date": preinfodata.scheduled_date,
        "schedule_id": preinfodata.schedule_id,
        "business_id": preinfodata.business_id
      }));
      const data = preinfodata.fullData;
      setUpdata({
        "class_id": data.class_id,
        "class_days_prior_signup": data.class_days_prior_signup,
        "class_waitlist_overflow": data.class_waitlist_overflow,
        "class_location": data.room_location,
        "class_waitlist_count": data.class_waitlist_count
      })
      if (data && data.class_waitlist_overflow == "yes") { setOverflow("yes") }
    } else {
      setPreinfo(() => ({
        "class_id": '',
        "scheduled_date": '',
        "schedule_id": '',
        "business_id": ''
      }))
      setUpdata({
        "class_days_prior_signup": "", "class_waitlist_overflow": "", "class_location": "", "class_id": "", "class_waitlist_count": ""
      })
    }
  }, [preinfodata]);

  const [classDetail, setClassDetail] = useState([]);
  const [insructor, setInstructor] = useState([]);
  const [clientdata, setClientdata] = useState([]);
  const [timepass, setTimepass] = useState([]);
  const [recurrigpass, setRecurrigpass] = useState([]);
  const [punchpass, setPunchpass] = useState();
  const [countNum, setCountNum] = useState();
  const inputRef = useRef();
  const navigate = useNavigate();
  const [waitList, setWaitList] = useState([]);

  const addTenYears = (originalDate) => {
    const currentDate = new Date(originalDate);
    currentDate.setFullYear(currentDate.getFullYear() + 10);
    return moment(currentDate).format('DD MMM YYYY');
  };


  const classDetails = async () => {
    setLoder(true);
    const responce = await getClassDetails(preinfo);
    if (responce) {
      setLoder(false);
      localStorage.setItem('sheDuleId INT', responce.data.data.schedule_id);
      setClassDetail(responce.data.data);
      setCountNum(responce.data.data.upcomming_count);
      let clsdata = [];
      let clsWaitList = [];
      const TotalData = (responce.data.data.booked_users_Attendance).concat(responce.data.data.booked_users_WiitList).concat(responce.data.data.booked_users_Attendance_cancel).concat(responce.data.data.booked_users_NoShow);
      const TotalCapacity = responce.data.data.total_capacity;


      TotalData.forEach((e, index) => {
        let current_pass_name = e.my_passes_details.find((f) => f.pass_id == e.current_pass_id);



        if (TotalCapacity > index || e.status == 'checkin' || e.status == 'cancel') {
          
          clsdata.push({
            Name: (
              <div className="ClientImg">
                <img
                  src={e.profile_image ? e.profile_image : logo}
                  alt=""
                  className="img-fluid"
                />
                {e.name} {e.lastname}
              </div>
            ),
            MobileNo: e.mobile ? e.mobile : "N/A",
            PassName: current_pass_name ? current_pass_name.pass_name : e.current_pass_name,
            Punches: current_pass_name && current_pass_name.remaining_count && current_pass_name.pass_type_category === 'PunchCard' ? `${current_pass_name.remaining_count} /  ${current_pass_name.total_count}` : "N/A",
            ExpDate: current_pass_name ? current_pass_name.pass_expired_date ? moment.unix(current_pass_name.pass_expired_date).format("DD MMM YYYY") : current_pass_name.end_date ? current_pass_name.end_date  : '' :"N/A",
            AttendanceStatus: (
              <div className="AttendanceBtn">
                <SharedButton
                  variant={e.status === "singup" ? "primary" : "outline"}
                  onClick={() =>
                    changeStatus(
                      e.service_type,
                      e.service_id,
                      e.user_id,
                      e.attendance_id,
                      responce.data.data.schedule_id,
                      "notcheckin",
                      e.status
                    )
                  }
                  className={"attendanceBTN btn btn-primary"}
                  title={"Signed Up"}
                />
                <SharedButton
                  variant={e.status === "checkin" ? "primary" : "outline"}
                  onClick={() =>
                    changeStatus(
                      e.service_type,
                      e.service_id,
                      e.user_id,
                      e.attendance_id,
                      responce.data.data.schedule_id,
                      "checkin",
                      e.status
                    )
                  }
                  className={"attendanceBTN btn btn-primary"}
                  title={"Checked In"}
                />
                <SharedButton
                  variant={e.status === "cancel" ? "primary" : "outline"}
                  onClick={() =>
                    changeStatus(
                      e.service_type,
                      e.service_id,
                      e.user_id,
                      e.attendance_id,
                      responce.data.data.schedule_id,
                      "cancel",
                      e.status
                    )
                  }
                  className={"attendanceBTN btn btn-primary"}
                  title={"Cancelled"}
                />
                <SharedButton
                  variant={e.status === "absence" ? "primary" : "outline"}
                  onClick={() =>
                    changeStatus(
                      e.service_type,
                      e.service_id,
                      e.user_id,
                      e.attendance_id,
                      responce.data.data.schedule_id,
                      "noshow",
                      e.status
                    )
                  }
                  className={"attendanceBTN btn btn-primary"}
                  title={"NoShow"}
                />
                <SharedButton
                  variant={e.status === "remove" ? "primary" : "outline"}
                  onClick={() =>
                    changeStatus(
                      e.service_type,
                      e.service_id,
                      e.user_id,
                      e.attendance_id,
                      responce.data.data.schedule_id,
                      "remove",
                      e.status
                    )
                  }
                  className={"attendanceBTN btn btn-primary"}
                  title={"Remove"}
                />
              </div>
            ),
          });
        } else {          
          clsWaitList.push({
            Name: (
              <div className="ClientImg">
                <img
                  src={
                    e.profile_image
                      ? e.profile_image
                      : logo
                  }
                  alt=""
                  className="img-fluid"
                />
                {e.name} {e.lastname}
              </div>
            ),
            MobileNo: e.mobile,
            PassName: current_pass_name.pass_name,
            Punches: current_pass_name && current_pass_name.remaining_count && current_pass_name.pass_type_category === 'PunchCard' ? `${current_pass_name.remaining_count} /  ${current_pass_name.total_count}` : "N/A",
            ExpDate: current_pass_name ? current_pass_name.pass_expired_date ? moment.unix(current_pass_name.pass_expired_date).format("DD MMM YYYY") : current_pass_name.end_date ? current_pass_name.end_date  : '' :"",
            //current_pass_name.end_date,
            AttendanceStatus: (
              <div className="AttendanceBtn">
                <SharedButton
                  variant={e.status === "checkin" ? "primary" : "outline"}
                  onClick={() =>
                    changeStatus(
                      e.service_type,
                      e.service_id,
                      e.user_id,
                      e.attendance_id,
                      responce.data.data.schedule_id,
                      "checkin",
                      e.status
                    )
                  }
                  className={"attendanceBTN btn btn-primary"}
                  title={"Checked In"}
                />
              </div>
            ),
          })
        }
      });
      setClientdata(clsdata);
      setWaitList(clsWaitList);
    } else {
      setLoder(false);
      localStorage.removeItem('sheDuleId INT');
    }
  };


  const getInstructorList = async () => {
    const pageId = { pageid: 1 };
    const resp = await InstructorList(pageId);
    if (resp) {
      setInstructor(resp.data.data);
    }
  };

  useEffect(() => {
    if (preinfo.class_id !== '') {
      classDetails();
    }
  }, [preinfo])


  useEffect(() => {
    getInstructorList();
  }, []);

  const classdetailcolumns = [
    { dataField: "Date", text: "Date" },
    { dataField: "Time", text: "Time" },
    { dataField: "Class", text: "Class" },
    { dataField: "Instructor", text: "Instructor" },
    { dataField: "Attendance", text: "Attendance" },
    { dataField: "Location", text: "Location" },
  ];



  const classDetailtableData = [
    {
      Date: `${classDetail.day_name}  ${moment(
        classDetail.scheduled_date,
        "YYYY-MM-DD"
      ).format("MMM DD/YY")}`,
      Time: `${moment.unix(classDetail.from_time).format("hh:mm A")} - ${moment
        .unix(classDetail.to_time)
        .format("hh:mm A")}`,
      Class: classDetail.class_name,
      Instructor: (
        <div className="instructorimg">
          <img
            src={
              classDetail.instructor_image
                ? classDetail.instructor_image
                : logo
            }
            alt=""
            className="img-fluid"
          />
          <p>{classDetail.instructor_name}</p>
          <ClassScheduleModal
            updateList={classDetails}
            list={insructor}
            classDetail={classDetail}
            preinfo={preinfo}
          />
        </div>
      ),
      Attendance: classDetail.attendence,
      Location: (
        <>
        <Link target="blank" to={classDetail.location_url}>
          <h6>{classDetail.location}</h6>
        </Link>
        {classDetail && classDetail.zoom_link &&
         <Link target="blank" to={classDetail.zoom_link}>
         <h6>Zoom link</h6>
       </Link>
       }
       </>
      ),
    },
  ];

  const classAttendancecolumns = [
    { dataField: "Name", text: "Name" },
    { dataField: "MobileNo", text: "Mobile No" },
    { dataField: "PassName", text: "Pass Name" },
    { dataField: "Punches", text: "Punches" },
    { dataField: "ExpDate", text: "Exp Date" },
    { dataField: "AttendanceStatus", text: "Attendance Status" },
  ];

  const classWaitingcolumns = [
    { dataField: "Name", text: "Name" },
    { dataField: "MobileNo", text: "Mobile No" },
    { dataField: "PassName", text: "Pass Name" },
    { dataField: "Punches", text: "Punches" },
    { dataField: "ExpDate", text: "Exp Date" },
    { dataField: "AttendanceStatus", text: "Attendance Status" },
  ];


  // My Code

  const numChangeHandler = (event) => {
    setCountNum(event.target.value);
  };

  useEffect(() => {
    if (classDetail.passes_details) {
      let passlist = [];
      if ((classDetail.passes_details.length) > 0) {
        passlist = classDetail.passes_details;
      }

      const timep = [];
      const recuring = [];
      const punch = [];

      if (passlist.length > 0) {
        const logo = localStorage.getItem("logo");
        passlist.forEach((value) => {
          if (value.pass_type === "Time Frame") {
            timep.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: value.amount,
              StringTax: "Plus Tax"
            })
          } else if (value.pass_type === "Recurring Membership") {
            recuring.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: value.amount,
              StringTax: "Plus Tax"
            })
          } else if (value.pass_type === "Punch Card") {
            punch.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: value.amount,
              StringTax: "Plus Tax"
            })
          }
        });
        if (timep.length > 0) {
          setTimepass(timep);
        }
        if (recuring.length > 0) {
          setRecurrigpass(recuring);
        }
        if (punch.length > 0) {
          setPunchpass(punch);
        }
      }

    }
  }, [classDetail.passes_details]);


  const changeStatus = async (service_type, service_id, user_id, attendance_id, schedule_id, status, currentStatus) => {

    if (currentStatus !== status && status == 'remove') {
      Swal.fire({
        html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">CONFIRMATION</h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">Are you sure to change status ?</small>
            </p>
          `,
        showClass: {
          popup: "custom-dialog-container",
        },
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const finalData = {
            service_type: service_type,
            service_id: service_id,
            user_id: user_id,
            attendance_id: attendance_id,
            schedule_id: schedule_id ? schedule_id : "",
            passes_status: status,
          };
          const resp = await passes_status_change(finalData);
          if (resp) {
            classDetails();
            successAlert(resp.data.message);
          } else {
            setLoder(false);
          }
        }
      });
    } else {
      const finalData = {
        service_type: service_type,
        service_id: service_id,
        user_id: user_id,
        attendance_id: attendance_id,
        schedule_id: schedule_id ? schedule_id : "",
        passes_status: status,
      };
      const resp = await passes_status_change(finalData);
      if (resp) {
        classDetails();
        successAlert(resp.data.message);
      } else {
        setLoder(false);
      }
    }


    // else { successAlert('Success') }
  }


  const updateClass = async () => {
    let formData = "";
    if (classDetail.upcomming_count > countNum) {
      const dif = classDetail.upcomming_count - countNum;
      formData = {
        'business_id': classDetail.business_id,
        'class_id': classDetail.class_id,
        'schedule_id': classDetail.schedule_id,
        'cancel': dif,
        'from_time': classDetail.from_time,
        'to_time': classDetail.to_time,
        'location_id': classDetail.location_id,
        // 'total_capacity': classDetail.total_capacity,
      };
    } else {
      formData = {
        'business_id': classDetail.business_id,
        'class_id': classDetail.class_id,
        'schedule_id': classDetail.schedule_id,
        'cancel': countNum,
        'from_time': classDetail.from_time,
        'to_time': classDetail.to_time,
        'location_id': classDetail.location_id,
        'upcomming_count': classDetail.upcomming_count,
      };
    }
    if (formData) {
      const resp = await update_multiple_business_status(formData);
      if (resp) {
        successAlert(resp.data.message);
        classDetails();
      }
    }
  };

  const cancelClass = async () => {
    Swal.fire({
      html: `
      <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      <h4 class="title text-uppercase text-center mt-4 textWhite">CONFIRMATION</h4>
      <p class="text-center my-4 textcolor">
        <small class="textWhite">Are you sure to change status ?</small>
      </p>
    `,
      showClass: {
        popup: "custom-dialog-container",
      },
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const cancelFormData = {
          business_id: classDetail.business_id,
          class_id: classDetail.class_id,
          schedule_id: classDetail.schedule_id,
          from_time: classDetail.from_time,
          to_time: classDetail.to_time,
          location_id: classDetail.location_id,
        };
        const resp = await cancel_single_class(cancelFormData);
        if (resp.data.status === 1) {
          navigate("/classes");
        }
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire('Changes are not saved', '', 'info')
      // }
    });
  };

  const [editModal, setEditModal] = useState(false);

  const [locOption, setLocOption] = useState([]);

  const roomOpt = async () => {
    const fData = {
      business_id: localStorage.getItem("business_id"),
    };
    const resp = await get_room_location(fData);
    if (resp) {
      const loc = [];
      resp.data.data.map((e) => {
        loc.push({ value: e.location_name, label: e.location_name });
      });
      setLocOption(loc);
    }
  };
  useEffect(() => { roomOpt(); }, [])
  const waitListoptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const updateClassHandler = async (e) => {
    e.preventDefault();
    if (!updata.class_id) { errorAlert("Class Id Required"); return; }
    setLoder(true);
    const fdata = {
      "class_id": updata.class_id,
      // "class_location": updata.class_location,
      "class_days_prior_signup": updata.class_days_prior_signup,
      "class_waitlist_overflow": updata.class_waitlist_overflow,
      "class_waitlist_count": updata.class_waitlist_count
    }
    const resp = await class_scheduling_update(fdata);
    if (resp) {
      setLoder(false);
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      <h4 class="title text-uppercase text-center mt-4 textWhite">
            Success
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
      ${resp.data.message} </small></p>
      `,
        showClass: {
          popup: "custom-dialog-container",
        },
        customClass: {
          confirmButton: "custom-button custom-button-size modalbtn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setEditModal(false);
        }
      })
    }
  }

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setUpdata((pre) => ({ ...pre, [name]: value }));
    if (name === "class_waitlist_overflow") {
      if (value === "yes") {
        setOverflow("yes");
      } else {
        setOverflow("no");
      }
    }
  }


  return (
    <>

      <section className="ClassesDetails">
        <div className="ClassesDetails">
          <div className="ScheduleHeading">
            <h5 className="classesHeading">
              <Link to="/classes">
                <IoIosArrowBack />
                Classes Schedule Details
              </Link>
            </h5>
          </div>
          <div className="DetailsBox">
            <div className="HeadDetail">
              <h5>CLASS DETAILS</h5>
              <span className="contentLeft">
                <button className="new-btn" type="button" onClick={() => setEditModal(true)}>Edit</button>
                <button className="new-btn-cancel" type="button" onClick={cancelClass}>Cancel</button>
                {/* <SharedButton
                title={"Cancel"}
                className={"cancelBtn"}
                type={"butoon"}
               
                variant={"outline"}
              /> */}
              </span>
            </div>
            <Row>
              <Col>
                <DataTable data={classDetailtableData} columns={classdetailcolumns} />
              </Col>
            </Row>

            <div className="HeadDetail my-3">
              <h5 className="mr-3">Attendance</h5>
              <SharedButton
                type={"button"}
                variant={"btn btn-primary "}
                element={preinfo}
                href={"/client-search"}
                title={"Add New Client"}
              />

            </div>
            <Row>
              <Col>
                {/* *************** Attendance Table **************** */}
                {console.log("clientdata-------",clientdata)}
                <DataTable data={clientdata} columns={classAttendancecolumns} />
              </Col>
            </Row>
            <div className="HeadDetail my-3">
              <h5>Wait List</h5>
            </div>
            <Row>
              <Col>
                {/* ************************ Waite List Table **************** */}
                <DataTable data={waitList} columns={classWaitingcolumns} />
              </Col>
            </Row>
            <div className="TopBorderBox">
              <Row className="mt-3 pt-5 ">
                <Col>
                  <h5 className="title p-3 font-arialrouded mb-0">
                    Remaining Classes
                  </h5>
                </Col>
                <Col>
                  <input type="number"
                    className="form-control"
                    min="1"
                    max="260"
                    value={countNum}
                    onChange={numChangeHandler}
                  />
                </Col>
                <Col>
                  <SharedButton
                    type="button"
                    onClick={updateClass}
                    title="Update"
                    variant={'primary'}
                  />
                </Col>
              </Row>
            </div>

            <div className="TopBorderBox">
              <Row>
                <Col className="text-left">
                  <h6 className="InstrDetail">Available Passes</h6>
                </Col>
                {classDetail.upcomming ? (
                  <Col className="text-right">
                    <SharedButton
                      type={"submit"}
                      title={"Edit"}
                      href="/update-schedule-class-pass"
                      variant={"outline"}
                      element={
                        preinfo && preinfo.class_id ? preinfo.class_id : ""
                      }
                      iconNode={<RiEditFill size={20} />}
                      className={"FontIconGreen"}
                    />
                  </Col>
                ) : (
                  ""
                )}
                <h5>Recurring Membership</h5>

                <Row>
                  <Col>
                    <div className="RecurringMembership">
                      <div className="CardCarousel mt-5">
                        <ClassCardCarousel pass={recurrigpass} />
                      </div>
                      <div className="TimeCard mt-5">
                        <h5>Time Frame</h5>
                        <ClassCardCarousel pass={timepass} />
                      </div>
                      <div className="PunchCard my-5">
                        <h5>Punch Card</h5>
                        <ClassCardCarousel pass={punchpass} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Row>
            </div>
          </div>
        </div>
      </section>


      <Modal show={editModal} onHide={() => setEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="ml-2">{classDetailtableData[0].Class}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Form>
              <Row>
                {/* <Col md={6}>
                  <SharedSelect
                    options={locOption}
                    name="class_location"
                    value={updata.class_location}
                    lable={"Select Room Location"}
                    onChange={inputHandler}
                  />
                </Col> */}
                <Col md={6}>
                  <InputField lable={"Days Prior Clients can sign up"}
                    name="class_days_prior_signup"
                    value={updata.class_days_prior_signup}
                    onChange={inputHandler}
                    placeholder={"200"} 
                    minLength={"0"}
                    type={"number"}
                  />
                </Col>
                <Col md={6}>
                  <SharedSelect
                    options={waitListoptions}
                    name="class_waitlist_overflow"
                    value={updata.class_waitlist_overflow}
                    onChange={inputHandler}
                    lable={"Waitlist overflow"}
                  />
                </Col>
                <Col md={6}>
                  {overflow === "yes" ? (
                    <InputField
                      name="class_waitlist_count"
                      lable={"Overflow Count"}
                      onChange={inputHandler}
                      value={updata.class_waitlist_count}
                      type={"number"}
                      minLength={"0"}
                    />
                  ) : (
                    ""
                  )}</Col>
              </Row>
            </Form>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col md={6}>
              <SharedButton title={"UPDATE"} type={"submit"} onClick={updateClassHandler} variant={"primary"} className={"btn btn-primary w-100"} />
            </Col>
            <Col md={6}>
              <SharedButton title={"CANCEL"} type={"button"} variant={"outline"} onClick={() => setEditModal(false)} className={"btn btn-outline w-100"} />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
