import React from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import Sidebar from '../../comman_page/Sidebar'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { DataTable } from '../../../../../sharedComponents/DataTable'
import { get_recurring_amount_list, update_recurring_amount } from '../../../../../Studio-Services/Studio_Services'
import { useEffect } from 'react'
import { useState } from 'react'
import { errorAlert, successAlert } from '../../../../../Static_Services/Alertmsg'

export const RecurringAmountList = () => {
  const [listInfo, setListInfo] = useState();
  const [tableData, setTableData] = useState([]);
  const [currentRow, setCurrentRow] = useState();
  const [curruntAmount, setCurruntAmount] = useState();
  const [loder,setLoder] = useState(false);
  const columns = [
    { dataField: "fname", text: "First Name" },
    { dataField: "lname", text: "Last Name" },
    { dataField: "email", text: "Email" },
    { dataField: "pass_name", text: "Pass Name" },
    { dataField: "PauseStopDate", text: "Pause/Stop Date" },
    { dataField: "Restart_Date", text: "Restart Date" },
    { dataField: "Pass_Full_Amount", text: "Pass Full Amount [including tax]" },
    { dataField: "Amount_Next_Cycle", text: "Amount Next Cyclet [including tax]" },
    { dataField: "Action", text: "Action" },
  ];


  const goToEditPage = (data) => {
    setCurrentRow(data);
    setCurruntAmount(data.owner_amount);

  }

  const getFullamount = (amount,tax1,tax2)=>{
    const tax = parseFloat(tax1)+parseFloat(tax2);
    const totalTax = (parseFloat(amount)*tax)/100;
    const fullamt =parseFloat(amount)+totalTax;
    console.log('amount',amount,'tax1',tax1,"tax2",tax2);
    return parseFloat(fullamt).toFixed(2);

  }

  const getInfo = async () => {
    setLoder(true);
    const resp = await get_recurring_amount_list();
    if (resp) {
      setLoder(false);
      const td = [];
      setListInfo(resp.data.data);
      const getTD = resp.data.data;
      if (getTD && getTD.length > 0) {
        getTD.map((e) => {
          td.push({
            fname: e.name,
            lname: e.lastname,
            email: e.email,
            pass_name:e.pass_name,
            PauseStopDate: e.pause_date,
            Restart_Date: e.pass_restart_date,
            Pass_Full_Amount: `${getFullamount(e.pass_amount,e.pass_tex1,e.pass_tex2)}`,
            Amount_Next_Cycle: e.owner_amount === '0.00' ?`${getFullamount(e.pass_amount,e.pass_tex1,e.pass_tex2)}` : `${parseFloat(e.owner_amount).toFixed(2)}`,
            Action: <button onClick={() => goToEditPage(e)} ><i class="fas fa-pencil-alt"></i></button>
          })
        })
        setTableData(td);
      } else {
        setTableData([]);
      }
    }
    setLoder(false);
  }

  useEffect(() => {
    getInfo();
  }, [])


  const updateAmount = async () => {
    if (!curruntAmount) {
      errorAlert('Plese Select Amount');
      return
    } else {
      const tempObj = {
        id: currentRow.id,
        amount: curruntAmount,
      }
      const resp = await update_recurring_amount(tempObj);
      if (resp) {
        if (resp.data && resp.data.message) {
          successAlert(resp.data.message);
        }
        setCurrentRow();
        setCurruntAmount();
        getInfo();
      }
    }
  }

  const backHandler = () => {
    setCurrentRow();
    setCurruntAmount();
  }


  return (
    <>
      {loder ? <div className='MainLoader'>
         <Spinner animation="border" role="status" className='Loader'>  </Spinner>
          </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>Recurring Pass Billing</h4>
                    <p>
                      This report shows passes that have been paused starting this month. Click on the Action Icon to edit the amount of the next billing cycle.
                    </p>
                    <div className='AddClientForm AddClientContent'>
                      {!currentRow ?
                        <Row className="mt-3">
                          <Col>
                            <DataTable data={tableData} columns={columns} />
                          </Col>
                        </Row>
                        :
                        <div className="p-4 bg-white mt-4">
                          <div className="row mt-3">
                            <div className="col-8">
                              <div className="form-group">
                                {/* <label>Set amount (plus tax) for next billing cycle. </label> */}
                                <label>Set Amount (INCLUDING TAX) for next billing cycle in the box below</label>
                                <input type="text" name='selected_amount' value={curruntAmount} onChange={(e) => setCurruntAmount(e.target.value)} className="form-control" />
                              </div>
                            </div>
                            <div className="col-4" style={{ marginTop: "35px" }}>
                              <button className="btn btn-primary" onClick={updateAmount}>Update</button>
                              <button className="btn btn-danger ml-3"
                                style={{ padding: "0.6rem 1.5rem", fontSize: "1.2rem", fontWeight: "500" }}
                                onClick={backHandler}>Back</button>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
