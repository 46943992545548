import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { transectionDetail, transectionList_new } from "../../../../../Studio-Services/Studio_Services";
import { Link, useLocation } from "react-router-dom";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { AiFillEdit } from "react-icons/ai";
import { DateRange } from "../../../../../sharedComponents/DateRange";
import { TrTable } from "./TrTable";
import moment from "moment";
import { BiSearchAlt } from "react-icons/bi";
import { errorAlert } from "../../../../../Static_Services/Alertmsg";
import { finalAMT, taxCalculator } from "../../../../../sharedComponents/MyHelperFunction";

export const TransactionDetailReport = () => {
  const location = useLocation();
  const [loder, setLoder] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pymode, setPymode] = useState();


  const [pretrData, setPretrData] = useState([]);

  const [trData, setTrData] = useState([]);
  const [fullAm, setFullAm] = useState();


  const columns = [
    { dataField: "datetime", text: "Date/Time" },
    { dataField: "client", text: "Client" },
    { dataField: "method", text: "Method" },
    { dataField: "transaction", text: "Transaction" },
    { dataField: "item", text: "Item" },
    { dataField: "amount", text: "Amount" },
    { dataField: "detail", text: "Details" },
  ];

  const pymodeHandler = (mode) => {
    const premode = mode.replace(/\s/g, '').toLowerCase();
    if (premode === 'visa' || premode === 'mastercard' || premode === 'amex' || premode === 'cron') {
      return "Credit/Debit";
    }
    return mode;
  }

  const filtercardData = (data, name) => {
    let lb = 0; let lt1 = 0; let lt2 = 0; let tt = 0;
    let count = 0;
    data.forEach((e, index) => {
      count += 1;
      let ttx1 = taxCalculator(e.amount, e.tax1_rate);
      let ttx2 = taxCalculator(e.amount, e.tax2_rate);
      let fnl = finalAMT(e.amount, ttx1, ttx2);

      let tt_text = parseFloat(ttx1) + parseFloat(ttx2);

      let b_fore_tax = parseFloat(e.amount) - parseFloat(tt_text);
      let amt = parseFloat(e.amount);



      lb += parseFloat(b_fore_tax);
      lt1 += ttx1;
      lt2 += ttx2;
      tt += amt;
    })
    return { summary: name, transaction: count, before_tax: (lb).toFixed(2), tax_one: (lt1).toFixed(2), tax_two: (lt2).toFixed(2), total: (tt).toFixed(2), };
  }

  const getReport = async (data) => {
    setLoder(true);
    let array = [];
    let full_amount = 0;
    if (data && data.length > 0) {
      data.forEach(e => {
        let bs = parseFloat(e.amount).toFixed(2);
        let ds = parseFloat(e.discount).toFixed(2);
        let tx1 = taxCalculator(e.amount, e.tax1_rate);
        let tx2 = taxCalculator(e.amount, e.tax2_rate);
        let tt_tex = parseFloat(tx1)+parseFloat(tx2);
      
        let fnl = parseFloat(e.amount).toFixed(2); // finalAMT(e.amount, );
        full_amount += parseFloat(fnl);
        array.push({
          subtotal: fnl,
          datetime: moment.unix(e.create_dt).format('DD-MMM-YYYY, hh:mm A'),
          client: e.name + ' ' + e.lastname,
          method: e.payment_mode ? pymodeHandler(e.payment_mode) : "Credit Card",
          transaction: e.transaction_id,
          item: e.item_name,
          amount: "$ " + parseFloat(fnl).toFixed(2) || 0,
          refund_status: e.refund_status,
          refund_date: e.refund_date,
          refundNote: e.refundNote,
          detail: (
            <SharedButton
              className={'btn btn-outline btn btn-primary'}
              iconNode={<AiFillEdit />}
              type={"button"}
              href={"/trindetail"}
              element={e}
              variant={"outline"}
            />
          ),
        })
      });
      setPretrData(array);
      setTrData(array);
      setFullAm(parseFloat(full_amount).toFixed(2));
      setLoder(false);
    } else { setTrData([]); }
    setLoder(false);
  }



  useEffect(() => {
    if (location && location.state) { getReport(location.state.td); setPymode(location.state.paymode) }
  }, [location.state])

  const filterData = (startDate, endDate) => {
    let pre = [...pretrData];
    const fdata = pre.filter((item) => {
      if (startDate === "" || endDate === "") {
        return item; // Return true if either startDate or endDate is empty
      } else {
        const createDate = moment(item.datetime).format('DD-MM-YYYY');
        return moment(createDate, 'DD-MM-YYYY').isBetween(startDate, endDate, null, '[]');
        // '[]' includes both startDate and endDate in the range
      }
    });
    return fdata;
  };


  const searchHandler = async (e) => {
    if (!startDate || !endDate) { errorAlert("Please Slect Date"); return; }
    setLoder(true);
    const fdata = { "page_no": 1, 'todayDate': '', 'startDate': startDate, 'endDate': endDate, "paymode": pymode }
    const resp = await transectionList_new(fdata);
    if (resp) {
      setLoder(false);
      let pre = resp.data;
      getReport(pre);
    } else getReport([]);
    setLoder(false);
  }

  return (
    <>
      {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> </Spinner> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4 className="mb-4">Transaction Detail Report</h4>
                      <Row>
                        <Col>
                          <DateRange
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col>
                          <SharedButton
                            type={"submit"}
                            onClick={searchHandler}
                            iconNode={<BiSearchAlt />}
                            className={"btn btn-outline"}
                          />
                        </Col>
                        <Col className="text-right">
                          <SharedButton
                            title={"Back"}
                            className={"backBtn"}
                            type={"button"}
                            onClick={() => {
                              window.history.back();
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <div className="row transactionReport w-100">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Date/Time</th>
                                  <th>Client Name</th>
                                  <th>Method</th>
                                  <th>Transaction</th>
                                  <th>Item</th>
                                  <th>Amount</th>
                                  <th>Refunded</th>
                                  <th>Details</th>
                                </tr>
                              </thead>
                              <tbody style={{ fontSize: '14px' }}>
                                {trData && trData.length > 0 && trData.map((row, index) => <tr key={index}>
                                  <td>{row.datetime}</td>
                                  <td>{row.client}</td>
                                  <td>{row.method}</td>
                                  <td>{row.transaction}</td>
                                  <td>{row.item}</td>
                                  <td>{row.amount}</td>
                                  <td style={{ textAlign: "center" }}>{row.refund_status == 0 ? <span style={{ color: "green" }}>No</span> : <span style={{ color: 'red' }}>Yes</span>}</td>
                                  <td>{row.detail}</td>
                                </tr>)}
                                {trData && trData.length > 0 &&
                                  <tr className='highlighted highlighted-my'>
                                    <td>Total</td>
                                    <td colspan="4"></td>
                                    <td colspan="2" style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {fullAm}</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                            {trData && trData.length <= 0 && (<p className="text-center mt-4">No Data Available</p>)}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
