import moment from "moment";
import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const StaffPurchaseCard = ({ pass, getPassId, checkedPassId,addCard }) => {
  const cardDetail = pass ? pass : [];
  const isChecked = checkedPassId || "";
  const navigate = useNavigate();

  const chunks = [];
  for (let i = 0; i < cardDetail.length; i += 2) {
      chunks.push(cardDetail.slice(i, i + 2));
  }

  const settings = {
    prevIcon: <FaLongArrowAltLeft />,
    nextIcon: <FaLongArrowAltRight />,
    interval: 1000000,
    pauseOnHover: true,
    wrap: true,
    touch: true,
    keyboard: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {chunks.length > 0 && (
        <Carousel {...settings} className="staffSlider">
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <Row className="mb-5">
                {chunk.map((items, cardIndex) => (
                  <Col lg={6} md={6} xs={12} key={cardIndex}>
                    <div class="available-card">
                      <div class="available-blue-card showicons p-3 p-sm-4">
                        <Row>
                            <Col>
                            <img src={items.studio_logo} class="img-fluid available-img" />
                            </Col>
                            <Col>
                            <h5 class="white-color font-14 mb-2 mt-0">
                              {items.pass_name}
                            </h5>
                            <h5 class="white-color font-14 mb-2 mt-0">
                              ${items.amount}{" "}
                              <span class="font-12"> Plus Tax</span>
                            </h5>
                            </Col>
                        </Row>
                        <div class="row mt-5 align-items-end">
                          <div class="col-6 text-left pr-0">
                            <p class="mb-0 white-color paratitle">
                              {" "}
                              Valid For {items.pass_validity}
                            </p>
                          </div>
                        </div>
                        <div class="actionbtns">
                          <button
                            class="viewproduct"
                            onClick={() =>
                              navigate("/pass-detail", {
                                state: { items: items },
                              })
                            }
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          {items.added_incart == 0 &&
                          <button class="addtocart"  onClick={()=>addCard('1',items)} ><i class="fas fa-cart-plus"></i> </button>
                             }
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
};
