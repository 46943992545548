import React, { useEffect, useState } from "react";
import { Header } from "../header/Header";
import { Footer } from "../footer/Footer";
import { Link } from "react-router-dom";
import { InputField } from "../../../sharedComponents/InputField";
import { Form, Spinner } from "react-bootstrap";
import { SharedButton } from "../../../sharedComponents/Button";
import { login } from "../../../Studio-Services/Studio_Services"
import { useNavigate } from 'react-router-dom';
import StaticHeader from "../../../staticpagis/StaticHeader";
import logo from "../../../Images/logo_white_web.png"
import Swal from "sweetalert2";

export const StudioLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem('ownerEmail') || '');
  const [password, setPassword] = useState(localStorage.getItem('ownerPass') || '');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [invalidPassword, setInvalidPassword] = useState('');
  const [checkBox, setCheckBox] = useState(localStorage.getItem('checedValue') || false);
  const [loder, setLoder] = useState(false);
  useEffect(() => {
    const id = localStorage.getItem('id');
    if (id) {
      navigate('/Home');
    }
  }, []);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setInvalidEmail('');
  };


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setInvalidPassword('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = 1;

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!email) {
      setInvalidEmail("Email Id Required"); isValid = 2;
    } else if (email && !email.match(emailPattern)) { setInvalidEmail("Invalid Email"); isValid = 3; }
    if (!password) { setInvalidPassword("PassWord Required"); isValid = 4; }

    if (isValid === 1) {
      localStorage.setItem('id', '');
      localStorage.removeItem('st_userData');
      localStorage.removeItem('Authorization');
      localStorage.removeItem('id');
      localStorage.removeItem('business_id');
      localStorage.removeItem('marchant_id');
      localStorage.removeItem('cl_token_expire');
      localStorage.removeItem('business_token');
      localStorage.removeItem('list_token');
      localStorage.removeItem('month_token');

      setLoder(true);
      if (checkBox) {
        localStorage.setItem('ownerEmail', email);
        localStorage.setItem('ownerPass', password);
        localStorage.setItem('checedValue', checkBox);
      } else {
        localStorage.setItem('ownerEmail', '');
        localStorage.setItem('ownerPass', '');
        localStorage.setItem('checedValue', checkBox);
      }
      const value = { "email": email, "password": password }
      const resp = await login(value);
      if (resp) {
        setLoder(false);
        if (resp.data.data.redirect_to_verify === '1') {
          Swal.fire({
            html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
              Error !!!
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
            ${resp.data.message} </small></p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/studiologin");
            }
          });
        } else if(resp.data.data.redirect_to_verify == 2){
          localStorage.setItem('st_userRegisterData', JSON.stringify(resp.data.data));
          localStorage.setItem('st_userPassword', JSON.stringify(password));
          Swal.fire({
            html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
              Error !!!
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
            ${resp.data.message} </small></p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/choose-plan', { state: { backURL: '/paycard-setup' } });
            }
          });
        } else if(resp.data.data.redirect_to_verify == 3){
          localStorage.setItem('st_userData', JSON.stringify(resp.data.data));
          localStorage.setItem('st_userPassword', JSON.stringify(password));
          Swal.fire({
            html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
             Error !!!
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
            ${resp.data.message} </small></p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/business-profile');
            }
          });
        } else {
          Swal.fire({
            html: `
              <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
                SUCCESS
              </h4>
              <p class="text-center my-4 textcolor">
                <small class="textWhite">
                  ${resp.data.message}
                </small>
              </p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/Home");
            }
          });
        }
      } else setLoder(false);
    }
    setLoder(false);
  };

  const setCheckBoxHandler = (e) => {
    setCheckBox(e.target.checked);
  }

  return (
    <>
      <div>
        {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
        <StaticHeader />
        {/* <!-- header close --> */}
        <div className="middle-content-wrp">
          {/* image layer */}
          <div className="signwrapp-back-layer">
            <img
              src="assets/images/pictures/watermark_bg.png"
              alt="Watermark Background"
            />
          </div>
          {/* img layer */}

          <div className="container py-5 relative setminheight d-flex align-items-center">
            <div className="row justify-content-center flex-fill">
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="middle-innerbox py-4 px-4">
                  <h3 className="title text-center mt-4">Welcome Back!!</h3>
                  <p className="text-center brand-light mb-4 font-16">
                    Please login to check your details...
                  </p>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <InputField
                      text={"Email"}
                      className={`inputField mb-2`}
                      type="email"
                      value={email}
                      placeholder={`Enter Your Email`}
                      onChange={handleEmailChange}
                      error={invalidEmail}
                    />
                    {/* <small className="text-danger">{}</small> */}
                    <InputField
                      text={"Password"}
                      className={`inputField mb-2`}
                      type="password"
                      value={password}
                      placeholder={`Password`}
                      error={invalidPassword}
                      onChange={handlePasswordChange}

                    />
                    {/* <small className="text-danger"> {invalidPassword}</small> */}
                    <Form.Group className="check-Box mb-3">
                      <Form.Check
                        label="Remember me"
                        feedbackType="invalid"
                        type="checkbox"
                        checked={checkBox}
                        onChange={setCheckBoxHandler}
                      />
                      <Link
                        to="/forgot-password"
                        style={{ color: "#66bce4", float: "right" }}
                      >
                        Forgot Password ?
                      </Link>
                    </Form.Group>
                    <SharedButton
                      title={"Continue"}
                      className={"btn w-100"}
                      type={"submit"}
                      variant="primary"
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
