import React from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { InputField } from "../../../../../sharedComponents/InputField";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { CardCarousel } from "../../../../../sharedComponents/CardCarousel";
import { MemberShipCard } from "../../../../../sharedComponents/MemberShipCard";
import { IoIosArrowBack } from "react-icons/io";
import {
  avalible_instructor,
  business_class_time,
  class_list,
  class_scheduling,
  get_passes_according_to_category,
  get_room_location,
  get_weekdays,
} from "../../../../../Studio-Services/Studio_Services";
import { useEffect } from "react";
import { useState } from "react";
import { MyContext } from "../../../../../App";
import { useContext } from "react";
import { MemberShipCardWithSlider } from "../../../../../sharedComponents/MemberShipCardWithSlider";
import { Date } from "./Date";
import moment from "moment";
import logo from "../../../../../Images/logo_white_web.png";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { errorAlert } from "../../../../../Static_Services/Alertmsg";


export const Schedulenewclass = () => {
  const [loder, setLoder] = useState(false);
  const [classOption, setClassOption] = useState([]);
  const [locOption, setLocOption] = useState([]);
  const [classValue, setClassValue] = useState();
  const [classDuration, setclassDuration] = useState(0);

  const [timePass, setTimePass] = useState([]);
  const [punchPass, setPunchPass] = useState([]);
  const [recurringPass, setRecurringPass] = useState([]);

  const [userData, setUserData] = useState([]);

  const [passValueRecurring, setPassValueRecurring] = useState([]);
  const [passValueTimeFram, setPassValueTimeFram] = useState([]);
  const [passValuePunch, setassValuePunch] = useState([]);

  const [startDate, setStartDate] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedDayId, setSelectedDayId] = useState();

  const [setfromTime, setSetfromTime] = useState();
  const [settoTime, setSettoTime] = useState();

  const [intOption, setIntOption] = useState();

  // const [classDefault,setClassDefault] = useState("");

  const [wekList, setWekList] = useState([]);



  const [prehourses, setPrehourses] = useState();
  const [preMinuts, setPreMinuts] = useState();
  const [hours, setHours] = useState();
  const [newclassEndTime, setNewclassEndTime] = useState('');
  const [endhours, setEndhours] = useState();
  const [minuts, setMinuts] = useState();
  const [ampm, setAmpm] = useState();

  const [overflow, setOverflow] = useState();

  const context = useContext(MyContext);

  const recuringHandler = (data) => {
    setPassValueRecurring(data);
  };

  const timeFramHandler = (data) => {
    setPassValueTimeFram(data);
  };

  const punchHandler = (data) => {
    setassValuePunch(data);
  };
  const avalibleInstructor = async () => {
    const fData = {
      service_id: classValue,
      day_id: selectedDayId,
      from_time: setfromTime,
      to_time: settoTime,
      service_type: "1",
    };
    const resp = await avalible_instructor(fData);

    if (resp) {
      const instList = [];
      resp.data.data.map((e) =>
        instList.push({
          value: e.id,
          label: e.name,
        })
      );
      setIntOption(instList);
    }
  };

  useEffect(() => {
    let h = "00";
    let m = "00";
    let sm = '00';
    let s = "00";
    let a = "AM";
    if (hours) { h = hours; }
    if (preMinuts) { sm = preMinuts; }
    if (minuts) { m = minuts; }
    if (ampm) { a = ampm; }

    const ftime = `${h} : ${sm} : ${s} ${a}`;

    const fromTime = moment(startDate + " " + ftime, "YYYY-MM-DD hh:mm:ss A").unix();

    // const pretoTime = moment(startDate + " " + ttime, "YYYY-MM-DD hh:mm A").unix();
    const toTime = moment(startDate + " " + newclassEndTime, "YYYY-MM-DD hh:mm A").unix();

    setSetfromTime(fromTime);
    setSettoTime(toTime);
    avalibleInstructor();
  }, [hours, minuts, ampm]);

  const roomOpt = async () => {
    const fData = {
      business_id: localStorage.getItem("business_id"),
    };
    const resp = await get_room_location(fData);
    if (resp) {
      const loc = [];
      resp.data.data.map((e) => {
        loc.push({ value: e.location_id, label: e.location_name });
      });
      setLocOption(loc);
    }
  };

  const clsList = async () => {
    const cData = {
      pageid: "1",
      business_id: localStorage.getItem("business_id"),
    };
    const resp = await class_list(cData);
    if (resp) {
      const clsOP = [];
      resp.data.data.forEach((element) => {
        if (element.status === "Active") {
          clsOP.push({ value: `${element.class_id},${element.duration}`, label: element.class_name });
        }
      });
      setClassOption(clsOP);
    }
  };

  const getWeekdays = async () => {
    const resp = await get_weekdays();
    if (resp) {
      setWekList(resp.data.data);
    }
  };

  // const getUserData = async () => {
  //     const resp = await getUserProfileInfo();
  //     if (resp) {
  //       const useInfo = resp.data.data ? resp.data.data : '';
  //       setUserinfo(useInfo);
  //     }
  // };
  useEffect(() => {
    if (context.context !== undefined && context.context !== null) {
      const udata = context.context.uData;
      setUserData({
        business_img: udata.business_img,
        logo: udata.logo,
      });
    }
    roomOpt();
    clsList();
    getWeekdays();
  }, []);

  const getPass = async () => {
    setPassValueRecurring([]);
    setPassValueTimeFram([]);
    setassValuePunch([]);
    setTimePass([]);
    setPunchPass([]);
    setRecurringPass([]);
    setLoder(true);
    const fData = {
      service_id: classValue,
      service_type: "1",
    };
    const resp = await get_passes_according_to_category(fData);
    if (resp) {
      setLoder(false);
      const timefram = [];
      const punchfram = [];
      const recuring = [];
      const fData = resp.data.data;
      fData.map((e) =>
        e.pass_type === "Time Frame"
          ? timefram.push({
            pass_id: e.pass_id,
            memberShipImg: userData.business_img ? userData.business_img : logo,
            cardTitle: e.pass_name,
            price: e.amount || 0,
            validText: `Valid For ${e.pass_days} Days`,
            tax: "Plus Tax",
            is_added: e.is_added,
          })
          : e.pass_type === "Recurring Membership"
            ? recuring.push({
              pass_id: e.pass_id,
              memberShipImg: userData.business_img ? userData.business_img : logo,
              cardTitle: e.pass_name,
              price: e.amount || 0,
              validText: `Valid For ${e.pass_days} Days`,
              tax: "Plus Tax",
              is_added: e.is_added,
            })
            : e.pass_type === "Punch Card"
              ? punchfram.push({
                pass_id: e.pass_id,
                memberShipImg: userData.business_img ? userData.business_img : logo,
                cardTitle: e.pass_name ? e.pass_name : "",
                price: e.amount || 0,
                validText: `Valid For ${e.pass_days} Classes`,
                tax: "Plus Tax",
                is_added: e.is_added,
              })
              : ""
      );
      setTimePass(timefram);
      setPunchPass(punchfram);
      setRecurringPass(recuring);
    }
    setLoder(false);
  };

  const [classStartTime, setClassStartTime] = useState();
  const [classEndTime, setClassEndTime] = useState();

  const businessClassTime = async () => {
    const fData = {
      business_id: localStorage.getItem("business_id"),
      class_id: classValue,
      service_type: "1",
    };
    const resp = await business_class_time(fData);
    if (resp) {
      resp.data.data.forEach((e) => {
        if (e.name === selectedDay) {
          e.slot_time.map(
            (d) => (
              setClassStartTime(
                moment.unix(d.time_slote_from).format("hh:mm A")
              ),
              setClassEndTime(moment.unix(d.time_slote_to).format("hh:mm A"))
            )
          );
        }
      });
    }
  };

  useEffect(() => {
    if (classValue) {
      getPass();
      businessClassTime();
      avalibleInstructor();
    }
  }, [classValue]);

  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const optionsStatus = [
    { value: "Active", label: "Active" },
    { value: "Deactive", label: "Deactive" },
  ];

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "2", label: "InActive" },
  ];

  const ContractorOptions = [
    { value: "1", label: "Employee" },
    { value: "2", label: "Contractor" },
    { value: "3", label: "Volunteer" },
  ];

  const HoursOPT = [
    { value: "01", label: <>01</> },
    { value: "02", label: <>02</> },
    { value: "03", label: <>03</> },
    { value: "04", label: <>04</> },
    { value: "05", label: <>05</> },
    { value: "06", label: <>06</> },
    { value: "07", label: <>07</> },
    { value: "08", label: <>08</> },
    { value: "09", label: <>09</> },
    { value: "10", label: <>10</> },
    { value: "11", label: <>11</> },
    { value: "12", label: <>12</> },
  ];

  const MinutsOPT = [
    { value: "00", label: <>00</> },
    { value: "05", label: <>05</> },
    { value: "10", label: <>10</> },
    { value: "15", label: <>15</> },
    { value: "20", label: <>20</> },
    { value: "25", label: <>25</> },
    { value: "30", label: <>30</> },
    { value: "35", label: <>35</> },
    { value: "40", label: <>40</> },
    { value: "45", label: <>45</> },
    { value: "50", label: <>50</> },
    { value: "55", label: <>55</> },
  ]

  const AMPMOP = [
    { value: "AM", label: <>AM</> },
    { value: "PM", label: <>PM</> },
  ];

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setError((pre) => ({ ...pre, class_status: "" }))
    // Do something with the selected value
  };

  const DateChange = (e) => {
    setError((pre) => ({ ...pre, start_date: "" }));


    const formattedString = moment(e.target.value).format('YYYY-MM-DD');

    setStartDate(formattedString);

    const selectedDay = moment(formattedString).format("dddd");

    wekList.forEach((e) => {
      if (e.week_name === selectedDay) {
        setSelectedDay(e.week_name);
        setSelectedDayId(e.id);
      }
    });
  };
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/classes");
    // Go back in the browser's history
    // window.history.back();
  };

  const [error, setError] = useState({
    class_id: "",
    start_date: "",
    class_location: "",
    passes_id: "",
    class_status: "",
    class_repeat_times: "",
    class_days_prior_signup: "",
    class_waitlist_overflow: "",
    class_overflow_count: "",
    start_time: "",
    end_time: "",
    day_id: "",
    instructor_id: "",
    class_capacity: "",
  })

  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());

    // if (passValueRecurring.length <= 0) {
    //   errorAlert('Please Select Passes');
    //   return;
    // } else {

    const pass_ids = [];
    if (passValueRecurring.length > 0) {
      passValueRecurring.map((e) => pass_ids.push(e));
    }
    if (passValueTimeFram.length > 0) {
      passValueTimeFram.map((e) => pass_ids.push(e));
    }
    if (passValuePunch.length > 0) {
      passValuePunch.map((e) => pass_ids.push(e));
    }
    const class_id = formValue.class_id;
    const start_date = moment(startDate, "YYYY-MM-DD").unix();
    const class_location = formValue.class_location;
    const passes_id = pass_ids;
    const class_status = formValue.class_status;
    const class_repeat_times = formValue.class_repeat_times;
    const class_days_prior_signup = formValue.class_days_prior_signup;
    const class_waitlist_overflow = formValue.class_waitlist_overflow;
    const class_overflow_count = formValue.class_overflow_count;
    const start_time = setfromTime;
    const end_time = settoTime;
    const day_id = selectedDayId;
    const instructor_id = formValue.instructor_id;
    const class_capacity = formValue.class_capacity;

    if (!class_id) { setError((pre) => ({ ...pre, class_id: "* Required" })); isValid = 2 }
    if (!start_date) { setError((pre) => ({ ...pre, start_date: "* Required" })); isValid = 3 }
    if (!class_location) { setError((pre) => ({ ...pre, class_location: "* Required" })); isValid = 4 }
    if (!passes_id) { setError((pre) => ({ ...pre, passes_id: "* Required" })); isValid = 5 }
    if (!class_status) { setError((pre) => ({ ...pre, class_status: "* Required" })); isValid = 6 }
    if (!class_repeat_times) { setError((pre) => ({ ...pre, class_repeat_times: "* Required" })); isValid = 7 }
    if (!class_days_prior_signup) { setError((pre) => ({ ...pre, class_days_prior_signup: "* Required" })); isValid = 8 }
    if (!class_waitlist_overflow) { setError((pre) => ({ ...pre, class_waitlist_overflow: "* Required" })); isValid = 9 }
    if (class_waitlist_overflow === "yes") {
      if (!class_overflow_count) { setError((pre) => ({ ...pre, class_overflow_count: "* Required" })); isValid = 10 }
    }

    if (!start_time) { setError((pre) => ({ ...pre, start_time: "* Required" })); isValid = 11 }
    if (!end_time) { setError((pre) => ({ ...pre, end_time: "* Required" })); isValid = 12 }
    if (!class_capacity) { setError((pre) => ({ ...pre, class_capacity: "* Required" })); isValid = 13 }
    if (!instructor_id) { setError((pre) => ({ ...pre, instructor_id: "* Required" })); isValid = 14 }

    if (isValid === 1) {
      setLoder(true);
      const myArrayAsString = JSON.stringify(passes_id);
      const stringWithoutBrackets = myArrayAsString.substring(1, myArrayAsString.length - 1);
      const fData = {
        class_id: formValue.class_id,
        start_date: moment(formValue.start_date, "YYYY/MM/DD").unix(),
        class_location: formValue.class_location,
        passes_id: stringWithoutBrackets,
        class_status: formValue.class_status,
        class_repeat_times: formValue.class_repeat_times,
        class_days_prior_signup: formValue.class_days_prior_signup,
        class_waitlist_overflow: formValue.class_waitlist_overflow,
        class_overflow_count: formValue.class_overflow_count,
        start_time: setfromTime,
        end_time: settoTime,
        day_id: selectedDayId,
        instructor_id: formValue.instructor_id,
        class_capacity: formValue.class_capacity,
      };

      const resp = await class_scheduling(fData);
      if (resp) {
        setLoder(false);
        // Reset the form after processing
        e.target.reset();
        setPassValueRecurring([]);
        setPassValueTimeFram([]);
        setassValuePunch([]);
        setTimePass([]);
        setPunchPass([]);
        setRecurringPass([]);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
              Success
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
        ${resp.data.message} </small></p>
        `,
          showClass: {
            popup: "custom-dialog-container",
          },
          customClass: {
            confirmButton: "custom-button custom-button-size modalbtn",
          },
        });
      } else {
        setLoder(false);
      }
    }
    // }
    setLoder(false);
  };

  const errorHandler = (e) => {
    const { name } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }));
  }



  const timeHandler = (h = '', m = '', a = '') => {

    // var startTime = h + ':' + m + ' ' + a;
    // console.log('----------->', startTime)

    // const classStartTime = startTime;
    // var endDate = moment(startTime, ["h:mm A"]).add(classDuration, 'minutes').format("h:mm A");

    // const classEndTime = endDate;
    // console.log(classStartTime, 'classEndTime', classEndTime);

    let newHours = '';
    let remainingMinutes = '';
    if (h != '' && m == '' && a == "") {
      let mym = '00';
      let mya = "AM";
      var startTime = h + ':' + mym + ' ' + mya;
      var endDate = moment(startTime, ["h:mm A"]).add(classDuration, 'minutes').format("h:mm A");
      setNewclassEndTime(endDate);

      const hours = parseInt(h);
      const originalMinutes = hours * 60;
      const newMinutes = originalMinutes + parseInt(classDuration);
      newHours = Math.floor(newMinutes / 60);
      remainingMinutes = newMinutes % 60;
      setPrehourses(parseInt(h));
    }
    if (prehourses != undefined && m != '' && a == '') {
      let myaa = "AM";
      var startTime = prehourses + ':' + m + ' ' + myaa;
      var endDate = moment(startTime, ["h:mm A"]).add(classDuration, 'minutes').format("h:mm A");
      setNewclassEndTime(endDate);

      setPreMinuts(parseInt(m));
      const hours = parseInt(prehourses);
      const originalMinutes = hours * 60;
      const newMinutes = originalMinutes + parseInt(classDuration) + parseInt(m);
      newHours = Math.floor(newMinutes / 60);
      remainingMinutes = newMinutes % 60;
    }
    if (prehourses != undefined && preMinuts != undefined && a != '') {
      var startTime = prehourses + ':' + preMinuts + ' ' + a;
      var endDate = moment(startTime, ["h:mm A"]).add(classDuration, 'minutes').format("h:mm A");
      setNewclassEndTime(endDate);
    }
    // if (!prehourses && m) { errorAlert('Plese Selet Hours First') }
    if (remainingMinutes > 0) { setMinuts(remainingMinutes); }
    if (newHours) { setEndhours(parseInt(newHours)); }
  }

  return (
    <>   {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'></Spinner> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="innerContent">
                  <Container>
                    <div className="NewClass ">
                      <Form onSubmit={submitHandler}>

                        <h5 style={{ display: "inline-block" }} >
                          <IoIosArrowBack
                          style={{cursor: "pointer"}}
                          onClick={handleGoBack}
                            className="m-3"
                          />
                          SCHEDULE CLASS
                        </h5>
                        <Row className="mt-3">
                          <Col>
                            <InputField
                              type={"date"}
                              name="start_date"
                              onChange={(e) => DateChange(e)}
                              lable={"Start"}
                            />
                            {/* <Date
                              selected={startDate}
                              name="start_date"
                              onChange={(e) => DateChange(e)}
                              error={error.start_date}
                              DatepickerLabel={"Start"}
                            /> */}
                          </Col>
                          <Col>
                            <InputField
                              type={"lable"}
                              lable={"Day"}
                              value={selectedDay}
                              readOnly
                              placeholder={"day.."}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <InputField
                              type={"number"}
                              name="class_repeat_times"
                              lable={"Number of Weeks"}
                              minLength={"0"}
                              error={error.class_repeat_times}
                              onChange={errorHandler}
                              placeholder={""}
                            />
                          </Col>
                          <Col>
                            <SharedSelect
                              options={classOption}
                              name="class_id"
                              defaultValue="Select Class"
                              onChange={(e) => {
                                const { value } = e.target;
                                const splitValue = value.split(',');
                                const clsID = splitValue[0];
                                const clsduration = splitValue[1];
                                setclassDuration(clsduration);
                                setClassValue(clsID);
                                setEndhours();
                                setMinuts();
                                setAmpm();
                                setSetfromTime();
                                setSettoTime();
                                setError((pre) => ({ ...pre, class_id: "" }))
                              }
                              }
                              error={error.class_id}
                              lable={"Select Class"}
                            />
                          </Col>
                        </Row>
                        {classValue ? (
                          <>
                            {/* <Row>
                              <h6>Business Time slot not assigned this day</h6>
                            </Row>
                            <Row>
                              <samll>{`${classStartTime} : ${classEndTime}`}</samll>
                            </Row> */}
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col>
                                    <SharedSelect
                                      options={HoursOPT}
                                      onChange={(e) => {
                                        setHours(e.target.value);
                                        timeHandler(e.target.value);
                                      }
                                      }
                                      lable={"Class Start Time"}
                                    />
                                  </Col>
                                  <Col className="mt-2">
                                    <SharedSelect
                                      options={MinutsOPT}
                                      onChange={(e) => { timeHandler('', e.target.value); }}
                                      lable={" "}
                                    />
                                  </Col>
                                  <Col className="mt-2">
                                    <SharedSelect
                                      options={AMPMOP}
                                      onChange={(e) => { setAmpm(e.target.value); timeHandler('', '', e.target.value); }}
                                      lable={" "}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <InputField
                                  type={"text"}
                                  readOnly
                                  lable={"Class End Time"}
                                  // value={`${endhours ? endhours : ""} : ${minuts ? minuts : ""
                                  //   } ${ampm ? ampm : ""}`}
                                  value={newclassEndTime}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                        <Row className="mt-3">
                          <Col>
                            <SharedSelect
                              options={intOption}
                              name="instructor_id"
                              lable={"Select Instructor"}
                              error={error.instructor_id}
                              onChange={errorHandler}
                            />
                          </Col>
                          <Col>
                            <SharedSelect
                              options={locOption}
                              name="class_location"
                              lable={"Select Room Location"}
                              error={error.class_location}
                              onChange={errorHandler}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <InputField
                              type={"lable"}
                              name="class_days_prior_signup"
                              lable={"Days Prior Clients can sign up"}
                              error={error.class_days_prior_signup}
                              onChange={errorHandler}
                              placeholder={""}
                            />
                          </Col>
                          <Col>
                            <SharedSelect
                              options={options}
                              name="class_waitlist_overflow"
                              onChange={(e) => {
                                const { value } = e.target;
                                setOverflow(value);
                                setError((pre) => ({ ...pre, class_waitlist_overflow: "" }))
                              }}
                              error={error.class_waitlist_overflow}
                              lable={"Waitlist overflow"}
                            />
                          </Col>
                          {overflow === "yes" ? (
                            <Col>
                              <InputField
                                name="class_overflow_count"
                                lable={"Overflow Count"}
                                type={"number"}
                                minLength={"1"}
                                error={error.class_overflow_count}
                                onChange={errorHandler}
                              />
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <SharedSelect
                              options={optionsStatus}
                              defaultValue="Status"
                              name="class_status"
                              onChange={handleChange}
                              error={error.class_status}
                              lable={"Status"}
                            />
                          </Col>
                          <Col>
                            <InputField
                              type={"number"}
                              name="class_capacity"
                              lable={"Capacity"}
                              placeholder={"Capacity"}
                              minLength={"0"}
                              error={error.class_capacity}
                              onChange={errorHandler}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="MemberShipCard">
                              <div className="CardCarousel mt-5">
                                <h5 className="grropCard">
                                  Recurring Membership
                                </h5>
                                <Row>
                                  <MemberShipCard
                                    pass={recurringPass}
                                    setValue={recuringHandler}
                                  />
                                </Row>
                              </div>
                              <div className="TimeCard mt-5">
                                <h5 className="grropCard">Time Frame</h5>
                                <Row>
                                  <MemberShipCard
                                    pass={timePass}
                                    setValue={timeFramHandler}
                                  />
                                </Row>
                              </div>
                              <div className="PunchCard mt-5">
                                <h5 className="grropCard">Punch Card</h5>
                                <Row>
                                  <MemberShipCard
                                    pass={punchPass}
                                    setValue={punchHandler}
                                  />
                                </Row>
                              </div>
                              {/* <CardCarousel/> */}
                              <div className="classesTimingCard">
                                {/* <ClassesCard/> */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <SharedButton
                              variant={"primary"}
                              type={"submit"}
                              className={"UpdatedBtn w-100"}
                              title={"Submit"}
                            />
                          </Col>
                          <Col>
                            <SharedButton
                              variant={"outline"}
                              type={"button"}
                              href={"/add-things"}
                              className={"CancelBtn w-100"}
                              title={"Cancel"}
                            />
                          </Col>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                      </Form>
                    </div>
                  </Container>
                  <Footer />
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
