import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { InputField } from "../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../sharedComponents/Button";
import { MyDatePicker } from "../../../../sharedComponents/MyDatePicker";
import { useLocation } from "react-router-dom";
import { MyContext } from "../../../../App";
import { SharedMultiSelect } from "../../../../sharedComponents/SharedMultiSelect";
import { SharedAddress } from "../../../../sharedComponents/SharedAddress";
import { get_skills, profile_update } from "../../../../Studio-Services/Studio_Services";
import moment from "moment";
import PlaceAutoCompleted from "../../../../client-staff/Components/CommanPages/PlaceAutoCompleted";
import Swal from "sweetalert2";
import logo from '../../../../Images/logo_white_web.png'

export const Editprofileform = () => {

  const [pdata, setPdata] = useState();
  const [skillsdata, setSkillsdata] = useState();
  const [preskillsdata, setPreskillsdata] = useState([]);
  const [cordinates, setCordinates] = useState();
  const [userData, setUserData] = useState();
  const [loder,setLoder] = useState(false);


  const [selectedFile, setSelectedFile] = useState(null);
  const [imgURL, setImgURL] = useState(null);

  
  const uploadImage = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setImgURL(event.target.result);
      };
    }
  };

  const [error, setError] = useState({
    name: '', lastname: '', email: '', country: '', mobile: '', experience: '', appointment_fees: '', appointment_fees_type: '', start_date: '', date_of_birth: '', address: '', street: '', city: '', state: '', zipcode: '', gander: '', status: '', employee_id: '', employee_contractor: '', registration_number: '', about: '', profile_img: '', skill: '', gender: ''
  });


  useEffect(() => {
    if (JSON.parse(localStorage.getItem('st_userData'))) {
      setUserData(JSON.parse(localStorage.getItem('st_userData')));
      const userDetail = JSON.parse(localStorage.getItem('st_userData'));
     
      setPdata({
        name: userDetail.name, lastname: userDetail.lastname, email: userDetail.email, country: userDetail.country, mobile: userDetail.mobile, experience: userDetail.experience, appointment_fees: userDetail.appointment_fees, appointment_fees_type: userDetail.appointment_fees_type, start_date: userDetail.start_date, date_of_birth: userDetail.date_of_birth, address: userDetail.address, street: userDetail.street, city: userDetail.city, state: userDetail.state, zipcode: userDetail.zipcode, gender: userDetail.gender, status: userDetail.status, employee_id: userDetail.employee_id, employee_contractor: userDetail.employee_contractor, registration_number: userDetail.registration_number, about: userDetail.about, profile_img: userDetail.profile_img, skill: userDetail.skill, lang: userDetail.lang, lat: userDetail.lat
      });
    }
  }, []);


  useEffect(() => {
    let newArray = pdata;
    if (cordinates) {
      if (cordinates.getAddress) { newArray.address = cordinates.getAddress; }
      if (cordinates.getStreet) { newArray.street = cordinates.getStreet; }
      if (cordinates.getCity) { newArray.city = cordinates.getCity; }
      if (cordinates.getState) { newArray.state = cordinates.getState; }
      if (cordinates.getZIP_Code) { newArray.zipcode = cordinates.getZIP_Code; }
      if (cordinates.getCountry) { newArray.country = cordinates.getCountry; }
      if (cordinates.getLng) { newArray.lang = cordinates.getLng; }
      if (cordinates.getLat) { newArray.lat = cordinates.getLat; }
      setPdata(newArray);
    }
  }, [cordinates])


  const getskillsData = async () => {
    const preData = [];
    const resp = await get_skills();
    if (resp) {
      if (resp.data.data) {
        const data = resp.data.data;
        data.map((e) => (
          preData.push({
            id: e.id, label: e.name
          })
        ))
        setPreskillsdata(preData);
      }
    }
  }

  useEffect(() => {
    getskillsData();
  }, [])


  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];

  const ContractorOptions = [
    { value: "Employee", label: "Employee" },
    { value: "Contractor", label: "Contractor" },
    { value: "Volunteer", label: "Volunteer" },
  ];

  const handleChange = (event) => {
    // const selectedValue = event.target.value;
    // Do something with the selected value
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid =1;
    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());
    const fskils = [];
    skillsdata.map((e) => { fskils.push(e.id) })

if(isValid ===1){
  setLoder(true);
    var formData = new FormData();
    formData.append('name', fValue.name);
    formData.append('lastname', fValue.lastname);
    formData.append('email', fValue.email);
    formData.append('role', '4');
    formData.append('skills', fskils);
    formData.append('experience', fValue.experience);
    formData.append('appointment_fees', fValue.appointment_fees);
    formData.append('appointment_fees_type', fValue.appointment_fees_type);
    formData.append('start_date', moment(fValue.start_date).unix());
    formData.append('status', fValue.status);
    formData.append('sin_no', "");
    formData.append('employee_id', fValue.employee_id);
    formData.append('mobile', fValue.mobile);
    formData.append('employee_contractor', fValue.employee_contractor);
    formData.append('address', fValue.address);
    formData.append('country', fValue.country);
    formData.append('state', fValue.state);
    formData.append('city', fValue.city);
    formData.append('street', fValue.street);
    formData.append('zipcode', fValue.zipcode);
    formData.append('lattitude', "0");
    formData.append('longitude', "0");
    formData.append('gender', fValue.gender);
    formData.append('about', fValue.about);
    formData.append('dob', moment(fValue.dob).unix());
    formData.append('registration_number', fValue.registration_number);

    if (selectedFile) {
      formData.append('image', selectedFile);
    } 
    // else {
    //   formData.append('image', pdata.profile_img);
    // }
     const resp = await profile_update(formData);
     if(resp){
      localStorage.setItem('st_userData', JSON.stringify(resp.data.data));
      setLoder(false);
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          SUCCESS
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
        ${resp.data.message} </small></p>
          `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('st_userData', JSON.stringify(resp.data.data));
        }
      });
     }
    }



  };
  return (
    <>
       {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Row>
          <div className="form-group">
            <div className="custom-uploadfile mb-3">
              {!imgURL &&
                <button className="fileuploadlabel" type="button">
                  <img src={pdata && pdata.profile_img ? pdata.profile_img : "assets/images/icons/ic_uploadimg.png"} className="img-fluid" />
                  {/* <span className="d-block font-14">Upload profile images</span> */}
                </button>
              }
              {imgURL &&
                <button className="fileuploadlabel" type="button">
                  <img src={imgURL} className="img-fluid" style={{ height: "100px" }} />
                  <span className="d-block font-14">Change image </span>
                </button>
              }
              <input type="file" className="fileuploadinput" accept="image/x-png,image/gif,image/jpeg"
                formControlName="profileimg" onChange={(e) => uploadImage(e)} />
            </div>
          </div>
        </Row>
        {/* <Row>
          <Col>
            <div className="ProfilePhoto">
              <InputField
                accept="image/x-png,image/gif,image/jpeg,image/png"
                type="file"
                name={"image"}
                // error={error.profile_img}
                onChange={handleImageUpload}
                className={"FileTypeHide"}
              />
              {base64Image ?
                <img
                  src={base64Image}
                  alt=""
                  className="img-fluid"
                />
                :
                <img
                  src={pdata && pdata.profile_img
                    ? pdata.profile_img
                    : "https://staging.warriorsms.com/superadmin/uploads/user/1664736383Untitleddesign6.jpg"
                  }
                  alt=""
                  className="img-fluid"
                />
              }
              <p>Change Image</p>
            </div>
          </Col>
        </Row> */}
        <Row className="mt-3">
          <Col>
            <InputField
              type="text"
              lable="First Name"
              name={"name"}
              error={error.name}
              value={pdata && pdata.name ? pdata.name : ""}
              placeholder={"Enter Name"}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <InputField
              type="text"
              lable="Last Name"
              name={"lastname"}
              error={error.lastname}
              value={pdata && pdata.lastname ? pdata.lastname : ""}
              placeholder={"Last Name"}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <InputField
              type="number"
              lable="Mobile"
              name={"mobile"}
              error={error.mobile}
              value={pdata && pdata.mobile ? pdata.mobile : ""}
              placeholder={"Enter Number"}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="email"
              lable="Primary Email Id"
              name={"email"}
              error={error.email}
              readOnly={true}
              value={pdata && pdata.email ? pdata.email : ""}
              placeholder={"example@gmail.com"}
            />
          </Col>
          <Col>
            <SharedMultiSelect
              labelText="Skills/Specialisation"
              setSkillsdata={setSkillsdata}
              name="skills"
              options={preskillsdata}
              value={pdata && pdata.skill ? pdata.skill : ""}
            />
          </Col>
          <Col>
            <InputField
              type="text"
              lable="Year of Experience"
              name={"experience"}
              error={error.experience}
              value={pdata && pdata.experience ? pdata.experience : ""}
              placeholder={"experience"}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="text"
              lable="Hourly Wage Rate"
              name={"appointment_fees"}
              error={error.appointment_fees}
              value={pdata && pdata.appointment_fees ? pdata.appointment_fees : ""}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <InputField
              type="text"
              lable="Flat Wage Rate"
              name={"appointment_fees_type"}
              error={error.appointment_fees_type}
              value={pdata && pdata.appointment_fees_type ? pdata.appointment_fees_type : ""}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <InputField
              type="date"
              lable="Start Date"
              name={"start_date"}
              error={error.start_date}
              value={pdata && pdata.start_date ? pdata.start_date : ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="date"
              lable="Date Of Birth"
              name={"date_of_birth"}
              error={error.date_of_birth}
              max={moment().format('YYYY-MM-DD')}
              value={pdata && pdata.date_of_birth ? pdata.date_of_birth : ""}
            />
          </Col>
          <Col>
            <PlaceAutoCompleted
              label={'Head Quarters Address'}
              value={pdata && pdata.address ? pdata.address : ''}
              setCordinates={setCordinates}
            />
          </Col>
          <Col>
            <InputField
              type="text"
              lable="Street"
              name={"street"}
              error={error.street}
              value={pdata && pdata.street ? pdata.street : ""}
              placeholder={"Enter Street"}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="text"
              name={"city"}
              error={error.city}
              value={pdata && pdata.city ? pdata.city : ""}
              lable="City"
              placeholder="City"
            />
          </Col>
          <Col>
            <InputField
              type={"text"}
              lable="Province/State"
              name={"state"}
              error={error.state}
              value={pdata && pdata.state ? pdata.state : ""}
              placeholder="Province/State"
            />
          </Col>
          <Col>
            <InputField
              type={"text"}
              lable="Postal Zip Code"
              name={"zipcode"}
              error={error.zipcode}
              value={pdata && pdata.zipcode ? pdata.zipcode : ""}
              placeholder={"Zip Code"}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>

            <SharedSelect
              options={options}
              lable="Select Gender"
              name={"gender"}
              error={error.gender}
              value={pdata && pdata.gender ? pdata.gender : ""}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <SharedSelect
              options={statusOptions}
              name={"status"}
              value={pdata && pdata.status ? pdata.status : ""}
              onChange={handleChange}
              lable="Status"
            />
          </Col>
          <Col>
            <InputField
              type="text"
              lable="Employee Id"
              name={"employee_id"}
              value={pdata && pdata.employee_id ? pdata.employee_id : ""}
              placeholder="ID"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <SharedSelect
              options={ContractorOptions}
              lable="Employee/Contractor"
              name={"employee_contractor"}
              onChange={handleChange}
              value={pdata && pdata.employee_contractor
                ? pdata.employee_contractor
                : ""
              }
            />
          </Col>
          <Col>
            <InputField
              type={"text"}
              lable="Registration Number"
              name={'registration_number'}
              value={pdata && pdata.registration_number ? pdata.registration_number : ""}
            />
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type={"text"}
              name={"about"}
              value={pdata && pdata.about ? pdata.about : ""}
              lable="About"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <SharedButton
              variant={"primary"}
              type={"submit"}
              className={"UpdatedBtn w-100"}
              title={"Update"}
            />
          </Col>
          <Col>
            <SharedButton
              variant={"secondary"}
              type={"button"}
              onClick={()=>window.history.back()}
              className={"CancelBtn w-100"}
              title={"Cancel"}
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  )
}
