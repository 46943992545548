import moment from "moment";
import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const CustomerProduct = ({ pass, getPassId, checkedPassId }) => {
  const navigate = useNavigate();
  const cardDetail = pass ? pass : [];
  const isChecked = checkedPassId || "";
  const chunks = [];
  for (let i = 0; i < cardDetail.length; i += 2) {
    chunks.push(cardDetail.slice(i, i + 2));
  }

  const settings = {
    prevIcon: <FaLongArrowAltLeft />,
    nextIcon: <FaLongArrowAltRight />,
    interval: 3000,
    pauseOnHover: true,
    wrap: true,
    touch: true,
    keyboard: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {chunks.length > 0 && (
        <Carousel {...settings} className="staffSliders">
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <Row  className="mb-5 staffSlider">
                {chunk.map((items, cardIndex) => (

                  <Col lg={4} md={6} xs={12} key={cardIndex}>
                    <div class="border-radius6 box-shadow1 white-bg px-3 pt-3 pb-2 detail-products showicons">
                      <img src={items.pro_image} class="productHeight w-100" />
                      <div class="row pt-3 pb-1">
                        <div class="col-7 text-left pt-3">
                          <h5 class="black-color heading-titlenew productname">
                            {items.product_name}{" "}
                          </h5>
                        </div>
                        <div class="col-5 text-right">
                          <button type="button" class="btn btn-green-grad">
                            {" "}
                            {items.currency}
                            {items.price}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
};
