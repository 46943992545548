import React, { useState } from 'react'
import { Col, Container, Modal, Navbar, Row, Spinner } from 'react-bootstrap'
import Sidebar from '../comman_page/Sidebar'
import { HeaderDashboard } from '../comman_page/HeaderDashboard'
import { Footer } from '../../../../public/containers/footer/Footer'
import { WebForm } from './WebForm'
import { useNavigate } from 'react-router-dom'
import AddClassImage from './AddClassImage'



export default function CreateWeb() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(true);
    const [clsImage, setClsImage] = useState(false);
    const [loder, setLoder] = useState(false);


    return (
        <>
            {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
            <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col>
                            <div className="RightSide">
                                <HeaderDashboard />
                                <section className="addClient innerContent">
                                    <div className="AddClientContent">
                                        <Container>
                                            <div className='col-md-12' style={{ textAlign: 'center' }}><h3>Create Your Business Website</h3></div>
                                            <div className='row'>

                                                <div className='col-md-6'></div>
                                                <div className='col-md-6 mt-4' style={{ textAlign: 'right' }}>
                                                    {/* <button type='button' className="btn btn-info mr-3" style={{ color: "#FFFF" }} onClick={() => setClsImage(!clsImage)}>Add Class Images</button> */}

                                                    <button type='button' className="btn btn-info" style={{ color: "#FFFF" }} onClick={() => window.open('https://themecrazy.net/html/fitness-center/gym-preview/index.html', '_blank')}>View Demo Template</button>
                                                </div>
                                            </div>
                                            <div className='row'>   <hr /></div>


                                            {clsImage ?
                                                <Row className='mt-5'>
                                                    <AddClassImage setLoder={setLoder} />
                                                </Row>
                                                :
                                                <Row className='mt-5'>
                                                    <WebForm setLoder={setLoder} />
                                                </Row>
                                            }
                                        </Container>
                                    </div>
                                </section>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal show={showModal}>
                <div className="" role="document">
                    <div className="modal-content">
                        <div className="modal-body white-bg box-shadow1 border-radius6 ">

                            <div className="modal-bottom-content blue-gradient border-radius6 py-3 py-sm-5 px-4">
                                <div className='row myclosecss'>
                                    <button type="button" class="btn-close" onClick={() => setShowModal(false)}></button>
                                </div>


                                <img src="clientassets/img/logo_white_web.png" className="img-fluid d-block mx-auto" />
                                <div className='mx-3 my-5'>
                                    <small className="text-white font-16" style={{display:'block'}}>Cost </small>
                                    <small className="text-white font-16" style={{display:'block'}}>  $350 Set Up Fee (plus applicable taxes)</small>
                                    <small className="text-white font-16" style={{display:'block'}}>  $25 / Month hosting fee (plus applicable taxes) </small>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mb-3'>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <button className="btn btn-white2 d-block mx-auto w-100" type="button"
                                                onClick={() => window.open('https://youtu.be/T_qAJEOc4eo', '_blank')}
                                            >
                                                Demo Video
                                                <i className='fab fa-youtube' style={{ fontSize: "22px", color: "red", paddingLeft: "10px" }}></i>
                                            </button>
                                        </div>

                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <button className="btn btn-white2 mx-auto w-100" type="button" onClick={() => window.open('https://themecrazy.net/html/fitness-center/gym-preview/index.html', '_blank')}>Demo Template
                                            <i className='fas fa-globe' style={{ fontSize: "22px", color: "#1e9ad6", paddingLeft: "10px" }}></i>
                                        </button>
                                    </div>

                                </div>
                                <button className="btn btn-white2 mx-auto w-100 mt-3" data-dismiss="modal" type="button" onClick={() => setShowModal(false)}>Continue
                                </button>
                            </div>
                            {/* <!-- modal-bottom-content --> */}

                        </div>
                        {/* <!-- end of modal-body --> */}

                    </div>
                </div>
            </Modal >


        </>
    )
}
