import React, { useEffect, useState } from 'react'
import { add_class_image, add_testimonial_image, class_list } from '../../../../Studio-Services/Studio_Services';
import logo from '../../../../Images/logo_white_web.png'
import { errorAlert } from '../../../../Static_Services/Alertmsg';
import Swal from 'sweetalert2';


export default function AddTestimonialsImage({ setLoder,data,dataHandler }) {
    const business_id = localStorage.getItem("business_id");
    const [classlist, setClasslist] = useState([]);
    
    useEffect(() => {
        setClasslist(data);
    }, [data]);

    const updateHandler = async (e, id) => {
        e.preventDefault();
        let formData = new FormData();
        const image = e.target.image.files[0];
        if (!image) { errorAlert("Image Required") };
        if (id && image) {
            formData.append("image", image);
            formData.append("id", id);
            const resp = await add_testimonial_image(formData);
            if (resp) {
                setLoder(false);
                dataHandler(business_id);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
                SUCCESS
                </h4>
                <p class="text-center my-4 textcolor">
                <small class="textWhite">
                ${resp.data.message} </small></p> `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
                e.target.reset();
                setLoder(false);
            } else
                setLoder(false)

        }
        // Your update logic here
   
    };


    return (
        <div className='p-4' style={{ border: "2px solid" }}>
            <table class="table table-striped table-hover" >
                <thead>
                    <tr>
                        <th scope="col">Sr No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Provider Name</th>
                        <th scope="col">
                        Add Pictures For Circle Image
                            {/* Add New */}
                            </th>
                        <th scope="col" style={{textAlign:'center'}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {classlist && classlist.length > 0 && classlist.map((e, index) => (
                        <tr key={e.id}>
                            <th scope="row">{index + 1}</th>
                            <td><img className={e && e.image ? "img-fluid table_user" : "img-fluid table_user bggreenmy"} src={e && e.image ? e.image : logo} style={{width: "100px", height: "50px",borderRadius: '3px'}} /></td>
                            <td style={{width:'330px'}}>{e.provider}</td>
                            <td colspan="2">
                                <form onSubmit={(event) => updateHandler(event, e.id)}>
                                    <input type='file' name='image' />
                                    <button className='btn btn-primary' type='submit' style={{ fontSize: '1rem', padding: "0.3rem 1rem" }}>{!e.image ? "Add" : "Update"}</button>
                        
                                </form>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )
}
