import { useState } from 'react';
import { create_website } from '../../../../Studio-Services/Studio_Services';
import Swal from 'sweetalert2';
import logo from "../../../../Images/logo_white_web.png";
import Tooltips from '../../../../client-staff/Components/CommanPages/Tooltips';
import { useNavigate } from 'react-router-dom';
import ColorModal from './ColorModal';
import { SketchPicker } from 'react-color';


export const WebForm = ({ setLoder }) => {
    const [fdata, setFdata] = useState({
        "domain": "", "brimage1": "", "brtitle1": "", "brsubtitle1": "",
        "brimage2": "", "brtitle2": "", "brsubtitle2": "", "abimage": "", "abheading": "", "abstory": "","banner_font_color1":"","banner_font_color2":"","banner_font_color_tagline1":"","banner_font_color_tagline2":"",
        "showinstructor": false, "showclass": false, "showpass": false, "showservices": false, "showworkshop": false, "showproduct": false, "facebook_id": '', "insta_id": "", "fontfamily": "", "inst_header": "", "inst_description": "", "cls_discription": "", "pas_discription": "", "serv_discription": "", "wrk_discription": "", "prd_discription": "", "provider1": "", "discription1": "", "provider2": "", "discription2": "", "provider3": "", "discription3": "", "favicon": "", "header_fonts": "", "background_for_pop_windows": "", "borders_and_buttons": "", "button_text_color": ""
    })



    const [error, setError] = useState({
        "domain": "", "brimage1": "", "brtitle1": "", "brsubtitle1": "",
        "brimage2": "", "brtitle2": "", "brsubtitle2": "", "abimage": "", "abheading": "", "abstory": "", "facebook_id": "", "insta_id": "", "fontfamily": ""
    });

    const navigate = useNavigate();

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setFdata((pre) => ({ ...pre, [name]: value }))
        setError((pre) => ({ ...pre, [name]: "" }));
    }

    const checkHandler = (e) => {
        const { name, checked } = e.target;
        setFdata((pre) => ({ ...pre, [name]: checked }));
    }



    const submitHandler = async (e) => {
        e.preventDefault();


        let isValid = 1;
        if (!fdata.domain) { setError((pre) => ({ ...pre, "domain": "Required *" })); isValid = 2; }
        if (!fdata.brimage1) { setError((pre) => ({ ...pre, "brimage1": "Required *" })); isValid = 3; }
        if (!fdata.brtitle1) { setError((pre) => ({ ...pre, "brtitle1": "Required *" })); isValid = 4; }
        if (!fdata.brsubtitle1) { setError((pre) => ({ ...pre, "brsubtitle1": "Required *" })); isValid = 5; }
        if (!fdata.brimage2) { setError((pre) => ({ ...pre, "brimage2": "Required *" })); isValid = 6; }
        if (!fdata.brtitle2) { setError((pre) => ({ ...pre, "brtitle2": "Required *" })); isValid = 7; }
        if (!fdata.brsubtitle2) { setError((pre) => ({ ...pre, "brsubtitle2": "Required *" })); isValid = 8; }
        if (!fdata.abimage) { setError((pre) => ({ ...pre, "abimage": "Required *" })); isValid = 9; }
        if (!fdata.abheading) { setError((pre) => ({ ...pre, "abheading": "Required *" })); isValid = 10; }
        if (!fdata.abstory) { setError((pre) => ({ ...pre, "abstory": "Required *" })); isValid = 11; }


        if (isValid === 1) {
            setLoder(true);
            let formData = new FormData();
            let myshowinstructor = "no";
            let myshowclass = "no";
            let myshowpass = "no";
            let myshowservices = "no";
            let myshowworkshop = "no";
            let myshowproduct = "no";

            if (fdata.showinstructor) { myshowinstructor = "yes" }
            if (fdata.showclass) { myshowclass = "yes" }
            if (fdata.showpass) { myshowpass = "yes" }
            if (fdata.showservices) { myshowservices = "yes" }
            if (fdata.showworkshop) { myshowworkshop = "yes" }
            if (fdata.showproduct) { myshowproduct = "yes" }


            const bri1 = e.target.brimage1.files[0];
            const bri2 = e.target.brimage2.files[0];
            const abi = e.target.abimage.files[0];
            const favicon = e.target.favicon.files[0];



            let testi = [];
            if (fdata.provider1) { testi.push({ "provider": fdata.provider1, "description": fdata.discription1 }) }
            if (fdata.provider2) { testi.push({ "provider": fdata.provider2, "description": fdata.discription2 }) }
            if (fdata.provider3) { testi.push({ "provider": fdata.provider3, "description": fdata.discription3 }) }



            formData.append('domain', fdata.domain);
            formData.append('brtitle1', fdata.brtitle1);
            formData.append('brsubtitle1', fdata.brsubtitle1);
            formData.append('brtitle2', fdata.brtitle2);
            formData.append('brsubtitle2', fdata.brsubtitle2);
            formData.append('font_family', fdata.fontfamily);

            formData.append("abheading", fdata.abheading);
            formData.append("abstory", fdata.abstory);
            formData.append("showinstructor", myshowinstructor);
            formData.append("showclass", myshowclass);
            formData.append("showpass", myshowpass);
            formData.append("showservices", myshowservices);
            formData.append("showworkshop", myshowworkshop);
            formData.append("showproduct", myshowproduct);

            formData.append("inst_header", fdata.inst_header);
            formData.append("inst_description", fdata.inst_description);
            formData.append("cls_discription", fdata.cls_discription);
            formData.append("pas_discription", fdata.pas_discription);
            formData.append("serv_discription", fdata.serv_discription);
            formData.append("wrk_discription", fdata.wrk_discription);
            formData.append("prd_discription", fdata.prd_discription);
            formData.append('testimonial', JSON.stringify(testi));

            formData.append("facebook_id", fdata.facebook_id);
            formData.append("insta_id", fdata.insta_id);

            formData.append('brimage1', bri1);
            formData.append('brimage2', bri2);
            formData.append('abimage', abi);


            let sanitizedHeaderFonts = fdata.header_fonts.replace(/^#/, '');
            let sanitizedBackground_for_pop_windows = fdata.background_for_pop_windows.replace(/^#/, '');
            let sanitizedBorders_and_buttons = fdata.borders_and_buttons.replace(/^#/, '');
        

            if (sanitizedHeaderFonts) { sanitizedHeaderFonts = `#${sanitizedHeaderFonts}`; }
            if (sanitizedBackground_for_pop_windows) { sanitizedBackground_for_pop_windows = `#${sanitizedBackground_for_pop_windows}`; }
            if (sanitizedBorders_and_buttons) { sanitizedBorders_and_buttons = `#${sanitizedBorders_and_buttons}`; }

            formData.append('favicon', favicon);
            formData.append("header_fonts", sanitizedHeaderFonts);
            formData.append("background_for_pop_windows", sanitizedBackground_for_pop_windows);
            formData.append("borders_and_buttons", sanitizedBorders_and_buttons);
            formData.append('button_text_color', fdata.button_text_color);

            let sanitizedBorders_banner_font_color1 = "";
            let sanitizedBorders_banner_font_color2 = "";

            if (fdata.banner_font_color1) {
                sanitizedBorders_banner_font_color1 = fdata.banner_font_color1.replace(/^#/, '');
                if (sanitizedBorders_banner_font_color1) { sanitizedBorders_banner_font_color1 = `#${sanitizedBorders_banner_font_color1}`; }

            }
            if (fdata.banner_font_color2) {
                sanitizedBorders_banner_font_color2 = fdata.banner_font_color2.replace(/^#/, '');
                if (sanitizedBorders_banner_font_color2) { sanitizedBorders_banner_font_color2 = `#${sanitizedBorders_banner_font_color2}`; }
            }
            formData.append("banner_font_color1", sanitizedBorders_banner_font_color1);
            formData.append("banner_font_color2", sanitizedBorders_banner_font_color2);

            let st_banner_font_color_tagline1 ="";
            let st_banner_font_color_tagline2 ="";

            if (fdata.banner_font_color_tagline1) {
                st_banner_font_color_tagline1 = fdata.banner_font_color_tagline1.replace(/^#/, '');
                if (st_banner_font_color_tagline1) { st_banner_font_color_tagline1 = `#${st_banner_font_color_tagline1}`; }

            }
            if (fdata.banner_font_color_tagline2) {
                st_banner_font_color_tagline2 = fdata.banner_font_color_tagline2.replace(/^#/, '');
                if (st_banner_font_color_tagline2) { st_banner_font_color_tagline2 = `#${st_banner_font_color_tagline2}`; }
            }
            formData.append("banner1_tagline1", st_banner_font_color_tagline1);
            formData.append("banner2_tagline2", st_banner_font_color_tagline2);

            const resp = await create_website(formData);
            if (resp) {
                setLoder(false);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                    <h4 class="title text-uppercase text-center mt-4 textWhite">
                    SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    ${resp.data.message} </small></p> `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                }).then((result) => {
                    navigate('/businessweb');
                });
                e.target.reset();
                setLoder(false);
            } else
                setLoder(false);
        }
        setLoder(false);
    }

    return (
        <>
            <div className='row'>
                <form onSubmit={submitHandler} autoComplete='off'>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="domain" className="form-label">
                                <strong style={{ color: 'black' }}>  Enter Your Domain Name </strong>
                                <Tooltips info={'www.example.com'} />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='domain'
                                onChange={changeHandler}
                                id="domain"
                                placeholder="www.example.com"
                            />
                            <span className='error'>{error.domain}</span>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="fontfamily" className="form-label">
                                <strong style={{ color: 'black' }}> Select Font-Family </strong>
                            </label>
                            <select className="form-control" name="fontfamily" onChange={changeHandler} id="fontfamily">
                                <option value='' selected disabled>Select</option>
                                <option value="Arial, sans-serif" style={{ fontFamily: 'Arial, sans-serif' }}>Arial</option>
                                <option value="Verdana, sans-serif" style={{ fontFamily: 'Verdana, sans-serif' }}>Verdana</option>
                                <option value="Georgia, serif" style={{ fontFamily: 'Georgia, serif' }}>Georgia</option>
                                <option value="Oswald, sans-serif" style={{ fontFamily: 'Oswald light' }}>Oswald light</option>
                                <option value="Comfortaa, cursive" style={{ fontFamily: "Comfortaa, cursive" }}>Comfortaa</option>
                            </select>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <label htmlFor="domain" className="form-label">
                                {/* Fav-Icon */}
                                <strong style={{ color: 'black' }}>Business Logo </strong>
                                <Tooltips info={'abc.ico & Width:16px, Height:16px'} />
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='favicon'
                                id="favicon"
                                onChange={changeHandler}
                                placeholder="Select abc.ico"
                            />
                        </div>
                    </div>


                    <div className='row mb-4'><hr /></div>

                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brimage1" className="form-label">
                                <strong style={{ color: 'black' }}> Select Banner Image 1 </strong>
                                <Tooltips info={'Image Size 1600 X 800'} />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='brimage1'
                                id="brimage1"
                                onChange={changeHandler}
                                placeholder="Select Image"
                            />
                            <span className='error'>{error.brimage1}</span>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brtitle1" className="form-label">
                                <strong style={{ color: 'black' }}>  Enter Company Name </strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brtitle1'
                                onChange={changeHandler}
                                id="brtitle1"
                                placeholder="Enter Title"
                            />
                            <span className='error'>{error.brtitle1}</span>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brsubtitle1" className="form-label">
                                <strong style={{ color: 'black' }}> Enter Tagline 1 </strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brsubtitle1'
                                onChange={changeHandler}
                                id="brsubtitle1"
                                placeholder="Enter Sub-Title"
                            />
                            <span className='error'>{error.brsubtitle1}</span>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <p>Hex Colors :</p>
                            <div className='row'>
                                <div className='d-flex'>
                                    <h4 className='pr-4'>
                                        <strong style={{ color: 'black' }}>
                                            Choose Your Colors
                                        </strong>
                                    </h4>
                                    <ColorModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>Font Color For Company Name </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color1}
                                name='banner_font_color1'
                                onChange={changeHandler}
                                id="banner_font_color1"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>Font Color For Tagline 1 </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color_tagline1}
                                name='banner_font_color_tagline1'
                                onChange={changeHandler}
                                id="banner_font_color_tagline1"
                                placeholder="211B16"
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brimage2" className="form-label">
                                <strong style={{ color: 'black' }}> Select Banner Image 2 </strong>
                                <Tooltips info={'Image Size 1600 X 800'} />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='brimage2'
                                onChange={changeHandler}
                                id="brimage2"
                                placeholder="select Image"
                            />
                            <span className='error'>{error.brimage2}</span>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>  Enter Business Objective </strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brtitle2'
                                onChange={changeHandler}
                                id="brtitle2"
                                placeholder="Enter Title"
                            />
                            <span className='error'>{error.brtitle2}</span>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}>  Enter Tagline 2 </strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='brsubtitle2'
                                onChange={changeHandler}
                                id="brsubtitle2"
                                placeholder="Enter Sub-Title"
                            />
                            <span className='error'>{error.brsubtitle2}</span>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <p>Hex Colors :</p>
                            <div className='row'>
                                <div className='d-flex'>
                                    <h4 className='pr-4'>
                                        <strong style={{ color: 'black' }}>
                                            Choose Your Colors
                                        </strong>
                                    </h4>
                                    <ColorModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brtitle2" className="form-label">
                                <strong style={{ color: 'black' }}> Font Color For Business Objective</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color2}
                                name='banner_font_color2'
                                onChange={changeHandler}
                                id="banner_font_color2"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-4 mb-3'>
                            <label htmlFor="brsubtitle2" className="form-label">
                                <strong style={{ color: 'black' }}> Font Color For Tagline 2  </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={fdata.banner_font_color_tagline2}
                                name='banner_font_color_tagline2'
                                onChange={changeHandler}
                                id="banner_font_color_tagline2"
                                placeholder="211B16"
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="abimage" className="form-label">
                                <strong style={{ color: 'black' }}> Select “About Us” Image</strong>
                                <Tooltips info={'Image Size 600 X 400'} />
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                name='abimage'
                                onChange={changeHandler}
                                id="abimage"
                                placeholder="Select Image"
                            />
                            <span className='error'>{error.abimage}</span>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="abheading" className="form-label">
                                <strong style={{ color: 'black' }}> “About Us” Header</strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='abheading'
                                onChange={changeHandler}
                                id="abheading"
                                placeholder="Header for “About Us” section"
                            />
                            <span className='error'>{error.abheading}</span>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label htmlFor="abstory" className="form-label">
                                <strong style={{ color: 'black' }}>  “About Us” Story</strong>
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <textarea
                                class="form-control"
                                id="abstory"
                                name='abstory'
                                onChange={changeHandler}
                                rows="3"
                            ></textarea>
                            <span className='error'>{error.abstory}</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="inst_header" className="form-label">
                                <strong style={{ color: 'black' }}>  Instructors / Staff Header </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='inst_header'
                                onChange={changeHandler}
                                id="inst_header"
                                placeholder="What title do you want for your title: Staff, Instructors, or Trainers?"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="inst_description" className="form-label"> <strong style={{ color: 'black' }}> Instructor / Staff Description </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='inst_description'
                                onChange={changeHandler}
                                id="inst_description"
                                placeholder="Briefly describe your staff"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="cls_discription" className="form-label"><strong style={{ color: 'black' }}>Classes </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='cls_discription'
                                onChange={changeHandler}
                                id="cls_discription"
                                placeholder="Briefly describe your classes"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="pas_discription" className="form-label"><strong style={{ color: 'black' }}> Passes </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='pas_discription'
                                onChange={changeHandler}
                                id="pas_discription"
                                placeholder="Briefly describe your Passes"
                            />
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label htmlFor="serv_discription" className="form-label"> <strong style={{ color: 'black' }}>Services </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='serv_discription'
                                onChange={changeHandler}
                                id="serv_discription"
                                placeholder="Briefly describe your services"
                            />
                        </div>

                        <div className='col-md-6 mb-3'>
                            <label htmlFor="wrk_discription" className="form-label"><strong style={{ color: 'black' }}> Workshops </strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='wrk_discription'
                                onChange={changeHandler}
                                id="wrk_discription"
                                placeholder="Briefly describe your workshops"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="prd_discription" className="form-label"><strong style={{ color: 'black' }}> Products </strong>  </label>
                            <input
                                type="text"
                                className="form-control"
                                name='prd_discription'
                                onChange={changeHandler}
                                id="prd_discription"
                                placeholder="Briefly describe your products"
                            />
                        </div>
                    </div>
                    <h4>Scrolling up to 3 testimonial statements</h4>
                    <div className='row'>
                        <small className="form-label">
                            <strong style={{ color: 'black' }}>Testimonial 1 </strong>
                        </small>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='provider1'
                                onChange={changeHandler}
                                id="provider1"
                                placeholder="Testimonial Provided By"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='discription1'
                                onChange={changeHandler}
                                id="discription1"
                                placeholder="Testimonial Statement"
                            />
                        </div>
                        <small className="form-label">
                            <strong style={{ color: 'black' }}>Testimonial 2 </strong>
                        </small>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='provider2'
                                onChange={changeHandler}
                                id="provider2"
                                placeholder="Testimonial Provided By"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='discription2'
                                onChange={changeHandler}
                                id="discription2"
                                placeholder="Testimonial Statement"
                            />
                        </div>
                        <small className="form-label">
                            <strong style={{ color: 'black' }}> Testimonial 3 </strong>
                        </small>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='provider3'
                                onChange={changeHandler}
                                id="provider3"
                                placeholder="Testimonial Provided By"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                name='discription3'
                                onChange={changeHandler}
                                id="discription3"
                                placeholder="Testimonial Statement"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="facebook_id" className="form-label">
                                <strong style={{ color: 'black' }}>Facebook ID </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='facebook_id'
                                onChange={changeHandler}
                                id="facebook_id"
                                placeholder="abcd123"
                            />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label htmlFor="insta_id" className="form-label">
                                <strong style={{ color: 'black' }}>
                                    Instagram ID
                                </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='insta_id'
                                onChange={changeHandler}
                                id="insta_id"
                                placeholder="abcd123"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='row'>
                            <div className='d-flex'>
                                <h4 className='pr-4'>
                                    <strong style={{ color: 'black' }}>
                                        Choose Your Colors
                                    </strong>
                                </h4>
                                <ColorModal />
                            </div>
                            <hr />
                            <p>Hex Colors :</p>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="header_fonts" className="form-label">
                                <strong style={{ color: 'black' }}> Header Fonts
                                </strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='header_fonts'
                                onChange={changeHandler}
                                id="header_fonts"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="background_for_pop_windows" className="form-label">
                                <strong style={{ color: 'black' }}> Background for Pop windows</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='background_for_pop_windows'
                                onChange={changeHandler}
                                id="background_for_pop_windows"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="borders_and_buttons" className="form-label"> <strong style={{ color: 'black' }}> Borders and Buttons</strong> </label>
                            <input
                                type="text"
                                className="form-control"
                                name='borders_and_buttons'
                                onChange={changeHandler}
                                id="borders_and_buttons"
                                placeholder="211B16"
                            />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label htmlFor="button_text_color" className="form-label">
                                <strong style={{ color: 'black' }}>Buttons Text Color</strong>
                            </label>
                            <select className="form-control" name="button_text_color" onChange={changeHandler} id="button_text_color">
                                <option value="#FFFFFF" >White</option>
                                <option value="#000000" >Black</option>
                            </select>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showinstructor'
                                    onChange={checkHandler}
                                    id="showinstructor"
                                />
                                <label className="form-label" htmlFor="showinstructor">
                                    <strong style={{ color: 'black' }}>   Show Instructors data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showclass'
                                    onChange={checkHandler}
                                    id="showclass"
                                />
                                <label className="form-label" htmlFor="showclass">
                                    <strong style={{ color: 'black' }}>  Show Classes data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showpass'
                                    onChange={checkHandler}
                                    id="showpass"
                                />
                                <label className="form-label" htmlFor="showpass">
                                    <strong style={{ color: 'black' }}>  Show Passes data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showservices'
                                    onChange={checkHandler}
                                    id="showservices"
                                />
                                <label className="form-label" htmlFor="showservices">
                                    <strong style={{ color: 'black' }}> Show Services data </strong>
                                </label>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showworkshop'
                                    onChange={checkHandler}
                                    id="showworkshop"
                                />
                                <label className="form-label" htmlFor="showworkshop">
                                    <strong style={{ color: 'black' }}>  Show Workshops data </strong>
                                </label>
                            </div>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div className='form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='showproduct'
                                    onChange={checkHandler}
                                    id="showproduct"
                                />
                                <label className="form-label" htmlFor="showproduct">
                                    <strong style={{ color: 'black' }}>   Show Products data </strong>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className='row mb-5 mt-3'>
                        <button type='submit' className='btn btn-info w-100'> <strong style={{ color: '#fff' }}>Submit </strong></button>
                    </div>

                </form>

            </div>


            {/* <ColorModal /> */}


        </>
    )
}
