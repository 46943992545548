import React from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect';
import { SharedButton } from '../../../../../sharedComponents/Button';
import logo from "../../../../../Images/logo_white_web.png"
import Swal from 'sweetalert2';
import { add_otherinstructor, get_otherinstructor } from '../../../../../Studio-Services/Studio_Services';
import { useEffect } from 'react';
import { useState } from 'react';



export const AddInstructor = () => {
    const [instlist, setInstlist] = useState([]);
    const [options , setOptions] = useState([]);
  
    const list = async () => {
        const resp = await get_otherinstructor();
        console.log("-------------------->----------",resp.data.data);
        setInstlist(resp.data.data);
    }

    useEffect(() => {
        if (instlist.length > 0) {
            const value = [];
            instlist.map((e) => (
                value.push({
                    value: e.id,
                    label: `${e.name}   ${e.lastname}`
                })
            ))
            setOptions(value);
        }
    }, [instlist]);

    useEffect(() => {
        list();
    }, []);


    const submithandler = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const selectedid = formData.get('instructor');
        if (selectedid!=="select") {
         const data = {id:selectedid}
        const resp = await add_otherinstructor(data);
          if(resp){
            Swal.fire({
                html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
             SUCCESS
              </h4>
              <p class="text-center my-4 textcolor">
              <small class="textWhite">
             ${resp.data.message}</small></p>
              `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
            });
          }
            e.target.reset();
        } else {
            Swal.fire({
                html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
                Error !!!
              </h4>
              <p class="text-center my-4 textcolor">
              <small class="textWhite">
             Please Select Instructor</small></p>
              `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
            });
        }


    }
    return (
        <>
            <Form autoComplete='off' onSubmit={submithandler}>
                <Row>
                    <Col>
                        <SharedSelect
                            options={options}
                            lable="Instructor"
                            name="instructor"
                        />
                    </Col>
                    <Col>
                        <SharedButton
                            type="submit"
                            className={"btn btn-primary mt-4"}
                            title={"Submit"} />
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
