import moment from "moment";
import React, { useEffect, useState } from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const ClientCardCarousal = ({ pass,currentUrl,redirectUrl,businessDl,addFav }) => {
 
  const cardDetail = pass ? pass : [];
  // const isChecked = checkedPassId || "";
  const navigate = useNavigate();
  const [businessDetail ,setBusinessDetail] = useState();

  useEffect(()=>{
    if(businessDl){
      setBusinessDetail(businessDl);
    }
  },[businessDl])

  const chunks = [];
  for (let i = 0; i < cardDetail.length; i += 2) {
    chunks.push(cardDetail.slice(i, i + 2));
  }

  const settings = {
    prevIcon: <FaLongArrowAltLeft />,
    nextIcon: <FaLongArrowAltRight />,
    interval: 1000000,
    pauseOnHover: true,
    wrap: true,
    touch: true,
    keyboard: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {chunks.length > 0 && (
        <Carousel {...settings} className="staffSlider">
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <Row className="mb-5">
                {chunk.map((items, cardIndex) => (
                  <Col lg={6} md={6} xs={12} key={cardIndex}>
                    <div className="available-card" style={{cursor: 'pointer'}}>
                      <div className="available-blue-card p-3 p-sm-4" onClick={() => redirectUrl(items, '/passes-detail', currentUrl)}>
                        <div className="row mx-0 ">
                          <div className="col-4 pass-logo">
                            <img src={businessDetail && businessDetail.logo ? businessDetail.logo :''} className="img-fluid" />
                          </div>
                          <div className="col-8 flex-fill pr-4 pl-5 passTextwidth">
                            <h5 className="white-color font-14 mb-2 mt-0">{items.pass_name}</h5>
                            <h5 className="white-color font-14 mb-2 mt-0">${items.full_price} <span className="font-12"> Plus Tax
                            </span></h5>
                          </div>
                        </div>
                        <div className="row mt-5 align-items-end">
                          <div className="col-6 text-left pr-0">
                            <p className="mb-0 white-color paratitle"> Valid For {items.pass_validity}</p>
                          </div>
                        </div>
                      </div>
                      <div className="studio-card-select">
                        {items.favourite == 0 && <i className="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.id, 1, 2)}></i>}
                        {items.favourite == 1 && <i className="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.id, 0, 2)}></i>}

                      </div>
                    </div>
                    {/* // <!-- end of purchase-blue-card --> */}

                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  )
}
