import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsFillHousesFill } from "react-icons/bs";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { RiBarChartBoxFill } from "react-icons/ri";
import { HiShoppingCart } from "react-icons/hi";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useContext } from "react";
import { MyContext } from "../../../../App";
import { SharedButton } from "../../../../sharedComponents/Button";
import { HiPlusCircle } from 'react-icons/hi';
import { AiFillMinusCircle } from 'react-icons/ai';
import { InputField } from "../../../../sharedComponents/InputField";
import Swal from "sweetalert2";
import logo from "../../../../Images/logo_white_web.png"
import { HoursModal } from "./HoursModal";
import { webinfo, webinfovalidation } from "../../../../Studio-Services/Studio_Services";

export default function Sidebar() {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isBarChartOpen, setIsBarChartOpen] = useState(false);
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);
  const [isSettingCartOpen, setIsSettingCartOpen] = useState(false);
  const [isUserCartOpen, setIsUserCartOpen] = useState(false);

  useEffect(() => {
    const handleCalendarClick = () => {
      setIsCalendarOpen(!isCalendarOpen);
      setIsBarChartOpen(false);
      setIsShoppingCartOpen(false);
      setIsSettingCartOpen(false);
      setIsUserCartOpen(false);
    };

    const handleBarChartClick = () => {
      setIsBarChartOpen(!isBarChartOpen);
      setIsCalendarOpen(false);
      setIsShoppingCartOpen(false);
      setIsSettingCartOpen(false);
      setIsUserCartOpen(false);
    };

    const handleShoppingCartClick = () => {
      setIsShoppingCartOpen(!isShoppingCartOpen);
      setIsCalendarOpen(false);
      setIsBarChartOpen(false);
      setIsSettingCartOpen(false);
      setIsUserCartOpen(false);
    };

    const handleSettingCartClick = () => {
      setIsSettingCartOpen(!isSettingCartOpen);
      setIsCalendarOpen(false);
      setIsBarChartOpen(false);
      setIsShoppingCartOpen(false);
      setIsUserCartOpen(false);
    };

    const handleUserCartClick = () => {
      setIsUserCartOpen(!isUserCartOpen);
      setIsCalendarOpen(false);
      setIsBarChartOpen(false);
      setIsSettingCartOpen(false);
      setIsShoppingCartOpen(false);
    };

    const calendarOptionListElement = document.querySelector(
      "#calendarOptionList"
    );
    const barChartOptionListElement = document.querySelector(
      "#barChartOptionList"
    );
    const barShoppingCartListElement = document.querySelector(
      "#barShoppingCartList"
    );
    const barSettingCartListElement = document.querySelector(
      "#barSettingCartList"
    );
    const barUserCartListElement = document.querySelector("#barUserCartList");

    calendarOptionListElement.addEventListener("click", handleCalendarClick);
    barChartOptionListElement.addEventListener("click", handleBarChartClick);
    barShoppingCartListElement.addEventListener(
      "click",
      handleShoppingCartClick
    );
    barSettingCartListElement.addEventListener("click", handleSettingCartClick);
    barUserCartListElement.addEventListener("click", handleUserCartClick);

    return () => {
      calendarOptionListElement.removeEventListener(
        "click",
        handleCalendarClick
      );
      barChartOptionListElement.removeEventListener(
        "click",
        handleBarChartClick
      );
      barShoppingCartListElement.removeEventListener(
        "click",
        handleShoppingCartClick
      );
      barSettingCartListElement.removeEventListener(
        "click",
        handleSettingCartClick
      );
      barUserCartListElement.removeEventListener("click", handleUserCartClick);
    };
  }, [
    isCalendarOpen,
    isBarChartOpen,
    isShoppingCartOpen,
    isSettingCartOpen,
    isUserCartOpen,
  ]);
  const [manu, setManu] = useState(0);

  const url = window.location.pathname;
  useEffect(() => {
    if (
      url === "/classes" ||
      url === "/service-appointment" ||
      url === "/class-schedule-detail" ||
      url === "/client-search" ||
      url === "/update-schedule-class-pass" ||
      url === "/workshop-schedule" ||
      url === "shift-details"
    ) {
      setManu(2);
    } else if (
      url === "/clsat-report" ||
      url === "/revenue-report" ||
      url === "/pass-report" ||
      url === "/service-report" ||
      url === "/warning-report" ||
      url === "/client-report" ||
      url === "/staff-report" ||
      url === "/product-report" ||
      url === "/payroll-report" ||
      url === "/list-recurring-amount"
    ) {
      setManu(3);
    } else if (
      url === "/make-a-purchase" ||
      url === "/product-request-list" ||
      url === "/pass-request-list" ||
      url === "/book-appointment"

    ) {
      setManu(4);
    } else if (
      url === "/add-people" ||
      url === "/add-items" ||
      url === "/add-things" ||
      url === "/add-schedule-class" ||
      url === "/add-schedule-workshop" ||
      url === "/list-clients" ||
      url === "/list-instructor" ||
      url === "/list-services" ||
      url === "/list-class" ||
      url === "/list-pass" ||
      url === "/list-shifts" ||
      url === "/merchant-service" ||
      url === "/marketing" ||
      url === "/create_web"
    ) {
      setManu(5);
    } else if (
      url === "/instructor-request-list" ||
      url === "/transaction-list" ||
      url === "/customer-payment-request-list"
    ) {
      setManu(6);
    }
  }, [url]);


  const [sidehide, setSidehide] = useState(false);
  const { updateContextValue, instNoti } = useContext(MyContext);
  const [notimsg, setNotimsg] = useState();


  useEffect(() => { setNotimsg(instNoti); }, [instNoti]);

  useEffect(() => {
    const sideHeader = { sideHeadre: sidehide }
    updateContextValue(sideHeader);
  }, [sidehide])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Modal Hours Operation
  const weeekArray = [
    {
      "name": 'Monday',
      "day_id": "1",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    },
    {
      "name": 'Tuesday',
      "day_id": "2",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    },
    {
      "name": 'Wednesday',
      "day_id": "3",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    },
    {
      "name": 'Thursday',
      "day_id": "4",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    },
    {
      "name": 'Friday',
      "day_id": "5",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    },
    {
      "name": 'Saturday',
      "day_id": "6",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    },
    {
      "name": 'Sunday',
      "day_id": "7",
      "slot_time": [
        {
          "time_slote_from": '',
          "time_slote_to": ''
        }
      ]
    }
  ];

  const [isWeb, setIsWeb] = useState(false);
  const [webdata, setWebdata] = useState();

  const webinformaition = async () => {
    const resp = await webinfovalidation({ "id": 123 });
    if (resp.status === 1) { setIsWeb(true); setWebdata(resp.data); } else { setIsWeb(false);  }
  }
  useEffect(() => { webinformaition(); }, []);

  return (
    <>
      {sidehide ? (
        <div style={{ paddingLeft: "26px" }}>
          <button
            className="btn btn-brandgreen px-4 py-1 font-20 mt-4 mx-auto d-block hideSide btnupdate"
            onClick={() => setSidehide(!sidehide)}
            id="expandSidebar"
            type="button"
            variant="primary"
          >
            <img
              src="/assets/images/icons/ic_accountdetails.png"
              className=""
              alt="Account Details"
            />
          </button>
        </div>
      ) : (
        <>
          <Col xl={1} lg={1} md={2}>
            <div className="LeftSide">
              <div
                className="leftmenusidebar box-shadow-light py-3"
                id="leftMenusidebar"
              >
                <Link to="" className="navbar-brand mr-0 d-block my-4">
                  <img
                    src="/assets/images/logo.png"
                    className="d-block mx-auto"
                    alt="Logo"
                  />
                </Link>
                <button
                  className="btn btn-brandgreen font-20 mt-4 mx-auto d-block"
                  id="expandSidebar"
                  type="button"
                  onClick={() => setSidehide(!sidehide)}
                  variant="primary"
                >
                  <img
                    src="/assets/images/icons/ic_accountdetails.png"
                    className=""
                    alt="Account Details"
                  />
                </button>

                <ul className="sidebarmenus mt-4">
                  <li className="option-list">
                    {/* Home */}
                    {/* ... */}
                    <Link to="/Home" className="option-list">
                      <span className="d-block relative">
                        <i className="fas fa-home" />
                      </span>
                      {/* <BsFillHousesFill /> */}
                    </Link>
                  </li>
                  <li className="">
                    <div
                      className={
                        isCalendarOpen
                          ? manu === 2
                            ? "option-list active-manu"
                            : "option-list active "
                          : manu === 2
                            ? "option-list active-manu"
                            : "option-list"
                      }
                      data-opencheck="false"
                      id="calendarOptionList"
                    >
                      <span
                        className={
                          manu === 2
                            ? "d-block relative text-white active-manu"
                            : "d-block relative"
                        }
                      >
                        <BsFillCalendarDateFill />
                      </span>
                      <div
                        className={
                          isCalendarOpen
                            ? "menuoptions py-3 show"
                            : "menuoptions py-3"
                        }
                      >
                        <ul>
                          <li>
                            <Link to="/classes">Classes</Link>
                          </li>
                          <li>
                            <Link to="/service-appointment"> Appointments </Link>
                          </li>
                          <li>
                            <Link to="/workshop-schedule"> Workshops </Link>
                          </li>
                          <li>
                            <Link to="/shift-schedule">Shifts</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="">
                    <div
                      className={
                        isBarChartOpen
                          ? manu === 3
                            ? "option-list active-manu"
                            : "option-list active "
                          : manu === 3
                            ? "option-list active-manu"
                            : "option-list"
                      }
                      data-opencheck="false"
                      id="barChartOptionList"
                    >
                      {/* <span className="d-block relative"> */}
                      <span
                        className={
                          manu === 3
                            ? "d-block relative text-white active-manu"
                            : "d-block relative"
                        }
                      >
                        <i className="fas fa-chart-line" />
                      </span>

                      {/* <RiBarChartBoxFill /> */}
                      {/* </span> */}
                      <div
                        className={
                          isBarChartOpen
                            ? "menuoptions py-3 show"
                            : "menuoptions py-3"
                        }
                      >
                        <ul>
                          <li> <Link to="/clsat-report">  Class Attendance </Link> </li>
                          <li> <Link to="/transactionreport">Revenue /Transaction Reports</Link> </li>
                          <li> <Link to="/pass-purchase-detail">Pass Purchase Detail</Link> </li>
                          <li> <Link to="/service-report">Service</Link> </li>

                          {/* <li><Link to="/staff-report">Staff</Link> </li> */}
                          {/* <li> <Link to="/product-report">Product Sales</Link> </li> */}
                          <li> <Link to="/cancel-className-report">Class Cancellations</Link> </li>
                          <li> <Link to="/list-recurring-amount">Recurring Pass Paused Billing</Link> </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="">
                    <div
                      className={
                        isShoppingCartOpen
                          ? manu === 4
                            ? "option-list active-manu"
                            : "option-list active "
                          : manu === 4
                            ? "option-list active-manu"
                            : "option-list"
                      }
                      data-opencheck="false"
                      id="barShoppingCartList"
                    >
                      <span
                        className={
                          manu === 4
                            ? "d-block relative text-white active-manu"
                            : "d-block relative"
                        }
                      >
                        <i className="fas fa-cart-plus" />
                      </span>
                      {/* <HiShoppingCart /> */}

                      <div
                        className={
                          isShoppingCartOpen
                            ? "menuoptions py-3 show"
                            : "menuoptions py-3"
                        }
                      >
                        <ul>
                          <li>
                            <Link to="/make-a-purchase">
                              Purchase Pass / Product
                            </Link>
                          </li>
                          <li>
                            <Link to="/book-appointment">
                              Book an Appointment
                            </Link>
                          </li>
                          <li>
                            <Link to="/workshop-schedule">
                              Purchase a Workshop
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="">
                    <div
                      className={
                        isSettingCartOpen
                          ? manu === 5
                            ? "option-list active-manu"
                            : "option-list active "
                          : manu === 5
                            ? "option-list active-manu"
                            : "option-list"
                      }
                      data-opencheck="false"
                      id="barSettingCartList"
                    >
                      <span
                        className={
                          manu === 5
                            ? "d-block relative text-white active-manu"
                            : "d-block relative"
                        }
                      >
                        <i className="fa fa-cog" />
                      </span>
                      {/* <AiTwotoneSetting /> */}

                      <div
                        className={
                          isSettingCartOpen
                            ? "menuoptions py-3 show"
                            : "menuoptions py-3"
                        }
                      >
                        <ul>
                          <li>
                            <Link to="/add-people">Full Business Set Up</Link>
                          </li>
                      
                          <li>
                            <Link to="/book-appointment">
                              Schedule an Appointment
                            </Link>
                          </li>
                          <li>
                            <Link to="/add-schedule-class">
                              Schedule a Class
                            </Link>
                          </li>
                          <li>
                            <Link to="/add-schedule-workshop">
                              Schedule a Workshop
                            </Link>
                          </li>
                          <li>
                            <Link to="/list-clients">Clients</Link>
                          </li>
                          <li>
                            <Link to="/list-instructor">
                              Staff/Instructors
                            </Link>
                          </li>
                          <li>
                            <Link to="/list-services">Service Types</Link>
                          </li>
                          <li>
                            <Link to="/list-class">
                              Class Types
                            </Link>
                          </li>
                          <li>
                            <Link to="/list-pass">Pass Types</Link>
                          </li>
                          <li>
                            <Link to="/list-shifts">Shifts</Link>
                          </li>
                          <li>
                            <Link to="/notification">Notifications</Link>
                          </li>
                          <li>
                            <Link to="/business-location">Rooms</Link>
                          </li>
                          <li>
                            <Link to="/list-products">Products</Link>
                          </li>
                          <li>
                            <Button
                              className="openhours-btn"
                              onClick={handleShow}
                            >
                              Hours of Operation
                            </Button>
                          </li>
                          <li>
                            <Link to="/merchant-service">
                              Merchant Services
                            </Link>
                          </li>
                          <li>
                            <Link to={isWeb ? "/businessweb" : "/create_web"}>
                              Hosted Website
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/marketing">Marketing Services</Link>
                          </li> */}



                          {/* <li onClick={()=>setManu(5)}>
                    <Link to="/list-recurring-amount">
                      Manage Recurring for paused passes
                    </Link>
                  </li> */}
                          {/* <li>
                            <Link to="/payroll">Payroll</Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="">
                    <div
                      className={
                        isUserCartOpen
                          ? manu === 6
                            ? "option-list active-manu"
                            : "option-list active "
                          : manu === 6
                            ? "option-list active-manu"
                            : "option-list"
                      }
                      data-opencheck="false"
                      id="barUserCartList"

                    >

                      <span
                        className={
                          manu === 6
                            ? "d-block relative text-white active-manu"
                            : "d-block relative"
                        }
                      >
                        <i className="fas fa-user" />
                        {/* <i className="far fa-comment" style={{position: "relative",bottom: "0.5rem"}}> */}
                        <span className="notifyCount">{notimsg}</span>
                        {/* </i> */}
                      </span>
                      {/* <FaUserAlt /> */}
                      {/* <div className="far fa-comment msg">
                        <span className="notifyCount">{notimsg}</span>
                      </div> */}
                      <div
                        className={
                          isUserCartOpen
                            ? "menuoptions py-3 show"
                            : "menuoptions py-3"
                        }
                      >
                        <ul>
                          <li>
                            <Link to="/instructor-request-list">
                              Instructor Request
                            </Link>
                          </li>
                          <li>
                            <Link to="/customer-request-list">Client List</Link>
                          </li>

                          {/* <li>
                            <Link to="/customer-payment-request-list">
                              Transaction Details
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </li>
                  {/* Rest of the menu items */}
                </ul>
              </div>
            </div>
          </Col>
        </>
      )}

      <HoursModal show={show} handleClose={handleClose} />



    </>
  );
}
