import React, { useEffect } from 'react'
import { change_business_instructor_status, getInstructorList } from '../../../../../Studio-Services/Studio_Services';
import { DataTable } from '../../../../../sharedComponents/DataTable';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { LiveSearch } from '../../../../../sharedComponents/LiveSearch';
import { SubscriptionModal } from "../../../../../sharedComponents/SubscriptionModal";
import logo from '../../../../../Images/logo_white_web.png';

export const InstructorTable = ({ setBinfo, binfo, setInstructorlistdata, instructorlistdata, staffList, loderHandler }) => {

    const [active, setActive] = useState();
    const [inActive, setInActive] = useState();


    const [tabledata, setTabledata] = useState([]);
    const [iniData, setIniData] = useState([]);


    const columns = [
        { dataField: "name", text: "Name" },
        { dataField: "registration", text: "Registration" },
        { dataField: "cell_number", text: "Cell Number" },
        { dataField: "skills", text: "Skills", formatter: (cell, row) => <h6 className="table-long-desc">{cell}</h6>, },
        { dataField: "experience", text: "Experience" },
        { dataField: "status", text: "Status" },
        { dataField: "action", text: "Action" },
    ];

    const update = async (key, status) => {
        loderHandler(true);
        const data = {
            id: key,
            status: status
        }
        const resp = await change_business_instructor_status(data);
        if (resp == undefined) {
            loderHandler(false);
        }
        if (resp) {
            staffList();
            loderHandler(false)

        }
    }

    useEffect(() => {
        const td = [];
        if (instructorlistdata) {
            instructorlistdata.forEach(element => {
                td.push({
                    name: (<div className='row'><div className='col-3 cardImg pr-2'><img className='card-img-top' src={element.profile_img ? element.profile_img : logo} alt='' /></div><div className='col-9'>{element.name}   {element.lastname} </div></div>),
                    names: element.name,
                    registration: element.registration,
                    cell_number: element.mobile,
                    skills: element.skill,
                    experience: element.experience,
                    status: element.instructor_status === "Active" ? <button onClick={() => { update(element.id, "Active") }}><i className="fas fa-circle brand-green"></i></button> : <button onClick={() => { update(element.id, "Inactive") }}><i className="fas fa-circle brand-red"></i></button>,
                    action: <Link to="/edit-instructor" state={{ data: element.id }} >Edit</Link>
                })
            });
            setIniData(td);
            setTabledata(td);
        }
        if (instructorlistdata) {
            const atv = "Active"
            const inactive = "Inactive"
            const Activecount = instructorlistdata.reduce((acc, currentValue) => {
                if (currentValue.instructor_status === atv) {
                    return acc + 1;
                }
                return acc;
            }, 0);

            const InActivecount = instructorlistdata.reduce((acc, currentValue) => {
                if (currentValue.instructor_status === inactive) {
                    return acc + 1;
                }
                return acc;
            }, 0);
            setActive(Activecount);
            setInActive(InActivecount);
        }
    }, [instructorlistdata]);




    const filterData = (data) => {
        if (typeof data !== "string") { return []; }
        const fdata = iniData.filter((item) => {
            return data.toLowerCase() === "" ? item : item.names.toLowerCase().includes(data.toLowerCase());
        });
        return fdata;
    };

    const searchHandler = (e) => {
        const value = e.target.value;
        if (value) {
            const resp = filterData(value);
            setTabledata(resp);
        } else {
            setTabledata(iniData);
        }
    }


    return (
        <>
            <div className="package m-0" style={{ alignItems: "normal" }}>
                <div className="lefttext">
                    <p>
                        <strong style={{ color: "#0e8543" }}>Current Package: </strong> <span>{binfo.package}
                            Staff/Instructors</span>
                    </p>
                    <p>
                        <span className="textgreen">
                            Current Package:
                        </span> $ {binfo.amount} /Month + Tax
                    </p>
                    <SubscriptionModal binfo={binfo} staffListss={staffList} />
                </div>
                <div className="righttext">
                    <p>
                        <span className="textgreen">
                            Monthly Active Staff Licenses
                        </span>
                    </p>
                    <p>
                        <span className="float-right">
                            <strong style={{ color: "#0e8543" }}>Used: </strong> {active} <strong
                                style={{ color: "#0e8543" }}>Available: </strong> <span>{(binfo.package) - (active)}</span>
                        </span>
                    </p>
                    <p></p>
                </div>
            </div>
            <Row className="my-4 mx-3">
                <Col>
                    <h5>Instructor List</h5>
                </Col>
                <Col>
                    <LiveSearch
                        Placeholder={
                            "Search by first name"
                        }
                        onChange={searchHandler}
                        className={"w-100 instructorSearch"}
                    />
                </Col>
            </Row>
            <DataTable data={tabledata} columns={columns} />
        </>
    )
}
