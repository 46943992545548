import React from 'react'
import { favourite } from '../../../Static_Services/Staff_Services';
import { successAlert } from '../../../Static_Services/Alertmsg';

export const StudioCart = ({ studiolist, onClick ,getData}) => {
    const  addFav = async (id, status) =>{
        var detail ={ "service_type": "1", "service_id": id, "status": status };
      const resp = await favourite(detail);
      if(resp){
        successAlert(resp.data.message);
        getData();
      }
    }
    return (
        <>
            {studiolist && studiolist.map((e, index) => (
                <div className="col-12 col-lg-3 mb-2 px-2" key={index}  >
                    <div>
                        <div className="studio-position-heart">
                            <div className="studio-card-newcustom gradient-studio-overlay box-shadow1" onClick={() => onClick(e.business_id)} key={index}>
                                <div className="img-box" style={{ backgroundImage: `url(${e.logo})` }}>
                                </div>
                                <div className="studio-detail p-3">
                                    <div className="row mx-0 align-items-start">
                                        <div className="col-md-4 profile-image box-shadow1">
                                            <img style={{objectFit:'contain'}} src={e.logo} alt='' className="img-fluid" />
                                        </div>
                                        <div className="col-md-8 studio-profile-detail flex-fill pl-3">
                                            <h5 className="nametitle classname-title mb-1">{e.business_name}</h5>
                                            {e.address.length <= 25 && <p className="brand-color3 paratitle mb-2">{e.address}</p>}
                                            {e.address.length > 25 && <p className="brand-color3 paratitle mb-2">{e.address.substring(0, 25) + '...'} </p>}
                                            {/* <p className="paratitle"><i className="fas fa-map-marker-alt mr-2 mb-0"></i>
                                                {e.distance}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="studio-card-select">
                                {e.favourite == 0 &&
                                    <i className="fas fa-heart brand-color3 font-22" onClick={()=>addFav(e.business_id,1)}></i>
                                }
                                {e.favourite == 1 &&
                                    <i className="fas fa-heart brand-color2 font-22" onClick={()=>addFav(e.business_id,0)}></i>
                                }
                            </div>
                        </div>
                    </div >
                </div >
            ))}
        </>
    )
}
