import moment from "moment";
import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from '../../../Images/logo_white_web.png'

export const ClientProduct = ({ pass, getPassId, checkedPassId ,businessDetail,redirectUrl}) => {
  const navigate = useNavigate();
  const cardDetail = pass ? pass : [];
  const isChecked = checkedPassId || "";

  const chunks = [];
  for (let i = 0; i < cardDetail.length; i += 2) {
    chunks.push(cardDetail.slice(i, i + 2));
  }

  const settings = {
    prevIcon: <FaLongArrowAltLeft />,
    nextIcon: <FaLongArrowAltRight />,
    interval: 3000,
    pauseOnHover: true,
    wrap: true,
    touch: true,
    keyboard: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {chunks.length > 0 && (
        <Carousel {...settings} className="staffSlider">
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <Row  className="mb-5 staffSlider">
                {chunk.map((productlist, cardIndex) => (
                  <Col lg={4} md={4} xs={12} key={cardIndex}>
                   <div className="mb-5">
                <a href="Javascript:void(0);" onClick={()=>redirectUrl(productlist.product_id,'/product','')}>
                  <div className="border-radius6 box-shadow1 white-bg px-3 pt-3 pb-2 detail-products">
                    <img src={productlist.product_images[0] && productlist.product_images[0].image_name ? productlist.product_images[0].image_name : logo} className="img-fluid w-100"
                      style={{height:"221px"}} />
                    <div className="row pt-3 pb-1">
                      <div className="col-6 text-left pt-3">
                        <h5 className="black-color heading-titlenew productname">{productlist.product_name} </h5>
                      </div>
                      <div className="col-6 text-right">
                        <button type="button" className="btn btn-green-grad">
                          {businessDetail.currency}{productlist.product_price} </button>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
};
