import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import { InputField } from "../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../sharedComponents/Button";
import { SharedMutiSelect, SheredMutiSelect, } from "../../../../sharedComponents/SharedMultiSelect";
import { MyDatePicker } from "../../../../sharedComponents/MyDatePicker";
import { useLocation } from "react-router-dom";
import { MyContext } from "../../../../App";
import { Editprofileform } from "./Editprofileform";

export const EditProfile = () => {



  const multioption = [
    { name: "first", placeHolder: "ABC" },
    { name: "first1", placeHolder: "ABC1" },
    { name: "first2", placeHolder: "ABC2" },
    { name: "first3", placeHolder: "ABC3" },
  ];

  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];

  const ContractorOptions = [
    { value: "Employee", label: "Employee" },
    { value: "Contractor", label: "Contractor" },
    { value: "Volunteer", label: "Volunteer" },
  ];

  const handleChange = (event) => {
    // const selectedValue = event.target.value;
    // Do something with the selected value
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Hello");
  };

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>PLEASE UPDATE YOUR PROFILE IT'S BEEN A LONG TIME...</h4>
                    <h6>UPLOAD NEW PHOTO</h6>
                    <div className='AddClientForm AddClientContent'>
                      <Editprofileform />
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
