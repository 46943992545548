import React, { useEffect, useState } from "react";
import StaticFooter from "../../staticpagis/StaticFooter";
import { Col, FormLabel, Row } from "react-bootstrap";
import { InputField } from "../../sharedComponents/InputField";
import TextArea from "antd/es/input/TextArea";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { SharedButton } from "../../sharedComponents/Button";
import StaticNav from "./StaticNav";
import { business_location_update_NEW, get_business_location_detail } from "../../Studio-Services/Studio_Services";
import { errorAlert } from "../../Static_Services/Alertmsg";
import Swal from "sweetalert2";
import logo from '../../Images/logo_white_web.png';
import { useLocation, useNavigate } from "react-router-dom";
import PlaceAutoCompleted from "../../client-staff/Components/CommanPages/PlaceAutoCompleted";
import { SharedSelect } from "../../sharedComponents/SharedSelect";

export default function NewBusinessLocation() {

    const [locationArray, setLocationArray] = useState([
        {
            "id": 1, "address": "", "capacity": "", "city": "", "country": "", "is_address_same": "Yes", "lat": "", "location_id": null, "location_name": "", "location_url": "", "longitude": "",
            "online_class_link": "", "state": "", "zipcode": "",
        }
    ]);
    const [bzDetail, setBzDetail] = useState({ "business_id": "", "user_id": "", "location_name": "", "latitude": "", "longitude": "", "address": "" });

    const [currentUser, setCurrentUser] = useState();
    const [buisnessData, setBuisnessData] = useState();
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const getcurrentUser = (localStorage.getItem('st_userData')) ? JSON.parse(localStorage.getItem('st_userData')) : JSON.parse(localStorage.getItem('st_userRegisterData'));
        setCurrentUser(getcurrentUser);
       
        if (localStorage.getItem('st_businessPrfoileData')) {
            const getbuisnessData = JSON.parse(localStorage.getItem('st_businessPrfoileData'));
            setBuisnessData(JSON.parse(localStorage.getItem('st_businessPrfoileData')));
        }
    }, [location]);

    const capitalizeFirstLetter = (str) => {
        if (typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };
      

    const getBusinessLocation = async (id) => {
        const data = { 'business_id': id };
        const resp = await get_business_location_detail(data);
        
        if (resp) {
            const preBZ = resp.data.data;
            setBzDetail({
                "business_id": preBZ.business_id,
                "user_id": preBZ.user_id,
                "location_name": preBZ.location_name,
                "latitude": preBZ.latitude,
                "longitude": preBZ.longitude,
                "address": preBZ.address
            })
            const preLocation = resp.data.data.location_info;            
            const preArray = [];
            preLocation.forEach((e) => {
                preArray.push({
                    "address": e.address,
                    "capacity": e.capacity,
                    "city": e.city,
                    "country": e.country,
                    "is_address_same": capitalizeFirstLetter(e.is_address_same),
                    "lat": e.lat,
                    "location_id": e.location_id,
                    "location_name": e.location_name,
                    "location_url": e.location_url,
                    "longitude": e.longitude,
                    "online_class_link": e.online_class_link,
                    "state": e.state,
                    "zipcode": e.zipcode,
                });
            });
            setLocationArray(preArray);
        }
    }


    useEffect(() => {
        if (buisnessData && buisnessData.business_id) {
            const bid=buisnessData.business_id
            getBusinessLocation(bid);
        }
    }, [buisnessData]);    



    const options = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ];

    const inputHandel = (e, i) => {
        const { name, value } = e.target;
        const updatedInfo = [...locationArray];
        updatedInfo[i] = { ...updatedInfo[i], [name]: value };
        if ("is_address_same" == name && value == 'Yes') {
            updatedInfo[i] = { ...updatedInfo[i], ["lat"]: bzDetail.latitude };
            updatedInfo[i] = { ...updatedInfo[i], ['longitude']: bzDetail.longitude };
            updatedInfo[i] = { ...updatedInfo[i], ['address']: bzDetail.address };
            updatedInfo[i] = { ...updatedInfo[i], ['city']: "" };
            updatedInfo[i] = { ...updatedInfo[i], ['state']: "" };
            updatedInfo[i] = { ...updatedInfo[i], ['country']: "" };
        }
        setLocationArray(updatedInfo);
    };


    const handleAddRoom = () => {
        setLocationArray((pre) => [
            ...pre,
            {
                id: pre.length + 1,
                "address": "",
                "capacity": "",
                "city": "",
                "country": "",
                "is_address_same": "Yes",
                "lat": "",
                "location_id": null,
                "location_name": "",
                "location_url": "",
                "longitude": "",
                "online_class_link": "",
                "state": "",
                "zipcode": "",
            }
        ]);
    };

    const handleDeleteRoom = (i) => {
        setLocationArray((pre) => {
            const updatedArray = [...pre];
            updatedArray.splice(i, 1);
            return updatedArray;
        });
    };

    const locationHandler = (data, i) => {
        const { getAddress, getCountry, getState, getCity, getZIP_Code, getLat, getLng, getStreet } = data;
        setLocationArray((prevInfo) => {
            const updatedInfo = [...prevInfo];
            updatedInfo[i] = {
                ...updatedInfo[i],
                "address": getAddress,
                "city": getCity,
                "state": getState,
                "country": getCountry,
                "lat": getLat,
                "longitude": getLng,
                "zipcode": getZIP_Code,
            };
            return updatedInfo;
        });

    }


    const businesslocationHandler = async () => {        

        if(!locationArray[0].location_name){errorAlert("Please enter location name");return;}
        if(!locationArray[0].capacity){errorAlert("Please enter capacity");return;}

        for (var i = 0; i < locationArray.length; i++) {
            if (locationArray[i].location_name == '' || locationArray[i].capacity == '') {
                return;
            }
            let rowObject = locationArray[i];
            let location_url = rowObject.location_url;
            if (!location_url.match(/^[a-zA-Z]+:\/\//) && location_url.length > 0) {
                locationArray.location_url = 'http://' + location_url;
            }
            if (locationArray.is_address_same == 'No') {
                if (!('longitude' in rowObject)) {
                    errorAlert('Please enter proper address.');
                    return false;
                }
            }
        }
        
        let data = {
            "user_id": bzDetail.user_id || currentUser && currentUser.id,
            "business_id": bzDetail.business_id,
            "business_address": bzDetail.address,
            "latitude": bzDetail.latitude,
            "longitude": bzDetail.longitude,
            "location_info": locationArray
        }
        const resp = await business_location_update_NEW(data);
        if (resp) {
            Swal.fire({
                html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          SUCCESS
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/business-hours');
                }
            });
        }
    }


    return (
        <>
            <StaticNav />
            <section className="Location-container">
                <div className="background-before">
                    <div className="py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="pricing-studio text-center wow animated slideInLeft mb-0">
                                        <h2 className="dark-color font-w-500 pb-0 text-left">
                                            Complete Your Location Details
                                        </h2>
                                        <p className="text-left m-0">Location details include information about where you will hold your classes or workshops such as the name of a room or additional you may provide your activities</p>
                                    </div>
                                    <div className="login-boxs border-radius10 p-4">
                                        <h5 className="text-left m-0 text-capital pb-3">
                                            Room For Your Activitys
                                        </h5>
                                        <Row>
                                            <Col md={12}>
                                                <FormLabel className="form-label inputLable">Head Quaters Address</FormLabel>
                                                <TextArea value={bzDetail.address} readOnly rows={3} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className='mt-4 p-2'>
                                                {locationArray.map((room, i) => (
                                                    <>
                                                        <Row className="AddRoomRow" key={i}>
                                                            <Col md={12}>
                                                                <Row style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <Col md={2} style={{ textAlign: "end" }}>
                                                                        <Row>
                                                                            {(i > 0) && (
                                                                                <Col md={6}>
                                                                                    <SharedButton type={"button"} className={"DeletedIcon"} iconNode={<AiFillMinusCircle />} onClick={() => handleDeleteRoom(i)} />
                                                                                </Col>
                                                                            )
                                                                            }
                                                                            {locationArray.length == (i + 1) &&
                                                                                <Col md={i === 0 ? 12 : 6}>
                                                                                    <SharedButton type={"button"} onClick={handleAddRoom} iconNode={<AiFillPlusCircle />} className={"AddPlusIcon"} />
                                                                                </Col>
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col md={6} className='mb-2'>
                                                                        <InputField lable="Room Name" name="location_name" onChange={(e) => inputHandel(e, i)} value={room.location_name} type="text" required />
                                                                    </Col>
                                                                    <Col md={6} className='mb-2'>
                                                                        <InputField lable="Map Link" name="location_url" onChange={(e) => inputHandel(e, i)} value={room.location_url} type="text" />
                                                                    </Col>

                                                                    <Col md={6} className='mb-2'>
                                                                        <InputField lable="Capacity" name="capacity" onChange={(e) => inputHandel(e, i)} value={room.capacity} type="number" required />
                                                                    </Col>
                                                                    <Col md={6} className='mb-2'>
                                                                        <SharedSelect className={"form-control"} lable="Address (Head Quarters)" name="is_address_same" onChange={(e) => inputHandel(e, i)} value={room.is_address_same} options={options} />
                                                                    </Col>
                                                                    <Col md={12} className='mb-2'>
                                                                        <InputField lable="Online Class LInk" name="online_class_link" onChange={(e) => inputHandel(e, i)} value={room.online_class_link} placeholder="" type="text" />
                                                                    </Col>
                                                                    {room.is_address_same === "No" &&
                                                                        <Col md={12} className='mb-2'>
                                                                            <PlaceAutoCompleted
                                                                                myClassName={"form-control"}
                                                                                label={'Address'}
                                                                                value={room.address}
                                                                                setCordinates={(e) => locationHandler(e, i)}
                                                                            />
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Col md={12} className='mt-2 mb-2'> <hr /></Col>
                                                    </>
                                                ))}
                                            </Col>
                                        </Row>
                                        <Row className="pt-5 pb-3">
                                            <Col>
                                                <SharedButton title={'Save & Continue'} onClick={businesslocationHandler} className={'btn me-3'} type={'button'} variant="primary" />
                                                <SharedButton title={'cancel'} className={'btn cancel'} type={'button'} variant="outline" />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <StaticFooter />
        </>
    );
}
