import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row, Spinner, Button, Form } from "react-bootstrap";
import Sidebar from "./Sidebar";
import { HeaderDashboard } from "./HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import logo from "../../../../Images/logo_white_web.png"

import { Cancel_Passes, Pause_Passes, Studio_add_member, Studio_edit_member, Studio_update_pass_expiry_byid, ToDate, customer_profile_update, family_member_purchase_details, get_customer_profile, get_relations, search_customer_details, studio_country_list, toTimestamp, update_pass_expiry, update_pass_remaining_count, utcToLocalTime, waiver_customer } from "../../../../Studio-Services/Studio_Services";
import { useLocation } from "react-router-dom";
import { CustomerProfileModal } from "../../../../sharedComponents/CustomerProfileModal";
import { FamilyMemberCard } from "../../../../sharedComponents/FamilyMemberCard";
import { FamilyMemberModal } from "../../../../sharedComponents/FamilyMemberModal";
import { CustomerAttendanceModal } from "../../../../sharedComponents/CustomerAttendanceModal";
import { CustomerProduct } from "../../../../client-staff/Components/CommanPages/CustomerProduct";
import { CustomerPurchasedCard } from "../../../../client-staff/Components/CommanPages/CustomerPurchasedCard";
import moment from "moment";
import { errorAlert, successAlert } from "../../../../Static_Services/Alertmsg";
import { InputField } from "../../../../sharedComponents/InputField";
import { CardsPasses } from "../../../../client-staff/Components/CommanPages/CardsPasses";
import { CustomerProductWithOutSlider } from "../../../../client-staff/Components/CommanPages/CustomerProductWithOutSlider";
import { RecurringActiveSlider } from "../../../../client-staff/Components/CommanPages/RecurringActiveSlider";
import { TimeActiveSlider } from "../../../../client-staff/Components/CommanPages/TimeActiveSlider";
import { RecurringInActiveSlider } from "../../../../client-staff/Components/CommanPages/RecurringInActiveSlider";
import { TimeInActiveSlider } from "../../../../client-staff/Components/CommanPages/TimeInActiveSlider";
import { PunchInActiveSlider } from "./PunchInActiveSlider";
import { PunchActiveSlider } from "./PunchActiveSlider";
import Swal from "sweetalert2";

export const StudioCustomerProfile = () => {
    const location = useLocation();
    const [userID, setUserID] = useState();
    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState('default');


    const handleShow = () => setShow(true);

    const handleButtonClick = (action) => {
        if (action === 'Cancel') {
            setModalContent('cancel');
        } else if (action === 'Pause') {
            setModalContent('pause');
        }
    };

    const [relationCollection, setRelationCollection] = useState([]);
    const [getCustomerCompleteDetail, setGetCustomerCompleteDetail] = useState();
    const [userDetail, setUserDetail] = useState();
    const [cancelRecurringPass, setCancelRecurringPass] = useState(false);
    const [confirmBoxMessage, setConfirmBoxMessage] = useState();
    const [relationIds, setRelationIds] = useState();
    const [getCtomerDetail, setGetCtomerDetail] = useState();
    const [countModal, setCountModal] = useState(false);


    useEffect(() => {
        if (location.state) {
            setGetCtomerDetail(location.state);
            setUserID(location.state);
            setGetCustomerDetail(location.state)
            getRelation();
            getCountry();
        }
    }, [location])

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("st_userData"))) {
            setUserDetail(JSON.parse(localStorage.getItem("st_userData")));
        }
    }, [])


    const [recurringPass, setRecurringPass] = useState([]);
    const [timeFramePass, setTimeFramePass] = useState([]);
    const [punchCardPass, setPunchCardPass] = useState([]);
    const [isWaiver, setIsWaiver] = useState(false);
    const [profileStatus, setProfileStatus] = useState(false);

    const [purchasePunchCardPass, setPurchasePunchCardPass] = useState();
    const [purchasePunchInactiveCard, setPurchasePunchInactiveCard] = useState();
    const [purchaseTimeFramePass, setPurchaseTimeFramePass] = useState();
    const [purchaseTimeFrameInactiveCard, setPurchaseTimeFrameInactiveCard] = useState();
    const [purchaseRecurringPass, setPurchaseRecurringPass] = useState();
    const [purchaseRecurringInactiveCard, setPurchaseRecurringInactiveCard] = useState();
    const [selectmemberId, setSelectmemberId] = useState(0);
    const [dateSelector, setDateSelector] = useState(false);
    const [currentDOB, setCurrentDOB] = useState();
    const [imgURL, setImgURL] = useState();
    const [updateModal, setUpdateModal] = useState(false);
    const [loder, setLoder] = useState();
    const [famailyProductItems, setFamailyProductItems] = useState([]);
    const [famailyUserDetail, setFamailyUserDetail] = useState();
    const [famailyPunchItems, setFamailyPunchItems] = useState([]);
    const [familyPunchInactiveItem, setFamilyPunchInactiveItem] = useState([]);
    const [famailyTimeFrameItems, setFamailyTimeFrameItems] = useState([]);
    const [familyTimeFrameInactiveItem, setFamilyTimeFrameInactiveItem] = useState([]);
    const [famailyRecurringItems, setFamailyRecurringItems] = useState([]);
    const [familyRecurringInactiveItem, setFamilyRecurringInactiveItem] = useState([]);
    const [getCustomerDetail, setGetCustomerDetail] = useState();
    const [summaryData, setsummaryData] = useState();
    const [showModalAttendance, setShowModalAttendance] = useState(false);
    const [memberDetail1, setMemberDetail1] = useState();
    const [memberDetail1My, setMemberDetail1My] = useState({ memeber_id: '', member_name: '', dob: '', gender: '', relation: '' });
    const [memberDetail1MyError, setMemberDetail1MyError] = useState({ memeber_id: '', member_name: '', dob: '', gender: '', relation: '' });

    const [selectPassType, setSelectPassType] = useState();
    const [total_count, setTotal_count] = useState(0);
    const [remaining_count, setRemaining_count] = useState(0);
    const [timeFrameMaxDate, setTimeFrameMaxDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(moment());
    const [selectPassExpiry, setSelectPassExpiry] = useState();
    const [selectPunch, setSelectPunch] = useState();
    const [booking_id, setBooking_id] = useState();
    const [selectPassName, setSelectPassName] = useState();
    const [punchCardSpinner, setPunchCardSpinner] = useState(false)
    const [passEditDate, setPassEditDate] = useState();
    const [passEXPState, setPassEXPState] = useState(false);
    const [recurringStatus, setRecurringStatus] = useState();
    const [recurringId, setRecurringId] = useState()
    const [recurringpassId, setRecurringpassId] = useState();
    const [recurringPassObj, setRecurringPassObj] = useState();
    const [confirmBoxStatus, setConfirmBoxStatus] = useState(false)
    const [gender, setGender] = useState('male');
    const [dob, setDob] = useState();
    const [profileSpinner, setProfileSpinner] = useState(false);
    const [countryList, setCountryList] = useState();


    const [clientIDS, setClientIDS] = useState();

    const [profileDetailMy, setProfileDetailMy] = useState({
        name: '', lastname: '', email: '', state: '', date_of_birth: '', city: '', country_code: '', address: '', mobile: '', country: '', emergency_country_code: '', emergency_contact_no: '', gender: '', emergency_contact_person: ''
    })
    const [profileDetailMyError, setProfileDetailMyError] = useState({
        name: '', lastname: '', email: '', state: '', date_of_birth: '', city: '', country_code: '', address: '', mobile: '', country: '', emergency_country_code: '', emergency_contact_no: '', gender: '', emergency_contact_person: ''
    })

    const passRescheduleStatus = [
        { id: 1, class: 'btn btn-outline-warning', name: 'Pause', first_text: 'Would you like to:', last_text: 'the pass for 30 days or less' },
        // { id: 2, class: 'btn btn-outline-warning', name: 'Stop', first_text: 'Would you like to:', last_text: 'the pass for an unknown or longer than 30 day period'},
        { id: 3, class: 'btn btn-outline-danger', name: 'Cancel', first_text: 'Would you like to:', last_text: 'the pass for and make inactive at the end of the month' },
    ];



    const setCurrentPassesRecord = (getDetail) => {
        let passesDetails = getDetail.avaliable_passes_data;
        let purchasePass = getDetail.purchase_passes_data;
        if (passesDetails.length > 0) {
            let recurringP = passesDetails.map((item) => item.pass_type == 'Recurring Membership');
            let timeFrameP = passesDetails.map((item) => item.pass_type == 'Time Frame');
            let punchCardP = passesDetails.map((item) => item.pass_type == 'Punch Card');
            setRecurringPass(recurringP);
            setTimeFramePass(timeFrameP);
            setPunchCardPass(punchCardP);
        } else {
            setRecurringPass([]);
            setTimeFramePass([]);
            setPunchCardPass([]);
        }
        if (purchasePass.length > 0) {
            const currentDate = moment().format('YYYY-MM-DD');
            let activePassRecords = [];
            let inActivePassRecords = [];

            let activeTimeFrameRecords = [];
            let inActiveTimeFrameRecords = [];

            let activeRecurringRecords = [];
            let inActiveRecurringRecords = [];

            purchasePass.forEach(function (value) {
                if (value.pass_type_name == 'Punch Card') {
                    const endDate = Number(value.end_date_utc);
                    const convert = moment.unix(endDate).format('YYYY-MM-DD');
                    const count = value.passes_remaining_count;
                    console.log("===========================================", value.passes_remaining_count);
                    if (moment(currentDate).isSameOrAfter(convert) || count == 0) { inActivePassRecords.push(value); } else { activePassRecords.push(value); }
                } else if (value.pass_type_name == 'Time Frame') {
                    const endDate = Number(value.end_date_utc);
                    const convert = moment.unix(endDate).format('YYYY-MM-DD');
                    if (moment(currentDate).isSameOrAfter(convert)) {
                        inActiveTimeFrameRecords.push(value);
                    } else {
                        activeTimeFrameRecords.push(value);
                    }
                } else if (value.pass_type_name == 'Recurring Membership') {
                    const endDate = Number(value.end_date_utc);
                    const convert = moment.unix(endDate).format('YYYY-MM-DD');
                    if (moment(currentDate).isSameOrAfter(convert)) {
                        inActiveRecurringRecords.push(value);
                    } else {
                        activeRecurringRecords.push(value);
                    }
                }
            });
            let purchasePunchCardPassMy = activePassRecords;
            let purchasePunchInactiveCard = inActivePassRecords;

            let purchaseTimeFramePassMy = activeTimeFrameRecords;
            let purchaseTimeFrameInactiveCardMy = inActiveTimeFrameRecords;

            let purchaseRecurringPassMy = activeRecurringRecords;
            let purchaseRecurringInactiveCardMy = inActiveRecurringRecords;
            setPurchasePunchCardPass(purchasePunchCardPassMy)
            setPurchasePunchInactiveCard(purchasePunchInactiveCard);
            setPurchaseTimeFramePass(purchaseTimeFramePassMy);
            setPurchaseTimeFrameInactiveCard(purchaseTimeFrameInactiveCardMy);
            setPurchaseRecurringPass(purchaseRecurringPassMy);
            setPurchaseRecurringInactiveCard(purchaseRecurringInactiveCardMy);

        } else {
            setPurchasePunchCardPass([])
            setPurchasePunchInactiveCard([]);
            setPurchaseTimeFramePass([]);
            setPurchaseTimeFrameInactiveCard([]);
            setPurchaseRecurringPass([]);
            setPurchaseRecurringInactiveCard([])
        }
    }

    const getCustomerInfo = async () => {
        const finalData = { class_id: "", client_id: userID.id, };
        setLoder(true);
        const response = await search_customer_details(finalData);
        if (response) {
            setLoder(false);
            const resp = response.data.data;
            setGetCustomerCompleteDetail(resp);
            setCurrentPassesRecord(resp);
            const myid = response.data.data.id;
            setClientIDS(myid)
            if (response.data.data.waiver == 1) {
                setIsWaiver(true);
            } else {
                setIsWaiver(false);
            }
            setProfileStatus(true);
        } else {
            setLoder(false);
            window.history.back();
            setGetCustomerCompleteDetail({});
        }
        setLoder(false);
    };


    const changePassInfo = (list, type = 1) => {
        setPassEditDate('');

        if (list.pass_type_name === 'Time Frame') {
            const currentDate = moment(); // Current date and time
            const futureDate = currentDate.add(7, 'days');
            console.log(futureDate.format());

            // let date = new Date();
            // console.log("==========----------->", date);
            // date.setDate(date.getDate() + +list.remaining_count);
            setTimeFrameMaxDate(futureDate);
        }
        // 1: Edit days, 2: Date Update
        setSelectPunch(list);
        setBooking_id(list.user_booking_id);
        setTotal_count(list.passes_total_count);
        setRemaining_count(list.passes_remaining_count);
        setSelectPassName(list.pass_name);
        setSelectPassType(list.pass_type_name);
        
        setPassEditDate(moment(list.end_date).format('YYYY-MM-DD'))
        let milliseconds = list.end_date_utc * 1000;
        setSelectPassExpiry(new Date(milliseconds));
        if (type === 1) {
            setCountModal(true);
        } else { setPassEXPState(true) }
    }


    useEffect(() => {
        if (userID && userID.id) {
            getCustomerInfo();
        }
    }, [userID]);


    const waiver = (e) => {
        const isChecked = e.target.checked;
        setIsWaiver(isChecked);
        const finalData = {
            'cust_id': clientIDS,
            'cust_status': isChecked ? 1 : 0,
        }
        Swal.fire({
            title: '<small>CONFIRMATION</small>',
            icon: 'info',
            html:
                'Are you sure to change status ?',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "YES",
            cancelButtonText: "NO"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await waiver_customer(finalData);
                if (resp) {
                    Swal.fire({
                        html: `
              <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
              <p class="text-center my-4 textcolor">
                <small class="textWhite">${resp.data.message}</small>
              </p>
            `,
                        showClass: {
                            popup: 'custom-dialog-container',
                        },
                        customClass: {
                            confirmButton: 'custom-button custom-button-size modalbtn',
                        },
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            getCustomerInfo();
                        }
                    });
                }
            } else {
                setIsWaiver(!isChecked);
            }
        })

        // setDateSelector(false);
        // setCancelRecurringPass(false);
        // setConfirmBoxStatus(true);
        // //this.confirmBoxStatus = true;
        // setConfirmBoxMessage("Are You Sure To Change Status");

    }

    const update = (detail) => {
        let membDetail1 = detail;
        let relative_id = ''
        for (let i = 0; i < relationCollection.length; i++) {
            if (relationCollection[i].realtion_name == membDetail1.relation) {
                setRelationIds(relationCollection[i].relative_id);
                relative_id = relationCollection[i].relative_id;

            }
        }
        setCurrentDOB(membDetail1.dob);
        setImgURL(detail.image);
        setUpdateModal(true);
        setMemberDetail1(membDetail1);
        setMemberDetail1My({ memeber_id: membDetail1.memeber_id, member_name: membDetail1.member_name, dob: membDetail1.dob, gender: membDetail1.gender, relation: relative_id });
    }

    const viewFamilyPass = async (memberId) => {

        // let selectmemberId =
        setSelectmemberId(memberId);
        let tempObj = {};
        tempObj.parentId = getCtomerDetail.id;
        tempObj.memberId = memberId;
        // this.
        // setLoder(true);       
        const response = await family_member_purchase_details(tempObj);
        if (response) {

            let data = response.data.data;
            setFamailyProductItems(data.product);
            setFamailyUserDetail(data.attendance);
            const currentDate = moment().format('YYYY-MM-DD');

            let activePassRecords = [];
            let inActivePassRecords = [];

            data.punch.forEach(function (value) {
                const endDate = Number(value.end_date_utc);
                const convert = moment.unix(endDate).format('YYYY-MM-DD');
                if (moment(currentDate).isSameOrAfter(convert)) {
                    inActivePassRecords.push(value);
                } else {
                    activePassRecords.push(value);
                }
            });

            setFamailyPunchItems(activePassRecords);
            setFamilyPunchInactiveItem(inActivePassRecords);

            let activeTimeFrameRecords = [];
            let inActiveTimeFrameRecords = [];

            data.time.forEach(function (value) {
                const endDate = Number(value.end_date_utc);
                const convert = moment.unix(endDate).format('YYYY-MM-DD');
                if (moment(currentDate).isSameOrAfter(convert)) {
                    inActiveTimeFrameRecords.push(value);
                } else {
                    activeTimeFrameRecords.push(value);
                }
            });

            setFamailyTimeFrameItems(activeTimeFrameRecords);
            setFamilyTimeFrameInactiveItem(inActiveTimeFrameRecords);

            let activeRecurringRecords = [];
            let inActiveRecurringRecords = [];

            data.recurring.forEach(function (value) {
                const endDate = Number(value.end_date_utc);
                const convert = moment.unix(endDate).format('YYYY-MM-DD');
                if (moment(currentDate).isSameOrAfter(convert)) {
                    inActiveRecurringRecords.push(value);
                } else {
                    activeRecurringRecords.push(value);
                }
            });

            setFamailyRecurringItems(activeRecurringRecords);
            setFamilyRecurringInactiveItem(inActiveRecurringRecords);
            setLoder(false);

        } else {
            setLoder(false)
            setFamailyProductItems([]);
            setFamailyPunchItems([]);
            setFamailyTimeFrameItems([]);
            setFamailyRecurringItems([]);
            setFamilyPunchInactiveItem([]);
            setFamilyTimeFrameInactiveItem([]);
            setFamilyRecurringInactiveItem([]);
            setFamailyUserDetail();
        }
        setLoder(false);
    }

    const resetFamilyPass = () => {
        setSelectmemberId(0);
        setFamailyProductItems([]);
        setFamailyPunchItems([]);
        setFamailyTimeFrameItems([]);
        setFamailyRecurringItems([]);

        setFamilyPunchInactiveItem([]);
        setFamilyTimeFrameInactiveItem([]);
        setFamilyRecurringInactiveItem([]);
        setFamailyUserDetail();
    }

    const ViewDetail = (detail) => {
        setsummaryData(detail);
        setShowModalAttendance(true)
    }
    const [selectedFile, setSelectedFile] = useState();
    const [message, setMessage] = useState();
    const [imagePath, setImagePath] = useState();


    //  * get Upload image detail *//
    const getUploadFileDetail = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            var mimeType = event.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                setMessage("Only images are supported.");
                return;
            }
            var reader = new FileReader();
            setImagePath(event.target.files);
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                setImgURL(reader.result);
            };
        }
    }


    const updatePassCounter = async () => {

        // alert('Hello');return;
        const total = total_count;
        // const remaining_count = remaining_count;
        // const selectPunch = selectPunch;

        let tempObject = {};
        tempObject.user_booking_id = selectPunch.user_booking_id;
        tempObject.pass_type = selectPunch.pass_type_name;

        if (selectPunch.pass_type_name === "Punch Card") {
            if (+remaining_count > +total) {
                errorAlert("The remaining count should be equal to or less than to Total Count.");
                return false;
            }
            tempObject.passes_total_count = total;
            tempObject.passes_remaining_count = remaining_count;
        } else {
            const expiry_date = toTimestamp(selectPassExpiry);
            tempObject.passes_expiry_date = expiry_date;
        }
        setPunchCardSpinner(true);

        // this.business_service
        const response = await update_pass_remaining_count(tempObject);
        if (response) {
            setSelectPunch();
            setBooking_id();
            setTotal_count();
            setRemaining_count();
            setSelectPassName();
            setSelectPassType();
            setPunchCardSpinner(false);
            setCountModal(false);
            successAlert(response.data.message);
            if (selectmemberId == 0) {
                getCustomerInfo();
            } else {
                viewFamilyPass(selectmemberId);
            }
        }
        setPunchCardSpinner(false);

    }

    const [eXPassEXPModal, setEXPassEXPModal] = useState(false);
    const [notclose, setNotclose] = useState(false);

    const changeExPassInfo = (list, type = 1) => {
        if (list.pass_type_name === 'Time Frame') {
            let date = new Date();
            date.setDate(date.getDate() + +list.remaining_count);
            setTimeFrameMaxDate(date);
        }
        // 1: Edit days, 2: Date Update
        setSelectPunch(list);
        setBooking_id(list.user_booking_id);
        setTotal_count(list.passes_total_count);
        setRemaining_count(list.passes_remaining_count);
        setSelectPassName(list.pass_name);
        setSelectPassType(list.pass_type_name);
        let milliseconds = list.end_date_utc * 1000;
        setSelectPassExpiry(new Date(milliseconds));
        setNotclose(true);
    }

    const updatePassDate = async () => {
        if (passEditDate == undefined) {
            errorAlert("Please select expiry date first");
        } else {
            let tempObj = {};
            tempObj.id = selectPunch.user_booking_id;
            tempObj.exp_date = moment(passEditDate).unix();

            // tempObj.exp_date = toTimestamp((new Date(passEditDate)));

            setPunchCardSpinner(true);
            const response = await update_pass_expiry(tempObj);
            if (response) {
                setPunchCardSpinner(false);
                successAlert(response.data.message);
                setPassEXPState(false);

                setSelectPunch();
                setBooking_id();
                setTotal_count();
                setRemaining_count();
                setSelectPassName();
                setSelectPassType();
                if (selectmemberId == 0) {
                    getCustomerInfo();
                } else {
                    viewFamilyPass(selectmemberId);
                }
            }
            setPunchCardSpinner(false);
        }
    }

    const updateExPassDate = async () => {
        if (passEditDate == undefined) {
            errorAlert("Please select expiry date first");
        } else {
            let tempObj = {};
            tempObj.id = selectPunch.user_booking_id;
            tempObj.pass_expired_date = moment(passEditDate).unix();// toTimestamp((new Date(passEditDate)).toUTCString());
            // console.log(moment(passEditDate).unix());return;
            setPunchCardSpinner(true);
            const response = await Studio_update_pass_expiry_byid(tempObj);
            if (response) {
                setNotclose(false);
                setPassEditDate();
                setPunchCardSpinner(false);
                setSelectPunch();
                setBooking_id();
                setTotal_count();
                setRemaining_count();
                setSelectPassName();
                setSelectPassType();
                successAlert(response.data.message);
                if (selectmemberId == 0) {
                    getCustomerInfo();
                } else {
                    viewFamilyPass(selectmemberId);
                }
            } else setPunchCardSpinner(false);
        }

    }


    const [pauseCaseValue, setPauseCaseValue] = useState({ "pauseStartDate": '', "days": '', "pauseEndDate": '' });
    const [pauseCaseValueError, setPauseCaseValueError] = useState({ "pauseStartDate": '', "days": '', "pauseEndDate": '' });


    const changeStatus = (type, passId, id, rowObj = '') => {
        type = 1;
        setRecurringPassObj(rowObj);
        if (rowObj && rowObj.pass_pause_start_less_then_thirty) {
            setPauseCaseValue((pre) => ({ ...pre, pauseStartDate: rowObj.pass_pause_start_less_then_thirty }));
        }

        localStorage.setItem('pass_recurring_pause', JSON.stringify(rowObj));
        setCancelRecurringPass(false);
        setRecurringId(id);
        setRecurringpassId(passId);
        if (type == 1) {
            setDateSelector(false);
            setRecurringStatus(1);
            //   this.confirmBoxMessage = "Are you sure you want to Pause the Membership";
            //   this.confirmBoxTitle = 'Pass Status: Active';
        } else {
            setRecurringStatus(0);
            setDateSelector(true);
            //   this.confirmBoxMessage = "Are you sure you want to Start the Membership";
            //   this.confirmBoxTitle = 'Pass Status: Paused';
        }
        setConfirmBoxStatus(true);
        setShow(true);
        // this.confirmBoxStatus = true;
    }


    const cancelPass = (type, passId, id) => {
        setRecurringId(id);
        setRecurringpassId(passId);
        // this.confirmBoxMessage = "Are you sure you want to cancel the Membership";
        // this.confirmBoxTitle = 'Pass Status: Active';
        setCancelRecurringPass(true);
        setConfirmBoxStatus(true);
    }


    const getRelation = async () => {
        const tempObj = {};
        // this.business_service
        const resp = await get_relations(tempObj);
        if (resp) {
            setRelationCollection(resp.data.data);
        } else {
            setRelationCollection([])
        }
    }

    const [memberSpinner, setMemberSpinner] = useState(false);

    const addMembers = async (e) => {
        e.preventDefault();
        const fData = new FormData(e.target);
        const fValue = Object.fromEntries(fData.entries());
        let isValid = 1;

        if (!memberDetail1My.member_name) { isValid = 2; setMemberDetail1MyError((pre) => ({ ...pre, member_name: 'Full Name is required' })) }
        if (!memberDetail1My.dob) { isValid = 3; setMemberDetail1MyError((pre) => ({ ...pre, dob: '* Required' })) }
        if (!memberDetail1My.gender) { isValid = 4; setMemberDetail1MyError((pre) => ({ ...pre, gender: 'Gender is required' })) }
        if (!memberDetail1My.relation) { isValid = 5; setMemberDetail1MyError((pre) => ({ ...pre, relation: 'Please Select Relation' })) }

        // ********** UPDATE OLD MEMBER ************ //

        if (fValue.memeber_id) {
            if (isValid == 1) {
                setMemberSpinner(true);
                let formdata = new FormData();
                formdata.append("fullname", memberDetail1My.member_name);
                formdata.append("dob", memberDetail1My.dob);
                formdata.append("relative_id", memberDetail1My.relation);
                formdata.append("member_id", memberDetail1My.memeber_id);
                formdata.append("gender", memberDetail1My.gender);
                formdata.append("user_id", memberDetail1.relative_id);
                if (selectedFile) {
                    formdata.append("image", selectedFile);
                }
                const resp = await Studio_edit_member(formdata);
                if (resp) {
                    setMemberDetail1My({ memeber_id: '', member_name: '', dob: '', gender: '', relation: '' });
                    setMemberSpinner(true);
                    successAlert(resp.data.message);
                    setMemberSpinner(false);
                    setUpdateModal(false);
                    getCustomerInfo();
                } else setMemberSpinner(true);
            }
        } else {
            if (isValid === 1) {
                setMemberSpinner(true);
                let formdatas = new FormData();
                formdatas.append("fullname", memberDetail1My.member_name);
                formdatas.append("dob", memberDetail1My.dob);
                formdatas.append("relative_id", memberDetail1My.relation);
                formdatas.append("image", selectedFile);
                formdatas.append("gender", memberDetail1My.gender);
                formdatas.append("user_id", getCustomerDetail.id);
                const responce = await Studio_add_member(formdatas);
                if (responce) {
                    setMemberDetail1My({ memeber_id: '', member_name: '', dob: '', gender: '', relation: '' });
                    successAlert(responce.data.message);
                    setMemberSpinner(false);
                    setUpdateModal(false);
                    getCustomerInfo();
                } else { setMemberSpinner(false); }
            }
        }
    }
    const [profileDetail, setProfileDetail] = useState([]);
    const [editProfileModal, setEditProfileModal] = useState(false)

    const getProfile = async () => {
        setEditProfileModal(true);
        const tempObj = {};
        tempObj.user_id = getCustomerCompleteDetail.id;
        const resp = await get_customer_profile(tempObj);
        if (resp) {
            let response = resp.data;
            if (response.data.date_of_birth) {
                setDob(response.data.date_of_birth);
            }
            let Profile_Details = response.data;
            if (Profile_Details && Profile_Details.gender) {
                setGender(Profile_Details.gender);
            }
            if (Profile_Details) {
                Profile_Details.mobile1 = Profile_Details.country_code + "  " + Profile_Details.mobile;
            }
            setProfileDetail(Profile_Details);
            setProfileDetailMy({
                name: Profile_Details.name,
                lastname: Profile_Details.lastname,
                email: Profile_Details.email,
                state: Profile_Details.state,
                date_of_birth: Profile_Details.date_of_birth,
                city: Profile_Details.city,
                country_code: Profile_Details.country_code,
                address: Profile_Details.address,
                mobile: Profile_Details.mobile,
                country: Profile_Details.country,
                emergency_country_code: Profile_Details.emergency_country_code,
                emergency_contact_no: Profile_Details.emergency_contact_no,
                gender: Profile_Details.gender,
                emergency_contact_person: Profile_Details.emergency_contact_person
            })
        } else { setProfileDetail([]) }
    }


    const newFamilyAdd = () => {
        setMemberDetail1My({ memeber_id: '', member_name: '', dob: '', gender: '', relation: '' });
        setUpdateModal(true)
        setRelationIds();
        setImgURL();
    }

    const faminlyOnChange = (e) => {
        const { name, value } = e.target;
        setMemberDetail1MyError((pre) => ({ ...pre, [name]: '' }));
        setMemberDetail1My((pre) => ({ ...pre, [name]: value }));
    }


    const getCountry = async () => {
        let cd = '';
        const resp = await studio_country_list();
        if (resp) {
            let response = resp.data;
            response.data.map((el) => {
                if (el.name == "United States") {
                    cd = el.name + " (" + el.code + ")";
                }
            });
            setCountryList(response.data);
            let abc = profileDetailMy;
            abc.emergency_country_code = cd;
            setProfileDetailMy(abc);
        }
    }

    const profileDetailHandler = (e) => {
        const { name, value } = e.target;
        setProfileDetailMyError((pre) => ({ ...pre, [name]: '' }));
        setProfileDetailMy((pre) => ({ ...pre, [name]: value }));
    }

    const updateProfile = async (e) => {
        e.preventDefault();
        let isValid = 1;

        if (!profileDetailMy.name) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, name: 'First Name is required' })) }
        if (!profileDetailMy.lastname) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, lastname: 'Last Name is required' })) }

        if (!profileDetailMy.state) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, state: 'State is required' })) }
        if (!profileDetailMy.date_of_birth) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, date_of_birth: 'Date of Birth is required' })) }
        if (!profileDetailMy.city) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, city: '' })) }
        if (!profileDetailMy.country_code) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, country_code: '' })) }
        if (!profileDetailMy.address) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, address: 'Address is required' })) }
        if (!profileDetailMy.mobile) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, mobile: '' })) }
        if (!profileDetailMy.country) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, country: 'Country is required' })) }
        if (!profileDetailMy.emergency_country_code) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, emergency_country_code: '' })) }
        if (!profileDetailMy.emergency_contact_no) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, emergency_contact_no: '' })) }
        if (!profileDetailMy.gender) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, gender: '' })) }
        if (!profileDetailMy.emergency_contact_person) { isValid = 2; setProfileDetailMyError((pre) => ({ ...pre, emergency_contact_person: '' })) }

        if (isValid === 1) {
            setProfileSpinner(true);
            var formData = new FormData();
            formData.append("user_id", getCustomerCompleteDetail.id);
            formData.append("password", profileDetail.password);
            formData.append("name", profileDetailMy.name);
            formData.append("lastname", profileDetailMy.lastname);
            formData.append("gender", profileDetailMy.gender);
            formData.append("date_of_birth", profileDetailMy.date_of_birth);
            formData.append("address", profileDetailMy.address);
            formData.append("city", profileDetailMy.city);
            formData.append("state", profileDetailMy.state);
            formData.append("country", profileDetailMy.country);
            formData.append("about", profileDetail.about);
            formData.append("zipcode", profileDetail.zipcode);
            formData.append("lat", profileDetail.lat);
            formData.append("lang", profileDetail.lang);
            formData.append("street", profileDetail.street);
            if (!imgURL) {
                formData.append("image", profileDetail.profile_img);
            } else {
                formData.append("image", selectedFile);
            }
            // formData.append('image',this.imgURL);
            formData.append("role_id", profileDetail.role_id);
            formData.append("mobile", profileDetail.mobile);
            formData.append("country_code", profileDetail.country_code);

            formData.append("device_type", "");
            formData.append("device_token", "");
            formData.append(
                "emergency_contact_person",
                profileDetailMy.emergency_contact_person
            );
            formData.append(
                "emergency_country_code",
                profileDetailMy.emergency_country_code
            );
            formData.append(
                "emergency_contact_no",
                profileDetailMy.emergency_contact_no
            );
            formData.append("skills", profileDetail.skill);
            formData.append("experience", profileDetail.experience);
            const resp = await customer_profile_update(formData);
            if (resp) {
                setProfileSpinner(false);
                setImgURL();
                setEditProfileModal(false)
                getCustomerInfo();
                successAlert(resp.data.message);
            } else {
                setProfileSpinner(false);
            }
        }
    }




    const checkLessThenThirty = (days) => {
        const preDate = moment(pauseCaseValue.pauseStartDate).format('YYYY-MM-DD');
        const nextDate = moment(preDate).add(days, 'day').format('YYYY-MM-DD');
        const differenceInDays = moment(nextDate).diff(preDate, 'days');
        if (differenceInDays < 30) {
            return nextDate;
        } else { errorAlert('Please enter less then 30 days'); return false; }

        //     const preDay = moment(pauseCaseValue.pauseStartDate).date();


        // return false;

        // const fDay = parseInt(preDay) + parseInt(days);
        // const endDates = moment(pauseCaseValue.pauseStartDate).add(days, 'day').format('YYYY-MM-DD');
        // console.log('preDay', preDay, '--', days, 'end date', endDates);
        // if (parseInt(days) > 29) {
        //     errorAlert('Please enter less then 30 days');
        //     return false;
        // } else return endDates;
    }

    const getDays = (pre, past) => {

        // Define the two dates
        const startDate = moment(pre);
        const endDate = moment(past);

        // Calculate the difference in days
        const differenceInDays = endDate.diff(startDate, 'days');
        if (differenceInDays < 30) {
            return differenceInDays;
        } else { errorAlert('Please enter less then 30 days'); return false; }

    }


    const handleClose = () => {
        setRecurringPassObj();
        setRecurringId();
        setRecurringpassId();
        setShow(false); setModalContent('default');
    };


    const cancelPausePass = async () => {
        const monthEndDate = moment().endOf('month').format('YYYY-MM-DD');
        const userID = selectmemberId === 0 ? getCustomerCompleteDetail.id : selectmemberId;
        const businessIS = recurringPassObj.business_id;
        const finalData = {
            "user_id": userID,
            "business_id": businessIS,
            "pass_id": recurringpassId,
            "id": recurringId,
            "schedule_date": monthEndDate,
        }
        const resp = await Cancel_Passes(finalData);
        if (resp) {
            successAlert(resp.data.message);
            getCustomerInfo();
            setShow(false);
            setRecurringPassObj();
            setRecurringId();
            setRecurringpassId();
        }
    }


    const confirmMultipleResponse = async () => {
        if (recurringPassObj && recurringPassObj.pass_pause_start_less_then_thirty == '') {
            if (pauseCaseValue && pauseCaseValue.pauseStartDate === '') {
                errorAlert('Plese Select Pause Start Date');
                return;
            }
            if (pauseCaseValue && pauseCaseValue.days === '') {
                errorAlert('Days Paused (must be less than 30 days)'); return;
            }

            const startDates = pauseCaseValue.pauseStartDate;
            const selectedDay = pauseCaseValue.days;
            const endDates = checkLessThenThirty(selectedDay);
            if (!endDates) { return; }
            const userID = selectmemberId === 0 ? getCustomerCompleteDetail.id : selectmemberId;
            const businessID = recurringPassObj.business_id;
            const finalData = {
                "user_id": userID,
                "business_id": businessID,
                "pass_id": recurringpassId,
                "id": recurringId,
                "schedule_date": startDates,
                "schedule_end_date": endDates,
                "days": selectedDay
            }
            const resp = await Pause_Passes(finalData);
            if (resp) {
                successAlert(resp.data.message);
                getCustomerInfo();
                setShow(false);
                setRecurringPassObj();
                setRecurringId();
                setRecurringpassId();
            }
        } else {
            const startDates = pauseCaseValue.pauseStartDate;
            const endDates = pauseCaseValue.pauseEndDate;
            const userID = selectmemberId === 0 ? getCustomerCompleteDetail.id : selectmemberId;
            const businessIS = recurringPassObj.business_id;
            const diffday = getDays(startDates, endDates);
            if (!diffday) { return; };
            const finalData = {
                "user_id": userID,
                "business_id": businessIS,
                "pass_id": recurringpassId,
                "id": recurringId,
                "schedule_date": startDates,
                "schedule_end_date": endDates,
                "days": diffday
            }
            const resp = await Pause_Passes(finalData);
            if (resp) {
                successAlert(resp.data.message);
                getCustomerInfo();
                setShow(false);
                setRecurringPassObj();
                setRecurringId();
                setRecurringpassId();
            }
        }
    }


    const changeHandlerPause = (e) => {
        const { name, value } = e.target;
        setPauseCaseValue((pre) => ({ ...pre, [name]: value }));
        setPauseCaseValueError((pre) => ({ ...pre, [name]: '' }));
    }



    const timeFramHandler = () => {

    }

    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col className="p-0 RightCol">
                            <HeaderDashboard />
                            <div className="RightSide">
                                <div className="page-content-wrapper" id="mainpageWrapper">

                                    <div className="page-content-inner bg-brand-dark mt-3 py-3 pl-4">
                                        {profileStatus &&
                                            <section className="contentSections mt-5">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="right-productdetail-sec p-4 relative mt-5 customer-section">
                                                            <div className="row mx-0 align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="user-profile-img">
                                                                        <img src={getCustomerCompleteDetail.profile_img} className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10">

                                                                    <div className="selected-user-detail pl-3">
                                                                        <h4 className="text-capitilize mb-1">Name: {getCustomerCompleteDetail.name}</h4>
                                                                        <span className="brand-light">Customer</span>
                                                                        <p className="mb-0">DOB: {getCustomerCompleteDetail.date_of_birth}</p>
                                                                        <p className="mt-1 mb-0">Email: {getCustomerCompleteDetail.email}</p>
                                                                        <p className="mt-1 mb-0">Mobile:
                                                                            {getCustomerCompleteDetail.country_code}-{getCustomerCompleteDetail.mobile}</p>
                                                                        <p className="mt-1 mb-0">Address:{getCustomerCompleteDetail.address}</p>
                                                                        <p className="mt-1 mb-0">Referred By: {getCustomerCompleteDetail.referred_by}</p>
                                                                        <p className="mt-1 mb-0">Emergency Contact Name / Number: {getCustomerCompleteDetail.contact_person} / {getCustomerCompleteDetail.contact_no}</p>
                                                                      
                                                                      {!isWaiver ?
                                                                        <p className="mt-1 mb-0">Waiver:
                                                                            <input style={{cursor:"pointer"}} type="checkbox" checked={isWaiver}
                                                                                // [disabled]="selectmemberId != 0" [(ngModel)]=""
                                                                                // ngModelOptions="{standalone: true}"
                                                                                onClick={(e) => waiver(e)}
                                                                            />
                                                                        </p>
                                                                        :
                                                                        <p className="mt-1 mb-0">Waiver:
                                                                        <input type="checkbox" style={{cursor:"pointer"}} checked={isWaiver} readOnly   />
                                                                    </p>
                                                                        }
                                                                        {(selectmemberId == 0) ?
                                                                            <div>
                                                                                <button className="btn btn-brandblue mt-1" data-toggle="modal"
                                                                                    data-target="#EditProfile" id="btn_close21" data-backdrop="static"
                                                                                    data-keyboard="false"
                                                                                    onClick={() => getProfile()}
                                                                                >Edit Profile</button>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {selectmemberId == 0 &&
                                                    <div className="row" >
                                                        <div className="col-8">
                                                            <h4 className="title text-uppercase mt-5">Family Members</h4>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            <button className="btn btn-brandblue mt-5 mr-3 text-right" data-toggle="modal" data-target="#addFamily" onClick={() => newFamilyAdd()}
                                                                data-backdrop="static" data-keyboard="false">Add Family Members</button>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row family-section">
                                                    {getCustomerCompleteDetail.family_member.map((fam) => (
                                                        <div className="col-xl-4">
                                                            <div className="right-productdetail-sec p-4 relative mt-5 customer-section">
                                                                <div className="row mx-0 align-items-center">
                                                                    <div className="col-md-3">
                                                                        <div className="user-profile-img">
                                                                            <img src={fam.image} className="img-fluid" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-9">
                                                                        <div className="selected-user-detail pl-3">
                                                                            <span style={{ display: 'flex' }}>
                                                                                <h5 className="text-capitilize mb-1">
                                                                                    {fam.member_name}
                                                                                </h5>
                                                                                {selectmemberId == 0 &&
                                                                                    <button className="d-inline-block border-radius6 ml-2 mr-2"
                                                                                        onClick={() => update(fam)}>
                                                                                        <a href="Javascript:void(0);">
                                                                                            <i className="fas fa-pencil-alt"></i>
                                                                                        </a>
                                                                                    </button>
                                                                                }
                                                                            </span>
                                                                            <span className="brand-light">Relation: {fam.relation}</span>
                                                                            <p className="mb-0">DOB: {fam.dob}</p>
                                                                            <p className="mt-1 mb-0">Gender: {fam.gender}</p>
                                                                            {selectmemberId == 0 ?
                                                                                <div >
                                                                                    <a className="mt-1 mb-0 pass-edit-view btn btn-brandbluegrad"
                                                                                        onClick={() => viewFamilyPass(fam.id)}
                                                                                    >View Details</a>
                                                                                </div>
                                                                                :
                                                                                <div >
                                                                                    {fam.id == selectmemberId &&
                                                                                        <a className="mt-1 mb-0 pass-edit-view  btn btn-brandgreengrad"
                                                                                            onClick={() => resetFamilyPass()}>Back</a>}
                                                                                    {/* {fam.id != selectmemberId &&
                                                                                        <a href='#' className="mt-1 mb-0 btn btn-brandlight"></a>} */}
                                                                                </div>
                                                                            }
                                                                            <button type="button" data-toggle="modal" data-target="#addFamily" id="btn_close"
                                                                                className="btn text-uppercase w-100 mt-3" data-backdrop="static" data-keyboard="false"
                                                                                style={{ display: "none" }}> </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>


                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="right-productdetail-sec p-4 relative mt-5">
                                                            <h4 className="title text-uppercase mt-5">Attendance</h4>
                                                            {selectmemberId == 0 &&
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            <button className="btn text-uppercase d-block" type="button"
                                                                                onClick={() => ViewDetail(getCustomerCompleteDetail.attendance.class)} >
                                                                                Classes
                                                                            </button>
                                                                            <button className="btn text-uppercase d-block" type="button"
                                                                                onClick={() => ViewDetail(getCustomerCompleteDetail.attendance.workshop)} >
                                                                                Workshops
                                                                            </button>
                                                                            <button className="btn text-uppercase d-block" type="button"
                                                                                onClick={() => ViewDetail(getCustomerCompleteDetail.attendance.appointment)}
                                                                            >  Appointments
                                                                            </button>
                                                                            <button type="button" data-toggle="modal" data-target="#viewAttandance"
                                                                                id="btn_view" className="btn text-uppercase w-100 mt-3" data-backdrop="static"
                                                                                data-keyboard="false" style={{ display: "none" }}> </button>

                                                                        </div>
                                                                        <div className="col-8">
                                                                            <p>
                                                                                Summary (in last 30 days):
                                                                                {getCustomerCompleteDetail.attendance.class.current_month}
                                                                            </p>
                                                                            <p>
                                                                                Summary (in last 30 days):
                                                                                {getCustomerCompleteDetail.attendance.workshop.current_month}
                                                                            </p>
                                                                            <p>
                                                                                Summary (in last 30 days):
                                                                                {getCustomerCompleteDetail.attendance.appointment.current_month}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <h4 className="title text-uppercase mt-5">Purchased Products</h4>
                                                                    {getCustomerCompleteDetail.purchase_product_data.length < 3 &&
                                                                        <div className="row pt-4" >
                                                                            {getCustomerCompleteDetail.purchase_product_data.map((product) => (
                                                                                <div className="col-md-6 col-lg-4" >
                                                                                    <div className="prelist-product p-3">
                                                                                        <div className="product-image">
                                                                                            <img src={product.pro_image} alt="" className="img-fluid d-block mx-auto" />
                                                                                        </div>
                                                                                        <div className="row align-items-center mt-5">
                                                                                            <div className="col-7">
                                                                                                <h6>{product.product_name}</h6>
                                                                                            </div>
                                                                                            <div className="col-5">
                                                                                                <button className="btn btn-brandgreengrad d-block ml-auto">$
                                                                                                    {product.price}</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {getCustomerCompleteDetail.purchase_product_data.length >= 3 &&
                                                                        <div className="row pt-1">
                                                                            <CustomerProduct pass={getCustomerCompleteDetail.purchase_product_data} />
                                                                        </div>
                                                                    }
                                                                    {getCustomerCompleteDetail.purchase_product_data.length == 0 &&
                                                                        <div >
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }

                                                                    <h4 className="title text-uppercase mt-5">Active Purchased Passes</h4>
                                                                    <h4 className="group_card mt-3">Recurring Membership</h4>
                                                                    {purchaseRecurringPass.length > 0 &&
                                                                        <div className="row pt-4 mb-3">
                                                                            {purchaseRecurringPass.map((lists) => (
                                                                                <div className="col-md-6 col-xl-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes mb-3">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.full_price} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-8">
                                                                                            <p>Auto Renew</p>
                                                                                                {/* <p>Remaining Days : {lists && lists.remaining_count ? lists.remaining_count : 'XX'} of {lists.total_count}</p> */}
                                                                                                <p>Start Date :  {utcToLocalTime(lists.start_date_utc)}</p>
                                                                                                {lists.pass_cancellation_date == '' && lists.pass_pause_start_less_then_thirty == '' &&
                                                                                                    <p style={{ textTransform: 'capitalize' }}>Status:  {lists.card_status}</p>}
                                                                                                {lists.pass_cancellation_date != '' && lists.pass_pause_start_less_then_thirty == '' &&
                                                                                                    <p>Status: Cancelled</p>}
                                                                                                {lists.pass_pause_start_less_then_thirty != '' &&
                                                                                                    <p >Status: Paused</p>}
                                                                                                {lists.pass_pause_start_less_then_thirty != '' && lists.is_recurring_stop != 0 &&
                                                                                                    <p >Paused :  {moment(lists.pass_pause_start_less_then_thirty).format("DD-MM-YYYY")}</p>}
                                                                                                {lists.pass_pause_end_less_then_thirty != '' && lists.is_recurring_stop != 0 &&
                                                                                                    <p>Restart Date:  {moment(lists.pass_pause_end_less_then_thirty).format("DD-MM-YYYY")}</p>}
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                {lists.passes_status == 1 && lists.pass_cancellation_date == '' && lists.card_status == 'active' &&
                                                                                                    <button className="btn btn-brandgreengrad text-right"
                                                                                                        onClick={() => changeStatus(1, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                    // onClick={handleShow}
                                                                                                    >Active
                                                                                                    </button>}
                                                                                                {lists.is_recurring_stop == 1 && lists.pass_cancellation_date == '' && lists.card_status == 'paused' &&
                                                                                                    <button className="btn btn-brandyellows text-right" style={{ background: '#fafa00' }} onClick={() => changeStatus(0, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                    >
                                                                                                        Paused
                                                                                                    </button>
                                                                                                }
                                                                                                {lists.is_recurring_stop == 1 && lists.pass_type_subcat != 36 &&
                                                                                                    <button className="btn btn-brandyellows text-right" style={{ background: '#fafa00' }} onClick={() => changeStatus(0, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                        disabled>Paused
                                                                                                    </button>}
                                                                                                {lists.pass_cancellation_date != '' && lists.pass_pause_start_less_then_thirty == '' &&
                                                                                                    <button className="btn btn-brandyellowcancel text-right" onClick={() => changeStatus(0, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                        style={{ marginLeft: "-5px" }}>Cancelling
                                                                                                    </button>}
                                                                                            </div>
                                                                                            <div col-md-12>
                                                                                                {lists.pass_cancellation_date != '' &&
                                                                                                    <p>Cancel Date:  {moment(lists.pass_cancellation_date).format('DD-MM-YYYY')}</p>}
                                                                                                {lists.pass_cancellation_date == '' && lists.passes_status == 1 &&
                                                                                                    <p>Expiry Date: {moment.unix(lists.pass_expired_date).format("DD-MMM-YYYY")}
                                                                                                        <button type="button" className="fa fa-edit" data-toggle="modal" data-target="#passInfoExpireDateModel"
                                                                                                            onClick={() => changeExPassInfo(lists)}
                                                                                                        ></button></p>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {purchaseRecurringPass.length == 0 && <div> <p className="text-center">No Records Found</p> </div>}

                                                                    {/* ********** SLIDER  ***************** */}
                                                                    {/* {purchaseRecurringPass.length > 22 &&
                                                                        <RecurringActiveSlider pass={purchaseRecurringPass} userDetail={userDetail} changeExPassInfo={changeExPassInfo} changeStatus={changeStatus} />
                                                                    } */}

                                                                    <h4 className="group_card mt-3">Time Frame</h4>
                                                                    {purchaseTimeFramePass.length < 3 &&
                                                                        <div className="row pt-4">
                                                                            {purchaseTimeFramePass.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} alt="" className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-12">
                                                                                                <p>Remaining Days : {lists.remaining_count} of {lists.total_count}
                                                                                                </p>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-12">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-12">
                                                                                                <p className="font-12">Expiration Date :
                                                                                                    {moment.unix(lists.end_date_utc).format("DD-MMM-YYYY")} <button type="button"
                                                                                                        className="fa fa-edit" data-toggle="modal"
                                                                                                        data-target="#passInfoModel"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {purchaseTimeFramePass.length == 0 && <div  > <p className="text-center">No Records Found</p>  </div>}
                                                                    {/*  ++++++++++++++++++++ SLIDER +++++++++++++ */}
                                                                    {/* {purchaseTimeFramePass.length > 2 &&
                                                                        <TimeActiveSlider pass={purchaseTimeFramePass} userDetail={userDetail} type={'time'} changePassInfo={changePassInfo} />
                                                                    } */}



                                                                    <h4 className="group_card mt-3">Punch Card</h4>
                                                                    {purchasePunchCardPass.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {purchasePunchCardPass.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} alt="" className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-md-12">
                                                                                                <p>Remaining Classes : {lists.remaining_count} of {lists.total_count}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Expiration Date :
                                                                                                    {moment.unix(lists.end_date_utc).format("DD-MMM-YYYY")}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoDateModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists, 2)}
                                                                                                    ></button>

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {purchasePunchCardPass.length == 0 && <div> <p className="text-center">No Records Found</p> </div>}

                                                                    {/* ******* SLIDER ********* */}
                                                                    {/* {purchasePunchCardPass.length > 2 &&
                                                                        <PunchActiveSlider pass={purchasePunchCardPass} userDetail={userDetail} changePassInfo={changePassInfo} />
                                                                    } */}



                                                                    <h4 className="title text-uppercase mt-5">In-Active Purchased Passes </h4>

                                                                    <h4 className="group_card mt-3">Recurring Membership</h4>
                                                                    {purchaseRecurringInactiveCard.length > 0 &&
                                                                        <div className="row pt-4">
                                                                            {purchaseRecurringInactiveCard.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.full_price} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-12">
                                                                                            <p>Auto Renew</p>
                                                                                                {/* <p>Remaining Days : {lists.remaining_count} of {lists.total_count}</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                                <p className="font-12">Start Date : {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                                {(lists.pass_cancellation_date == '') &&
                                                                                                    <div>
                                                                                                        <p className="font-12">Renewal Date : {utcToLocalTime(lists.end_date_utc)} </p>
                                                                                                    </div>
                                                                                                }
                                                                                                {lists.pass_cancellation_date != '' &&
                                                                                                    <div>
                                                                                                        <p className="font-12">Cancellation Date : {lists.pass_cancellation_date} </p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                {lists.is_recurring_stop == 0 &&
                                                                                                    <button className="btn btn-brandred text-right" onClick={() => changeStatus(1, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                    >Expired
                                                                                                    </button>
                                                                                                }
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {purchaseRecurringInactiveCard.length == 0 && <div> <p className="text-center">No Records Found</p> </div>}

                                                                    {/* ************ SLIDER ************ */}
                                                                    {/* {purchaseRecurringInactiveCard.length > 2 &&
                                                                        <RecurringInActiveSlider pass={purchaseRecurringInactiveCard} userDetail={userDetail} changeExPassInfo={changeExPassInfo} changeStatus={changeStatus} />
                                                                    } */}


                                                                    <h4 className="group_card mt-3">Time Frame</h4>
                                                                    {purchaseTimeFrameInactiveCard.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {purchaseTimeFrameInactiveCard.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-4">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-12">
                                                                                                <p>Remaining Days : {lists.remaining_count} of {lists.total_count}
                                                                                                </p>
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                                <p className="font-12">Expiration Date :
                                                                                                    {utcToLocalTime(lists.end_date_utc)} <button type="button"
                                                                                                        className="fa fa-edit" data-toggle="modal"
                                                                                                        data-target="#passInfoModel"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {purchaseTimeFrameInactiveCard.length == 0 && <div>  <p className="text-center">No Records Found</p>  </div>}

                                                                    {/* ************* SLIDER *************** */}
                                                                    {/* {purchaseTimeFrameInactiveCard.length > 2 &&
                                                                        <TimeInActiveSlider pass={purchaseTimeFrameInactiveCard} userDetail={userDetail} changePassInfo={changePassInfo} />
                                                                    } */}


                                                                    <h4 className="group_card mt-3">Punch Card</h4>
                                                                    {purchasePunchInactiveCard.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {purchasePunchInactiveCard.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-md-12">
                                                                                                <p>Remaining Classes : {lists.remaining_count} of {lists.total_count}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Expiration Date :{utcToLocalTime(lists.end_date_utc)}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoDateModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists, 2)}
                                                                                                    ></button>

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {purchasePunchInactiveCard.length == 0 && <div> <p className="text-center">No Records Found</p> </div>}
                                                                    {/* ********* SLIDER *************8 */}

                                                                    {/* // *ngIf="purchasePunchInactiveCard.length > 2" */}

                                                                    {/* {purchasePunchInactiveCard.length > 2 &&
                                                                        <PunchInActiveSlider pass={purchasePunchInactiveCard} userDetail={userDetail} changePassInfo={changePassInfo} />
                                                                    } */}


                                                                </div>
                                                            }
                                                            {selectmemberId != 0 &&
                                                                <div >
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            <button className="btn text-uppercase d-block" type="button"
                                                                                onClick={() => ViewDetail(famailyUserDetail && famailyUserDetail.class)}
                                                                            > Classes
                                                                            </button>
                                                                            <button className="btn text-uppercase d-block" type="button"
                                                                                onClick={() => ViewDetail(famailyUserDetail && famailyUserDetail.workshop)}
                                                                            > Workshops
                                                                            </button>
                                                                            <button className="btn text-uppercase d-block" type="button"
                                                                                onClick={() => ViewDetail(famailyUserDetail && famailyUserDetail.appointment)}
                                                                            > Appointments
                                                                            </button>
                                                                            <button type="button" data-toggle="modal" data-target="#viewAttandance"
                                                                                id="btn_view" className="btn text-uppercase w-100 mt-3" data-backdrop="static"
                                                                                data-keyboard="false" style={{ display: "none" }}> </button>

                                                                        </div>
                                                                        <div className="col-8">
                                                                            <p> Summary (in last 30 days): {famailyUserDetail && famailyUserDetail.class && famailyUserDetail.class.current_month} </p>
                                                                            <p> Summary (in last 30 days): {famailyUserDetail && famailyUserDetail.workshop && famailyUserDetail.workshop.current_month} </p>
                                                                            <p> Summary (in last 30 days): {famailyUserDetail && famailyUserDetail.appointment && famailyUserDetail.appointment.current_month}</p>
                                                                        </div>
                                                                    </div>
                                                                    <h4 className="title text-uppercase mt-5">Purchased Products</h4>
                                                                    {famailyProductItems.length > 0 &&
                                                                        <div className="row pt-4">
                                                                            {famailyProductItems.map((product) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-product p-3">
                                                                                        <div className="product-image">
                                                                                            <img src={product.pro_image} alt="" className="img-fluid d-block mx-auto" />
                                                                                        </div>
                                                                                        <div className="row align-items-center mt-5">
                                                                                            <div className="col-7">
                                                                                                <h6>{product.product_name}</h6>
                                                                                            </div>
                                                                                            <div className="col-5">
                                                                                                <button className="btn btn-brandgreengrad d-block ml-auto">$ {product.price}</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {famailyProductItems.length == 0 &&
                                                                        <div >
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }

                                                                    <h4 className="title text-uppercase mt-5">Active Purchased Passes </h4>

                                                                    {/* *** Family Recurring Active Passes  */}
                                                                    <h4 className="group_card mt-3">Recurring Membership</h4>
                                                                    {famailyRecurringItems.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {famailyRecurringItems.map((lists) => (
                                                                                <div className="col-md-6 col-xl-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes mb-3">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.full_price} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-8">
                                                                                                <p>Remaining Days : {lists && lists.remaining_count ? lists.remaining_count : 'XX'} of {lists.total_count}</p>
                                                                                                <p>Start Date :  {utcToLocalTime(lists.start_date_utc)}</p>
                                                                                                {lists.pass_cancellation_date == '' && lists.pass_pause_start_less_then_thirty == '' &&
                                                                                                    <p style={{ textTransform: 'capitalize' }}>Status:  {lists.card_status}</p>}
                                                                                                {lists.pass_cancellation_date != '' && lists.pass_pause_start_less_then_thirty == '' &&
                                                                                                    <p>Status: Cancelled</p>}
                                                                                                {lists.pass_pause_start_less_then_thirty != '' &&
                                                                                                    <p >Status: Paused</p>}
                                                                                                {lists.pass_pause_start_less_then_thirty != '' && lists.is_recurring_stop != 0 &&
                                                                                                    <p >Paused:  {utcToLocalTime(lists.pass_pause_start_less_then_thirty)}</p>}
                                                                                                {lists.pass_pause_end_less_then_thirty != '' && lists.is_recurring_stop != 0 &&
                                                                                                    <p>Restart Date:  {utcToLocalTime(lists.pass_pause_end_less_then_thirty)}</p>}
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                {lists.passes_status == 1 && lists.pass_cancellation_date == '' && lists.card_status == 'active' &&
                                                                                                    <button className="btn btn-brandgreengrad text-right"
                                                                                                        onClick={() => changeStatus(1, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                    // onClick={handleShow}
                                                                                                    >Active
                                                                                                    </button>}
                                                                                                {lists.is_recurring_stop == 1 && lists.pass_cancellation_date == '' && lists.card_status == 'paused' &&
                                                                                                    <button className="btn btn-brandyellows text-right" style={{ background: '#fafa00' }} onClick={() => changeStatus(0, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                    >
                                                                                                        Paused
                                                                                                    </button>
                                                                                                }
                                                                                                {lists.is_recurring_stop == 1 && lists.pass_type_subcat != 36 &&
                                                                                                    <button className="btn btn-brandyellows text-right" style={{ background: '#fafa00' }} onClick={() => changeStatus(0, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                        disabled>Paused
                                                                                                    </button>}
                                                                                                {lists.pass_cancellation_date != '' && lists.pass_pause_start_less_then_thirty == '' &&
                                                                                                    <button className="btn btn-brandyellowcancel text-right" onClick={() => changeStatus(0, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                        style={{ marginLeft: "-5px" }}>Cancelling
                                                                                                    </button>}
                                                                                            </div>
                                                                                            <div col-md-12>
                                                                                                {lists.pass_cancellation_date != '' &&
                                                                                                    <p>Cancel Date:  {moment(lists.pass_cancellation_date).format('MMM d , y')}</p>}
                                                                                                {lists.pass_cancellation_date == '' && lists.passes_status == 1 &&
                                                                                                    <p>Expiry Date: {utcToLocalTime(lists.pass_expired_date)}
                                                                                                        <button type="button" className="fa fa-edit" data-toggle="modal" data-target="#passInfoExpireDateModel"
                                                                                                            onClick={() => changeExPassInfo(lists)}
                                                                                                        ></button></p>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {famailyRecurringItems.length == 0 &&
                                                                        <div>
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }

                                                                    {/* *** Family Time Frame Active Passes  */}

                                                                    <h4 className="group_card mt-3">Time Frame</h4>
                                                                    {famailyTimeFrameItems.length > 0 &&
                                                                        <div className="row pt-4">
                                                                            {famailyTimeFrameItems.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4" >
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-12">
                                                                                                <p>Remaining Days : {lists.remaining_count} of {lists.total_count}
                                                                                                </p>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                                <p className="font-12">Expiration Date :
                                                                                                    {utcToLocalTime(lists.end_date_utc)} <button type="button"
                                                                                                        className="fa fa-edit" data-toggle="modal"
                                                                                                        data-target="#passInfoModel"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {famailyTimeFrameItems.length == 0 &&
                                                                        <div>
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }

                                                                    {/* *** Family Punch Active Passes  */}
                                                                    <h4 className="group_card mt-3">Punch Card</h4>
                                                                    {famailyPunchItems.length > 0 &&
                                                                        <div className="row pt-4">
                                                                            {famailyPunchItems.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-md-12">
                                                                                                <p>Remaining Classes : {lists.remaining_count} of {lists.total_count}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Expiration Date : {utcToLocalTime(lists.end_date_utc)}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoDateModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists, 2)}
                                                                                                    ></button>

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {famailyPunchItems.length == 0 &&
                                                                        <div >
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }


                                                                    <h4 className="title text-uppercase mt-5">In-Active Purchased Passes </h4>

                                                                    {/* ****** Family Inactive Recurring Passes  ******** */}

                                                                    <h4 className="group_card mt-3">Recurring Membership</h4>
                                                                    {familyRecurringInactiveItem.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {familyRecurringInactiveItem.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.full_price} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-12">
                                                                                                <p>Remaining Days : {lists.remaining_count} of {lists.total_count}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                                <p className="font-12">Start Date : {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                                {(lists.pass_cancellation_date == '') &&
                                                                                                    <div>
                                                                                                        <p className="font-12">Renewal Date : {utcToLocalTime(lists.end_date_utc)} </p>
                                                                                                    </div>
                                                                                                }
                                                                                                {lists.pass_cancellation_date != '' &&
                                                                                                    <div>
                                                                                                        <p className="font-12">Cancellation Date : {lists.pass_cancellation_date} </p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                {lists.is_recurring_stop == 0 &&
                                                                                                    <button className="btn btn-brandred text-right" onClick={() => changeStatus(1, lists.booking_pass_id, lists.pass_id, lists)}
                                                                                                    >Expired
                                                                                                    </button>
                                                                                                }
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {familyRecurringInactiveItem.length == 0 &&
                                                                        <div>
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }
                                                                    {/* ****** Family Inactive Time Frame Passes  ******** */}

                                                                    <h4 className="group_card mt-3">Time Frame</h4>
                                                                    {familyTimeFrameInactiveItem.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {familyTimeFrameInactiveItem.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-m-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-m-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-12">
                                                                                                <p>Remaining Days : {lists.remaining_count} of {lists.total_count}
                                                                                                </p>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-8">
                                                                                                <p className="font-12">Expiration Date :
                                                                                                    {utcToLocalTime(lists.end_date_utc)} <button type="button"
                                                                                                        className="fa fa-edit" data-toggle="modal"
                                                                                                        data-target="#passInfoModel"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {familyTimeFrameInactiveItem.length == 0 &&
                                                                        <div>
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }

                                                                    {/* ****** Family Inactive Punch Passes  ******** */}

                                                                    <h4 className="group_card mt-3">Punch Card</h4>
                                                                    {familyPunchInactiveItem.length > 0 &&
                                                                        <div className="row pt-4" >
                                                                            {familyPunchInactiveItem.map((lists) => (
                                                                                <div className="col-md-6 col-lg-4" >
                                                                                    <div className="prelist-pass p-3 blueback-passes">
                                                                                        <div className="mx-0 row align-items-center">
                                                                                            <div className="col-md-3">
                                                                                                <div className="passnew_logo">
                                                                                                    <img src={userDetail && userDetail.business_img} alt="" className="img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-9">
                                                                                                <div className="passdetail_new text-center flex-fill">
                                                                                                    <p className="mb-0 text-capitalize">{lists.pass_name}</p>
                                                                                                    <h6 className="mb-2"> $ {lists.amount} <small>Plus Tax</small></h6>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                        <div className="row mt-4">
                                                                                            <div className="col-md-12">
                                                                                                <p>Remaining Classes : {lists.remaining_count} of {lists.total_count}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Start Date :
                                                                                                    {utcToLocalTime(lists.start_date_utc)} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mt-1 align-items-end">
                                                                                            <div className="col-md-12">
                                                                                                <p className="font-12">Expiration Date :

                                                                                                    {utcToLocalTime(lists.end_date_utc)}
                                                                                                    {/* // .format('MMM d y')} */}
                                                                                                    <button type="button" data-toggle="modal"
                                                                                                        data-target="#passInfoDateModel" className="fa fa-edit"
                                                                                                        onClick={() => changePassInfo(lists, 2)}
                                                                                                    ></button>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                    {familyPunchInactiveItem.length == 0 &&
                                                                        <div>
                                                                            <p className="text-center">No Records Found</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                    </div>

                                </div>
                                <Footer />
                            </div >
                        </Col >
                    </Row >
                </div >
            </div >

            {/* <!-- Modal Popup (Add & Update - Family Member ) --> */}

            < Modal show={updateModal} className="modal fade" role="dialog" >
                <div className="modal-body white-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
                    <div className="modal-logo text-center pb-3 pb-sm-2 mb-2">
                        <input type="file" id="file1" name="image" accept="image/*" className="fileType"
                            onChange={(e) => getUploadFileDetail(e)} />
                        {!imgURL && <img src="assets/images/logo_white_web.png" className="img-fluid modalProfileImg m-0" />}
                        {imgURL && <img src={imgURL} alt="" className="img-fluid m-0 modalProfileImg" />}
                    </div>
                    <div className="modal-bottom-content border-radius6 py-3 py-sm-2 px-2">
                        <form className="AddNewForm" onSubmit={addMembers}>
                            <input type="hidden" name='memeber_id' value={memberDetail1My.memeber_id} />
                            <div className="form-group profile-change-password">
                                <p className="text-left font-14 mb-0"> Full Name </p>
                                <input type="text" className="form-control" value={memberDetail1My.member_name}
                                    placeholder="Full Name" name="member_name" onChange={(e) => faminlyOnChange(e)}
                                />
                                <small className="text-danger">{memberDetail1MyError.member_name}</small>
                            </div>

                            <div className="form-group">
                                <p className="text-left font-14 mb-0"> DOB </p>
                                <div >
                                    {memberDetail1My.memeber_id ?
                                        <input type="date" className="matinput" style={{ width: '100%', margin: '0', padding: '10px' }}
                                            value={memberDetail1My.dob}
                                            name="dob" readOnly={memberDetail1My.dob ? true : false} />
                                        :
                                        <input type="date" className="matinput" style={{ width: '100%', margin: '0', padding: '10px' }}
                                            value={memberDetail1My.dob}
                                            max={moment().format('YYYY-MM-DD')}
                                            name="dob" onChange={(e) => faminlyOnChange(e)}
                                        />
                                    }
                                </div>
                            </div>
                            <small className="text-danger">{memberDetail1MyError.dob}</small>

                            <div className="form-group relative">
                                <p className="text-left font-14 mb-0"> Gender </p>
                                <select className="form-control white-color" name="gender" onChange={(e) => faminlyOnChange(e)} >
                                    <option value=""> </option>
                                    <option value="Male" selected={memberDetail1My.gender === 'Male'}>Male</option>
                                    <option value="Female" selected={memberDetail1My.gender === 'Female'}>Female</option>
                                    <option value="Other" selected={memberDetail1My.gender === 'Other'}>Other</option>
                                </select>
                                <small className="text-danger">{memberDetail1MyError.gender}</small>
                            </div>

                            <div className="form-group relative">
                                <p className="text-left font-14 mb-0"> Relation with me </p>
                                <select className="form-control white-color" name="relation" onChange={(e) => faminlyOnChange(e)}>
                                    <option value=""> </option>
                                    {relationCollection && relationCollection.map((rl) => (
                                        <option value={rl.relative_id} selected={relationIds === rl.relative_id}>{rl.realtion_name}</option>

                                    ))}
                                </select>
                                <small className="text-danger">{memberDetail1MyError.relation}</small>
                            </div>

                            <div className="form-group text-align-center px-3 pt-0 pt-sm-2 mb-0 mb-sm-2 d-flex">
                                {!memberDetail1My.memeber_id &&
                                    <button type="submit" className="btn btn-brandblues text-uppercase  ml-5">
                                        {memberSpinner && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                                        Save
                                    </button>}
                                {memberDetail1My.memeber_id &&
                                    <button type="submit" variant="primary" className="btn btn-brandblues text-uppercase  ml-5">
                                        {memberSpinner && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                                        Update
                                    </button>}
                                <button type="button" data-dismiss="modal" id="btn_close1"
                                    className="btn btn-brandblues text-uppercase ml-5" data-backdrop="static"
                                    data-keyboard="false"
                                    onClick={() => setUpdateModal(false)}> close </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal >


            {/* <!-- Modal Popup (View - Attandance ) --> */}
            < Modal show={showModalAttendance} className="modal fade" >
                <div className="modal-body white-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
                    <button type="button" onClick={() => setShowModalAttendance(false)} data-dismiss="modal" id="class_modal_close_btn1" className="float-right mb-5">
                        <img src="assets/images/icons/close.png" width="15" /></button>
                    <div className="col-12">
                        <h4 className="black-color font-w-400 font-18 mb-0 text-uppercase mt-5">Last 30 day Vs Prior 30 days
                            Attendance is :
                            {summaryData && summaryData.attendance_status == 1 &&
                                <span className="brand-green font-18 ml-5">{summaryData.description} </span>}
                            {summaryData && summaryData.attendance_status == 0 &&
                                <span className="brand-red font-18 ml-5"> {summaryData && summaryData.description ? summaryData.description : ''} </span>}
                        </h4>
                        <h4 className="black-color font-w-400 font-18 mb-0 text-uppercase mt-4 mb-2">Summary: </h4>
                        <p className="black-color  font-16">{summaryData && summaryData.current_month ? summaryData.current_month : ''}</p>
                        <p className="black-color  font-16"> {summaryData && summaryData.previous_month ? summaryData.previous_month : ''} </p>
                    </div>
                </div>
            </Modal >

            {/* <!-- Update Pass Counter --> */}
            < Modal show={countModal} className="modal"  >
                {/* <!-- Modal Header --> */}
                {selectPassType == 'Punch Card' &&
                    < div className="modal-header" >
                        <h4 className="modal-title">Update Pass Counter</h4>
                        <button type="button" className="close" onClick={() => setCountModal(false)} data-dismiss="modal">&times;</button>
                    </div >
                }
                {selectPassType == 'Time Frame' &&
                    < div className="modal-header" >
                        <h4 className="modal-title">Update Pass Date</h4>
                        <button type="button" className="close" onClick={() => setCountModal(false)} data-dismiss="modal">&times;</button>
                    </div >
                }
                {/* <!-- Modal body --> */}
                < div className="modal-body" >
                    {selectPassType == 'Punch Card' &&
                        <div className="row">
                            <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                    <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Total Count</p>
                                    <input type="number" className="form-control text-capitalize" value={total_count}
                                        onChange={(e) => setTotal_count(e.target.value)}
                                        placeholder="Enter total count"

                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                    <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Remaining Count</p>
                                    <input type="number" className="form-control text-capitalize" value={remaining_count}
                                        placeholder="Enter remaining count"
                                        onChange={(e) => setRemaining_count(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        selectPassType == 'Time Frame' &&
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    {console.log(selectPassExpiry)}
                                    <p className="black-color font-w-900 font-18 mb-2">Expiry Date</p>
                                    <input
                                        type="date"
                                        className="form-control"
                                        // max={moment(timeFrameMaxDate).format("YYYY-MM-DD")}
                                        // min={moment(maxDate).format("YYYY-MM-DD")}
                                        onChange={(e) => setSelectPassExpiry(e.target.value)}
                                        value={moment(selectPassExpiry).format('YYYY-MM-DD')}
                                        required
                                    />
                                </div>
                            </div>
                        </div >
                    }
                </div >
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => setCountModal(false)} data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary"
                        onClick={() => updatePassCounter()} >
                        Submit
                        {punchCardSpinner && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                    </button >
                </div >
            </Modal >


            {/* <!-- Extand punch pass date --> */}
            < Modal show={passEXPState} className="modal"  >
                <div className="modal-header">
                    <h4 className="modal-title">Update Pass Date</h4>
                    <button type="button" onClick={() => setPassEXPState(false)} className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    {selectPassType === 'Punch Card' &&
                        <div div className="row" >
                            <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                <div className="form-group">
                                    <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Expiry Date</p>
                                    <InputField
                                        type="date"
                                        onChange={(e) => setPassEditDate(e.target.value)}
                                        value={passEditDate}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div >
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => setPassEXPState(false)} data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary"
                        onClick={() => updatePassDate()} > Submit
                        {punchCardSpinner && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                    </button >
                </div >
            </Modal >



            {/* <!-- Extand punch pass date --> */}
            <Modal show={notclose} className="modal">
                <div className="modal-header">
                    <h4 className="modal-title">Update Expiration Date</h4>
                    <button type="button" onClick={() => setNotclose(false)} className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                            <div className="form-group">
                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Expiry Date</p>
                                <InputField
                                    type="date"
                                    onChange={(e) => setPassEditDate(e.target.value)}
                                    
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={() => setNotclose(false)} data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={updateExPassDate}>
                        Submit
                        {/* {punchCardSpinner && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} */}
                    </button>
                </div >
            </Modal >


            {/* <!-- Modal Popup (Edit - Profile ) --> */}
            <Modal show={editProfileModal} className="modal fade" id="EditProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2"
                aria-hidden="true">
                <div className="modal-body white-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
                    <div className="col-12">
                        <form onSubmit={updateProfile}
                        // [formGroup]="userProfile"
                        >
                            <div className="row">
                                <div className="col-12 pb-4">
                                    <div className="profile-details">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6">
                                                <div className="profile-detail-image ">
                                                    <div className="userprofile_img box-shadow1">
                                                        {!imgURL ?
                                                            <img src={profileDetail && profileDetail.profile_img} className="img-fluid border-radius100 w-100" style={{ height: "200px" }} />
                                                            :
                                                            <img src={imgURL} className="img-fluid border-radius100  w-100" style={{ height: "200px" }} />
                                                        }
                                                        <a href="Javascript:void(0);" className="brand-color1 font-14 edit-profileimg box-shadow1"
                                                            id="upfile1" style={{ cursor: "pointer" }}>
                                                            <i className="fas fa-camera">
                                                                <input type="file" id="file1" name="image" accept="image/*" onChange={(e) => getUploadFileDetail(e)} />
                                                            </i>
                                                        </a>

                                                    </div>
                                                </div>
                                            </div>
                                            {profileDetail &&
                                                <div className="col-12 col-md-6">
                                                    <div className="profile-detail-text">
                                                        <h2 className="title dark-color font-24 mb-2 text-capitalize">
                                                            {profileDetail.name}  </h2>
                                                        <h3>{profileDetail.lastname} </h3>
                                                        <p className="brand-color3">  {profileDetail.email} </p>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Client Details --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">First Name </p>
                                        <input type="text" className="form-control text-capitalize"
                                            value={profileDetailMy.name}
                                            placeholder="Enter your name"
                                            onChange={profileDetailHandler}
                                            name="name" />
                                        <small className="text-danger">{profileDetailMyError.name}</small>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Last Name </p>
                                        <input id="" type="text" name="lastname" className="form-control text-capitalize"
                                            value={profileDetailMy.lastname}
                                            placeholder="Enter your name"
                                            onChange={profileDetailHandler}
                                        />
                                        <small className="text-danger">{profileDetailMyError.lastname}</small>
                                    </div>
                                </div>
                                {/* <!-- Name --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Country Code</p>
                                        <select className="form-control" name="country_code" value={profileDetailMy.country_code} onChange={profileDetailHandler} >
                                            {countryList && countryList.map((co) => (<option value={co.code} >{co.name}</option>))}
                                        </select>
                                        <small className="text-danger">{profileDetailMyError.country_code}</small>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Phone </p>
                                        <input id="" type="number" name="mobile" className="form-control"
                                            value={profileDetailMy.mobile}
                                            onChange={profileDetailHandler}
                                            style={{ backgroundColor: "white" }} placeholder="Enter your phone no."
                                        />
                                        <small className="text-danger">{profileDetailMyError.mobile}</small>
                                    </div>
                                </div>
                                {/* <!-- Phone --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Email </p>
                                        <input id="" type="email" name="email" className="form-control"
                                            value={profileDetailMy.email}
                                            style={{ backgroundColor: "white" }} placeholder="Enter your e-mail" readonly />
                                    </div>
                                </div>

                                {/* <!-- Email --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> DOB </p>
                                        <InputField
                                            type="date"
                                            name={'date_of_birth'}
                                            onChange={profileDetailHandler}
                                            value={profileDetailMy.date_of_birth}
                                        />
                                        <small className="text-danger">{profileDetailMyError.date_of_birth}</small>
                                    </div>
                                </div>
                                {/* // <!-- DOB --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Address</p>
                                        <input id="" name="address" className="form-control"
                                            value={profileDetailMy.address}
                                            placeholder="Enter your address"
                                            onChange={profileDetailHandler}
                                        // ngx-google-places-autocomplete
                                        //  (onAddressChange)="handleAddressChange($event)"
                                        // name="address"
                                        // [ngClass]="{ 'is-invalid': submitted && pf.address.errors }"
                                        />
                                        <small className="text-danger">{profileDetailMyError.address}</small>

                                    </div>
                                </div>
                                {/* <!-- Address --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Country </p>
                                        <input id="" name="country" className="form-control"
                                            value={profileDetailMy.country}
                                            onChange={profileDetailHandler}
                                            placeholder="Enter your country"
                                        />
                                        <small className="text-danger">{profileDetailMyError.country}</small>
                                    </div>
                                </div>
                                {/* // ><!-- Country --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> State/Province
                                        </p>
                                        <input id="" name="state" className="form-control"
                                            value={profileDetailMy.state}
                                            onChange={profileDetailHandler}
                                            placeholder="Enter your state/province"
                                        />
                                        <small className="text-danger">{profileDetailMyError.state}</small>
                                    </div>
                                </div>
                                {/* <!-- State/Province --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> City </p>
                                        <input id="" name="city" className="form-control"
                                            value={profileDetailMy.city}
                                            onChange={profileDetailHandler}
                                            placeholder="Enter your city"
                                        // [ngClass]="{ 'is-invalid': submitted && pf.city.errors }"
                                        />
                                        <small className="text-danger"></small>
                                        {/* <div *ngIf="submitted && pf.city.errors" className="invalid-feedback">
                                        <div *ngIf="pf.city.errors.required">City is required</div>
                                    </div> */}
                                    </div>
                                </div>
                                {/* <!-- City --> */}

                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency
                                            Contact Person </p>
                                        <input id="" name="emergency_contact_person" className="form-control"
                                            value={profileDetailMy.emergency_contact_person}
                                            onChange={profileDetailHandler}
                                            placeholder="Enter name of your emergency contact person"

                                        // [ngClass]="{ 'is-invalid': submitted && pf.emergency_contact_person.errors }"
                                        />
                                        <div
                                            // *ngIf="submitted && pf.emergency_contact_person.errors"
                                            className="invalid-feedback">
                                            <div
                                            // *ngIf="pf.emergency_contact_person.errors.required"
                                            >Emergency Contact
                                                Person is required</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Emergency Contact Person --> */}
                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency
                                            Country </p>
                                        <select className="form-control"
                                            name="emergency_country_code"
                                            onChange={profileDetailHandler}
                                            // [ngClass]="{ 'is-invalid': submitted && pf.emergency_country_code.errors }"
                                            required>
                                            <option value=" "></option>
                                            {countryList && countryList.map((code) => (
                                                <option selected={profileDetailMy.emergency_country_code === code.code} value={code.code}>{code.name}
                                                </option>))}
                                        </select>
                                        {/* <div *ngIf="submitted && pf.emergency_country_code.errors" className="invalid-feedback">
                                        <div *ngIf="pf.emergency_country_code.errors.required">Emergency Country is
                                            required</div>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                    <div className="form-group">
                                        <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency
                                            Contact Number </p>
                                        <input type="number" name="emergency_contact_no" className="form-control"
                                            // onKeyPress="if(this.value.length >= 12) return false;"
                                            value={profileDetailMy.emergency_contact_no}
                                            onChange={profileDetailHandler}
                                            placeholder="Enter number of your emergency contact person"
                                        // name="emergency_contact_no"
                                        // (keypress)="global.numberOnly($event)"
                                        // [ngClass]="{ 'is-invalid': submitted && pf.emergency_contact_no.errors }"
                                        />
                                        {/* <div *ngIf="submitted && pf.emergency_contact_no.errors" className="invalid-feedback">
                                        <div *ngIf="pf.emergency_contact_no.errors.required"> Emergency Contact Number
                                            is required</div>
                                    </div> */}
                                    </div>
                                </div>
                                {/* <!-- Emergency Contact Number --> */}

                                <div className="col-12 col-sm-8 mt-2 mt-md-3">
                                    <div className="form-group profile-gender mb-0">
                                        <p className="brand-color3 text-left font-14 mb-3"> Gender </p>
                                        <div className="profile-gender-button2">
                                            <input type='radio' id='male' checked={profileDetailMy.gender === "male"} value="male" name='gender'
                                                onChange={profileDetailHandler}
                                            />
                                            <label for='male'>Male</label>
                                            <input type='radio' id='female' value="female" name='gender'
                                                checked={profileDetailMy.gender === "female"}
                                                onChange={profileDetailHandler}
                                            />
                                            <label for='female'>Female</label>

                                            <input type='radio' id='other' value="other" name='gender'
                                                checked={profileDetailMy.gender === "other"}
                                                onChange={profileDetailHandler}
                                            />
                                            <label for='other'>Other</label>
                                        </div>
                                        {/* <!-- end of profile-gender-button2 --> */}
                                    </div>
                                </div>
                                {/* <!-- Gender --> */}

                                <div className="col-12 col-sm-4 mt-2 mt-md-3"></div>
                                <div className="col-12 col-sm-4 mt-2 mt-md-3"></div>

                                <div className="col-12 col-sm-4 mt-2 mt-md-3 d-flex">
                                    <button type="submit" variant="primary" className="btn btn-brandblue mt-5 mr-3 text-right"

                                    // [disabled]="profileSpinner"
                                    >
                                        {profileSpinner &&
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>} Update </button>
                                    <button type="button" data-dismiss="modal" id="btn_close11" className="btn btn-brandblue mt-5 mr-3 text-right" data-backdrop="static"
                                        data-keyboard="false" onClick={() => setEditProfileModal(false)}
                                    > close </button>

                                </div>
                            </div>
                            {/* <!-- end of Input fields --> */}

                        </form>
                    </div>
                </div>
                {/* <!-- end of modal-body --> */}

            </Modal>


            {/* Show Pause Modal */}

            <Modal show={show && modalContent === 'default'} onHide={handleClose} className="MembershipActiveModal">
                <Modal.Header closeButton>
                    <Modal.Title>Pause or Cancel Membership</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>
                                Would you like to : <Button variant="warning" onClick={() => handleButtonClick('Pause')} className="mx-3">Pause</Button> the pass for 30 days or less
                            </p>
                            <p>
                                Would you like to : <Button variant="danger" onClick={() => handleButtonClick('Cancel')} className="mx-3">Cancel</Button> this pass
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={12}>
                            <p>
                                <b>Note:</b>
                                To adjust the next recurring pass charge, go to “Paused Recurring Pass Billing” in the reports tab
                            </p>
                        </Col>
                        {/* <Col md={2}>
                            <Button variant="danger" onClick={handleClose}> No </Button>
                        </Col> */}
                    </Row>
                </Modal.Footer>
            </Modal>


            {/* Pause Modal */}
            <Modal show={show && modalContent === 'pause'} onHide={handleClose} className="MembershipActiveModal">
                <Modal.Header closeButton>
                    <Modal.Title>Pause Membership</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>Enter the details to pause the pass:</p>
                            {recurringPassObj && recurringPassObj.pass_pause_start_less_then_thirty == '' ?
                                <Row>
                                    <Col>
                                        <InputField
                                            type="date"
                                            name={'pauseStartDate'}
                                            minLength={(moment().add(1, 'days')).format('YYYY-MM-DD')}
                                            lable='Pause Start Date'
                                            onChange={changeHandlerPause}
                                        />
                                        <small className="text-danger">{pauseCaseValueError.pauseStartDate}</small>
                                    </Col>
                                    <Col>
                                        <InputField
                                            type="number"
                                            minLength={'0'}
                                            name={'days'}
                                            onChange={changeHandlerPause}
                                            lable={'Days Paused (must be less than 30 days)'}
                                        // lable='Days Paused: (must be less than 30 days)'
                                        />
                                        <small className="text-danger">{pauseCaseValueError.days}</small>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col>
                                        <InputField
                                            type="date"
                                            value={recurringPassObj && recurringPassObj.pass_pause_start_less_then_thirty}
                                            lable='Pause Start Date'
                                            readOnly={true}
                                            onChange={changeHandlerPause}
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            className={'form-control abcd'}
                                            type="date"
                                            name={'pauseEndDate'}
                                            onChange={changeHandlerPause}
                                            minLength={recurringPassObj && moment(recurringPassObj.pass_pause_start_less_then_thirty).add(1, 'days').format('YYYY-MM-DD')}
                                            // max={recurringPassObj && moment(recurringPassObj.pass_pause_start_less_then_thirty).format('YYYY-MM-DD')}
                                            lable={'Pass Restart Date'}
                                        // lable={`pauseReason [ ${recurringPassObj && moment(recurringPassObj.pass_pause_start_less_then_thirty).format('DD-MM-YYYY')} ]`}
                                        />
                                        <small className="text-danger">{pauseCaseValueError.pauseEndDate}</small>
                                    </Col>
                                </Row>
                            }

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={9}>
                            <p>
                                <b>Note:</b>
                                To adjust the next recurring pass charge, go to “Paused Recurring Pass Billing” in the reports tab
                            </p>
                        </Col>
                        <Col md={3}>
                            <Row>
                                <h5>Save Changes</h5>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Button variant="primary" className="pt-1 pb-1 pl-2 pr-2 mr-2" onClick={confirmMultipleResponse}> Yes </Button>
                                </Col>
                                <Col md={6}>
                                    <Button variant="danger" onClick={handleClose}>  No </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>


            {/* Cancel Modal */}

            <Modal show={show && modalContent === 'cancel'} onHide={handleClose} className="MembershipActiveModal">
                <Modal.Header closeButton>
                    <Modal.Title><p className="text-danger">Cancelling Recurring Pass</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent === 'cancel' && (
                        <Row>
                            <Col>
                                <p>
                                    By cancelling this pass, it will become inactive at the end of the month and move the pass to become inactive.
                                    The client can use this pass until the end of the month. Do you want to proceed with cancelling this?
                                </p>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={12}>
                                    <h5>Save Changes</h5>
                                    <Row>
                                        <Col md={6}>
                                            <Button variant="primary" className="pt-1 pb-1 pl-2 pr-2 mr-2" onClick={cancelPausePass}> Yes </Button>
                                        </Col>
                                        <Col md={6}>
                                            <Button variant="danger" onClick={handleClose}>  No </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
