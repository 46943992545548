import React, { useContext, useEffect, useState } from 'react'

import { Innerfooter } from '../../Components/inner-footer/Innerfooter';
import { InnerHeader } from '../../Components/inner-header/InnerHeader1';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { get_business_detail } from '../../../Static_Services/StaticServices';
import { MyContext } from '../../../App';
import { DashboardTopSection } from '../../Components/CommanPages/DashboardTopSection';
import { API_get_business_detail, Instructor_get_business_detail, favourite } from '../../../Static_Services/Staff_Services';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { StaffProductCarousal } from '../../Components/CommanPages/StaffProductCarousal';
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg';
import { StaffCardCarousel } from '../../Components/CommanPages/StaffCardCarousel';
import { ClientCardCarousal } from '../../Components/CommanPages/ClientCardCarousal';
import { ClientProduct } from '../../Components/CommanPages/ClientProduct';


export const DashboardDetail = () => {

    const navigate = useNavigate();
    const [businessID, setBusinessID] = useState();
    const [userDetail, setUserDetail] = useState();
    const location = useLocation();
    const [aPIResponce,setAPIResponce] = useState(false)

    const [businessDetail, setBusinessDetail] = useState();
    const [productDetail, setProductDetail] = useState([]);
    const [addresslength, setAddresslength] = useState();
    const [punchPassDetail, setPunchPassDetail] = useState([]);
    const [timePassDetail, setTimePassDetail] = useState([]);
    const [recurringPassDetail, setRecurringPassDetail] = useState([]);
    const [passData, setPassData] = useState([]);
    const [loder, setLoder] = useState(false);
    const [currentUrl,setCurrentUrl]=useState();
    const [iD,setID] = useState();


    useEffect(() => {
        if (localStorage.getItem("userDetail")) {
            setUserDetail(JSON.parse(localStorage.getItem("userDetail")));
        }
        if(JSON.parse(localStorage.getItem('w_loginDetail'))){
            setUserDetail(JSON.parse(localStorage.getItem('w_loginDetail')));
        }
        if (localStorage.getItem('bzid')) {
            setBusinessID(localStorage.getItem('bzid'));
        }
        if(location.pathname){
            setCurrentUrl(location.pathname);
        }
        const navData = location.state;
        if(localStorage.getItem('w_DetailId')){
            setID(JSON.parse(localStorage.getItem('w_DetailId')));
            }
        if(navData && navData.Id){
            localStorage.setItem('w_DetailId', JSON.stringify(navData.Id));
            setID(navData.Id);
          }
         
    }, [])


    const redirect = (url) => {
        navigate(url, { state: { id: businessDetail.business_id } });
    }


    const getBusinessDetail = async () => {
        setLoder(true);
        let detail = { "business_id": iD };
        const resp = await API_get_business_detail(detail);       
        if (resp) {
            setAPIResponce(true);
            const response = resp.data;
            setBusinessDetail(response.data);
            setProductDetail(response.data.product_details);
            setAddresslength(response.data.address.length);
            if (response.data.pass_details.length != 0) {
                let punchPass = [];
                let timePass = [];
                let recurringPass = [];
                let ageal = '';
                response.data.pass_details.map((el) => {
                    el.start_date_utc_new = moment.unix(el.passes_start_date).format('MMM DD YYYY');
                    el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');
                    el.is_purchase = 0;
                    if (el.age_restriction == 'Over') {
                        if (userDetail.age >= el.age_over_under) {
                            ageal = true;
                        } else {
                            ageal = false;
                        }
                    } else if (el.age_restriction == 'Under') {
                        if (userDetail.age < el.age_over_under) {
                            ageal = true;
                        } else {
                            ageal = false;
                        }
                    } else {
                        ageal = true;
                    }
                    el.ageallow = ageal;
                    if (el.pass_type_name == 'Punch Card') {
                        punchPass.push(el);
                    } else if (el.pass_type_name == 'Time Frame') {
                        timePass.push(el);
                    } else {
                        recurringPass.push(el);
                    }
                })

                setPunchPassDetail(punchPass);
                setTimePassDetail(timePass);
                setRecurringPassDetail(recurringPass);
            }
            setPassData(response.data.pass_details);
            const bddetail = { "business_id": response.data.business_id, 'business_name': response.data.business_name, 'address': response.data.address, 'logo': response.data.logo, 'business_img': response.data.business_img, 'email': response.data.email, 'latitude': response.data.latitude, 'longitude': response.data.longitude, 'business_phone': response.data.business_phone, 'favourite': response.data.favourite, 'isCloverPurchase': response.data.isCloverPurchase, 'is_already_client': response.data.is_already_client, 'studio_message': response.data.studio_message };
            localStorage.setItem('w_BusinessDetail', JSON.stringify(bddetail));
            setLoder(false);
        } else {
            setLoder(false);
        }

    }

    const getStaffBusinesDetail = async () => {
      
        const detail = { "business_id": iD };
        const resp = await Instructor_get_business_detail(detail);
        console.log("b-details ---",resp);
        if (resp) {
            setAPIResponce(true);
            const response = resp.data
            setBusinessDetail(response.data);
            const bddetail = { "business_id": response.data.business_id, 'business_name': response.data.business_name, 'address': response.data.address, 'logo': response.data.logo, 'business_img': response.data.business_img, 'email': response.data.email, 'latitude': response.data.latitude, 'longitude': response.data.longitude, 'business_phone': response.data.business_phone, 'favourite': response.data.favourite, 'isCloverPurchase': response.data.isCloverPurchase, 'is_already_client': response.data.is_already_client, 'studio_message': response.data.studio_message };
            localStorage.setItem('w_BusinessDetail', JSON.stringify(bddetail));
            setAddresslength(response.data.address.length);
            setLoder(false);
        } else {
            setLoder(false);
        }

    }

    useEffect(() => {
        console.log(iD);
        if (iD) {
            if (userDetail && userDetail.role_id == '3') {
                getBusinessDetail();
            } else {
                getStaffBusinesDetail();
            }
        }
    }, [iD, userDetail])

    const addFav = async (id, status, type) => {
        setLoder(true);
        var detail = { "service_type": type, "service_id": id, "status": status };
        const resp = await favourite(detail)
        if (resp) {
            const response = resp.data;
            successAlert(response.message);
            if (userDetail.role_id == '3') {
                getBusinessDetail();
            } else {
                getStaffBusinesDetail();
            }
        } else {
            setLoder(false);
        }
        setLoder(false);
    }

 

    const redirectUrl = (id, url, urlcurrent) => {
        if (urlcurrent) {
            if (id.ageallow) {
                var detail = { 'id': id.id, 'url': currentUrl, 'user_booking_id': id.user_booking_id };
                navigate(url, { state: { Id: detail } });
            } else {
                errorAlert('You are not eligible');
            }
        } else {
            navigate(url, { state: { Id: id, backurl: currentUrl } });
        }
    }
    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            < InnerHeader />
            <div className="body-grey">
                {aPIResponce &&
                <div className="studio-details client-home-inner mb-5">
                    <div className="container pb-5">
                        <DashboardTopSection />

                        {/*   IF ROLE ID ======  4 START */}
                        {userDetail && userDetail.role_id == 4 &&
                            <div>
                                <div className="container-fluid pr-0 white-bg mb-3 mt-3" >
                                    <div className="mb-2 pb-1" onClick={() => redirect("/classess")} style={{ cursor: "pointer" }} >

                                        <div className="row">
                                            <div className="col-12 col-md-1">
                                                <div className="text-center pt-3">
                                                    <img src="clientassets/img/ic_class.png" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-11">
                                                <div className="inner-staff-card pt-3">
                                                    <span> Classes</span>
                                                    <p className="black-color font-w-100 font-16"> {businessDetail ? businessDetail.class_categories : ''}</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end of Heading --> */}
                                </div>
                                {/* <!-- end of home-inner-class --> */}

                                <div className="container-fluid pr-0  white-bg mb-3">
                                    <div className="mb-2 pb-1" onClick={() => redirect("/appointment")} style={{ cursor: "pointer" }}>

                                        <div className="row">
                                            <div className="col-12 col-md-1">
                                                <div className="text-center pt-3">
                                                    <img src="clientassets/img/ic_appointment.png" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-11">
                                                <div className="inner-staff-card pt-3">
                                                    <span> Appointment </span>
                                                    <p className="black-color font-w-100 font-16">{businessDetail && businessDetail.services_categories ? businessDetail.services_categories : ''}</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <!-- end of Heading --> */}
                                </div>
                                {/* <!-- end of home-inner-class --> */}
                                <div className="container-fluid pr-0  white-bg mb-3">

                                    <div className="mb-2 pb-1" onClick={() => redirect("/workshopss")} style={{ cursor: "pointer" }}>
                                        <div className="row">
                                            <div className="col-12 col-md-1">
                                                <div className="text-center pt-3">
                                                    <img src="clientassets/img/ic_workshop.png" />
                                                </div></div>
                                            <div className="col-12 col-md-11">
                                                <div className="inner-staff-card pt-3">
                                                    <span> Workshops </span>
                                                    <p className="black-color font-w-100 font-16"> {businessDetail ? businessDetail.workshop_categories : ''}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end of Heading --> */}
                                </div>
                                {/* <!-- end of home-inner-class --> */}
                            </div>
                        }
                        {/*  ********** ROLL ID 4 END  ******** */}

                        {/* ************ IF ROLL ID 3 STRAT ************ */}
                        {userDetail && userDetail.role_id == 3 &&
                            <div>

                                {/* <div class="container-fluid pr-0 white-bg mb-3 mt-3">
                                    <div class="mb-2 pb-1">
                                        <Link href="Javascript:void(0);" to='/my-classes'>
                                            <div class="row">
                                                <div class="col-12 col-md-1">
                                                    <div class="text-center pt-3">
                                                        <img src="clientassets/img/ic_class.png" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-11">
                                                    <div class="inner-staff-card pt-3">
                                                        <span> Classes</span>
                                                        <p class="black-color font-w-100 font-16">{businessDetail && businessDetail.class_categories ? businessDetail.class_categories : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div> */}

                                {/* <!-- end of home-inner-class --> */}

                                {/* <div class="container-fluid pr-0  white-bg mb-3">
                                    <div class="mb-2 pb-1">
                                        <Link to='/services'>
                                            <div class="row">
                                                <div class="col-12 col-md-1">
                                                    <div class="text-center pt-3"> <img src="clientassets/img/ic_service.png" /> </div>
                                                </div>
                                                <div class="col-12 col-md-11">
                                                    <div class="inner-staff-card pt-3">
                                                        <span> Services </span>
                                                        <p class="black-color font-w-100 font-16"> {businessDetail && businessDetail.services_categories}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div> */}

                                {/* <div class="container-fluid pr-0  white-bg mb-3">
                                    <div class="mb-2 pb-1">
                                        <Link to='/workshops'>
                                            <div class="row">
                                                <div class="col-12 col-md-1">
                                                    <div class="text-center pt-3">
                                                        <img src="clientassets/img/ic_workshop.png" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-11">
                                                    <div class="inner-staff-card pt-3">
                                                        <span> Workshops </span>
                                                        <p class="black-color font-w-100 font-16"> {businessDetail && businessDetail.workshop_categories}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div> */}
                                {/* <div class="container-fluid pr-0  white-bg mb-3">
                                    <div class="mb-2 pb-1">
                                        <Link to='/staff'>
                                            <div class="row">
                                                <div class="col-12 col-md-1">
                                                    <div class="text-center pt-3">
                                                        <img src="clientassets/img/ic_instructor.png" />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-11">
                                                    <div class="inner-staff-card pt-3">
                                                        <span> Staff / Instructors </span>
                                                        <p class="black-color font-w-100 font-16"> {businessDetail && businessDetail.instructor_categories}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div> */}
                                {/* <!-- end of home-inner-class --> */}

                                {productDetail.length != 0 &&
                                    <div class="home-inner-product">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="section-heading pt-2 pb-4 text-left">
                                                    <Link to="/allproduct" class="black-color font-w-100 text-uppercase font-20"> products </Link>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="section-heading pt-2 pb-3 text-right pr-5">
                                                    <Link to="/allproduct" class="brand-color3 font-w-100 font-14"> View All </Link>
                                                </div>
                                            </div>
                                            {/* <!-- end of col-6 --> */}
                                        </div>
                                        {/* <!-- end of container --> */}

                                        <div className="row pt-4">
                                            <ClientProduct pass={productDetail} businessDetail={businessDetail} redirectUrl={redirectUrl}/>
                                        </div>
                                    </div>
                                }
                                {/* <!-- end of home-inner-product --> */}

                                {passData.length != 0 &&
                                    <div class="home-inner-available" >
                                        <div class="mb-2 pb-1">
                                            <p class="black-color font-w-100 text-uppercase font-20"> Recurring Membership </p>
                                        </div>
                                        {recurringPassDetail.length == 0 &&
                                            <div class="row">
                                                <div class="col-4"></div>
                                                <div class="col-4">
                                                    <div class="text-center">
                                                        <p class="font-awesome black-color font-18 d-inline mx-2 "> No Pass Available</p>
                                                    </div>
                                                </div>
                                                <div class="col-4"></div>
                                            </div>
                                        }

                                        {recurringPassDetail.length <= 2 &&
                                            <div class="row new-slider-logo">
                                                {recurringPassDetail.map((items) => (
                                                    <div class="col-6">
                                                        <div class="mb-5">
                                                            <div class="available-card" style={{cursor: 'pointer'}}>
                                                                <div class="available-blue-card p-3 p-sm-4" onClick={() => redirectUrl(items, '/passes-detail', currentUrl)} >
                                                                    <div class="row mx-0 ">
                                                                        <div class="col-4 pass-logo">
                                                                            <img src={businessDetail.logo} class="img-fluid" />
                                                                        </div>
                                                                        <div class="col-8 flex-fill pr-4 pl-5 passTextwidth">
                                                                            <h5 class="white-color font-14 mb-2 mt-0"> {items.pass_name}</h5>
                                                                            <h5 class="white-color font-14 mb-2 mt-0">$ {items.full_price} <span class="font-12"> Plus Tax
                                                                            </span></h5>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-5 align-items-end">
                                                                        <div class="col-6 text-left pr-0">
                                                                            <p class="mb-0 white-color paratitle"> Valid For  {items.pass_validity}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="studio-card-select">
                                                                    {items.favourite == 0 &&
                                                                        <i class="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.id, 1, 2)}></i>}
                                                                    {items.favourite == 1 &&
                                                                        <i class="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.id, 0, 2)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {recurringPassDetail.length > 2 &&
                                            <div className='row mt-4'>
                                                <ClientCardCarousal pass={recurringPassDetail}  businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />
                                            </div>
                                        }

                                        <div class="mb-2 pb-1">
                                            <p class="black-color font-w-100 text-uppercase font-20">Time Frame </p>
                                        </div>
                                        {timePassDetail.length == 0 &&
                                            <div class="row">
                                                <div class="col-4"></div>
                                                <div class="col-4">
                                                    <div class="text-center">
                                                        <p class="font-awesome black-color font-18 d-inline mx-2 "> No Pass Available</p>
                                                    </div>
                                                </div>
                                                <div class="col-4"></div>

                                            </div>
                                        }
                                        {timePassDetail.length <= 2 &&
                                            <div class="row new-slider-logo" >
                                                {timePassDetail.map((items) => (
                                                    <div class="col-6">
                                                        <div class="mb-5">
                                                            <div class="available-card">
                                                                <div class="available-blue-card p-3 p-sm-4" onClick={() => redirectUrl(items, '/passes-detail', currentUrl)}>
                                                                    <div class="row mx-0 ">
                                                                        <div class="col-4 pass-logo">
                                                                            <img src={businessDetail.logo} class="img-fluid" />
                                                                        </div>
                                                                        <div class="col-8 flex-fill pr-4 pl-5 passTextwidth">
                                                                            <h5 class="white-color font-14 mb-2 mt-0">{items.pass_name}</h5>

                                                                            <h5 class="white-color font-14 mb-2 mt-0">$ {items.amount}<span class="font-12"> Plus Tax
                                                                            </span></h5>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-5 align-items-end">
                                                                        <div class="col-6 text-left pr-0">
                                                                            <p class="mb-0 white-color paratitle"> Valid For {items.pass_validity}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="studio-card-select">
                                                                    {items.favourite == 0 &&
                                                                        <i class="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.id, 1, 2)}></i>}
                                                                    {items.favourite == 1 && <i class="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.id, 0, 2)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {timePassDetail.length > 2 &&
                                            <div className='row mt-4'>
                                                <ClientCardCarousal  pass={timePassDetail} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />
                                            </div>
                                        }
                                        <div class="mb-2 pb-1">
                                            <p class="black-color font-w-100 text-uppercase font-20">Punch Card </p>
                                        </div>

                                        {punchPassDetail.length == 0 &&
                                            <div class="row" >
                                                <div class="col-4"></div>
                                                <div class="col-4">
                                                    <div class="text-center">
                                                        <p class="font-awesome black-color font-18 d-inline mx-2 "> No Pass Available</p>
                                                    </div>
                                                </div>
                                                <div class="col-4"></div>
                                            </div>
                                        }
                                        {punchPassDetail.length <= 2 &&
                                            <div class="row new-slider-logo" >
                                                {punchPassDetail.map((items) => (
                                                    <div class="col-6">
                                                        <div class="mb-5">
                                                            <div class="available-card">
                                                                <div class="available-blue-card p-3 p-sm-4" onClick={() => redirectUrl(items, '/passes-detail', currentUrl)} >
                                                                    <div class="row mx-0 ">
                                                                        <div class="col-4 pass-logo">
                                                                            <img src={businessDetail.logo} class="img-fluid" />
                                                                        </div>
                                                                        <div class="col-8 flex-fill pr-4 pl-5 passTextwidth">
                                                                            <h5 class="white-color font-14 mb-2 mt-0"> {items.pass_name}</h5>
                                                                            <h5 class="white-color font-14 mb-2 mt-0">$ {items.amount} <span class="font-12"> Plus Tax
                                                                            </span></h5>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-5 align-items-end">
                                                                        <div class="col-6 text-left pr-0">
                                                                            <p class="mb-0 white-color paratitle"> Valid For {items.pass_validity}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="studio-card-select">
                                                                    {items.favourite == 0 &&
                                                                        <i class="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.id, 1, 2)}></i>}
                                                                    {items.favourite == 1 &&
                                                                        <i class="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.id, 0, 2)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {punchPassDetail.length > 2 &&

                                            <div className='row mt-4'>
                                                <ClientCardCarousal pass={punchPassDetail} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />
                                            </div>
                                        }
                                    </div>
                                }
                                {passData.length == 0 &&
                                    <div class="row"  >
                                        <div class="col-4"></div>
                                        <div class="col-4">
                                            <div class="text-center">
                                                <p class="font-awesome black-color font-18 d-inline mx-2 "> No Pass Available</p>
                                            </div>
                                        </div>
                                        <div class="col-4"></div>
                                    </div>
                                }
                            </div>
                        }
                        {/* ************ IF ROLL ID 3 END ************ */}

                    </div>
                </div>
                }
            </div>
            <Innerfooter />
        </>
    )
}
